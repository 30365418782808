import React from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ReceiveMessage } from './Enums.jsx';
import sizeof from 'object-sizeof'


class SignalR extends React.Component {
  
    static connection = null;

	constructor(props){
		super(props);

        let url = props.url ? props.url : '';
        let group = props.group ? props.group : '';

		this.state = {
            url: url,
            group: group,
		}
	}
	

	getJSONSize = (title = '', json = {}) => {
        let bytes = sizeof(json);
        // con_sole.log(title, json, "JSON size", Utlis.bytesToSizeWithDecimal(bytes));
        console.log("JSON size", title, json);
    }

    connect = () => {
        try {
            this.connection = new HubConnectionBuilder()
                .withUrl(this.state.url)
                // .configureLogging(LogLevel.Debug)
                .withAutomaticReconnect([0, 2000, 10000, 30000])
                .build();

            this.connection.serverTimeoutInMilliseconds = 60000;
        } catch (err) {}

        try {
            this.connection.start().then(() => {
                if(this.props.onConnect){
                    this.props.onConnect();
                }
    
                this.connection.invoke("AddToGroup", this.state.group);
    
                this.connection.on(ReceiveMessage.CreateJob, message => {
                    this.getJSONSize("CreateJob", message);
                      
                    if(this.props.onReceiveMessage){
                        this.props.onReceiveMessage(ReceiveMessage.CreateJob, message);
                    }
                });
    
                this.connection.on(ReceiveMessage.UpdateJob, message => {
                    this.getJSONSize("UpdateJob", message);
    
                    if(this.props.onReceiveMessage){
                        this.props.onReceiveMessage(ReceiveMessage.UpdateJob, message);
                    }
                });
    
                this.connection.on(ReceiveMessage.UpdateBatchJob, message => {
                    this.getJSONSize("UpdateBatchJob", message);
                    
                    if(this.props.onReceiveMessage){
                        this.props.onReceiveMessage(ReceiveMessage.UpdateBatchJob, message);
                    }
                });
    
                this.connection.on(ReceiveMessage.UpdateMessage, message => {
                    this.getJSONSize("UpdateMessage", message);
    
                    if(this.props.onReceiveMessage){
                        this.props.onReceiveMessage(ReceiveMessage.UpdateMessage, message);
                    }
                });
            }).catch(e => {
                if(this.props.onFailed){
                    this.props.onFailed(e);
                }
            });
        } catch (err) {}
    }
    
    disconnect = () => {
        try {
            this.connection.stop();
        } catch (err) {}
    }


	componentDidMount() {
        this.connect();
    }
    
    
    isConnected = () => {
        return this.connection.connectionStarted;
    }

    sendMessage = async (message) => {
        const msg = {
            message: message
        };

        if(this.isConnected()) {
            try {
                await this.connection.send('SendMessage', msg);
            } catch(e) {}
        }
    }
    
    
	render() {
		return null;
	}
}
  
export default SignalR;