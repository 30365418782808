import React from 'react';
import { useHistory } from "react-router-dom";
import moment from "moment";

import {
  Row,
  Col,
  Button,
  Form,
  DropdownButton,
  ButtonGroup,
  Dropdown,
} from 'react-bootstrap';
import styled from 'styled-components';

import Carousel from 'react-elastic-carousel';

import RSuiteDateRangePickerGantt from "../../../components/OLD/Datepicker/RSuiteDateRangePickerGantt";


// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../setup'
import slice from './slice';

// enums
import Utils from '../../../utils/utils';
// import {  } from '../../../utils/enums';

import ListComponent from './List';

import { ReactComponent as PlusIcon } from "../../../../_metronic/assets/icons/plus.svg";
import { ReactComponent as ListIcon } from "../../../../_metronic/assets/icons/list.svg";
import { ReactComponent as BarChart2Icon } from "../../../../_metronic/assets/icons/bar-chart-2.svg";
import { ReactComponent as CalendarIcon } from "../../../../_metronic/assets/icons/calendar.svg";
import { ReactComponent as FilterIcon } from "../../../../_metronic/assets/icons/filter.svg";
import { ReactComponent as SearchIcon } from "../../../../_metronic/assets/icons/search.svg";

// ----------------------------------------------------------------------

const StyledRow = styled(Row)`
  position: sticky;
  top: 65px;
  background-color: white;
  z-index: 5;

  @media (max-width: 767px) {
    position: relative;
    top: unset;
  }
`;


const PageComponent = () => {
  let history = useHistory();

  const { isVertical, showActualJobTime } = useSelector((state: RootState) => state.ganttChartSlice);
  const dispatch = useDispatch<any>();

  const [show, setShow] = React.useState(true);

  const [search, setSearch] = React.useState('');

  
  const [fromDate, setFromDate] = React.useState(moment().format(Utils.getAPIDateFormat()));
  const [toDate, setToDate] = React.useState(moment().format(Utils.getAPIDateFormat()));
  const [dateRange, setDateRange] = React.useState(Utils.getDateRangeValue("Today"));
  const [format, setFormat] = React.useState(Utils.getDateRangeFormat("Today"));
  

  const topSection = () => {
    return <Row className='align-items-center g-4'>
      <Col xs={12} md={'auto'} className={'title'}>Gantt Chart</Col>
      <Col xs={true} className='d-none d-md-block'></Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-outlined'}
          size='sm'
          onClick={() => {
            history.push('/jobs');
          }}
        >
          <ListIcon /> List View
        </Button>
      </Col>
      <Col xs={'auto'} className='d-none d-md-block'><div className='v-divider'></div></Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-outlined'}
          size='sm'
          onClick={() => {
            // dispatch(slice.setShow({ show: true, id: null }));
          }}
        >
          0 Unassigned Jobs
        </Button>
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-outlined'}
          size='sm'
          onClick={() => {
            // dispatch(slice.setShow({ show: true, id: null }));
          }}
        >
          Dispatch All
        </Button>
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-dark'}
          size='sm'
          onClick={() => {
            // dispatch(slice.setShow({ show: true, id: null }));
          }}
        >
          <PlusIcon /> New Job
        </Button>
      </Col>
    </Row>
  }
  
  const filterSection = () => {
    return <Row className='align-items-center g-4'>
      <Col xs={'auto'}>
        <div className={'search-input'}>
          <SearchIcon />
          <Form.Control
            type="text"
            size='sm'
            placeholder={'Search jobs'}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-outlined'}
          size='sm'
          onClick={() => {
            // dispatch(slice.setShow({ show: true, id: null }));
          }}
        >
          <FilterIcon /> Filters
        </Button>
      </Col>
      <Col xs={true} className='d-none d-md-block'></Col>
      <Col xs={'auto'}>
        <DropdownButton
          as={ButtonGroup}
          variant={'custom-outlined'}
          size='sm'
          title={'Daily'}
        >
          <Dropdown.Header>Sort by</Dropdown.Header>
          <Dropdown.Item>
            <Form.Check 
              type={'radio'}
              name={'sort_by_period'}
              id={'sort_by_Daily'}
              label={'Daily'}
              checked={true}
            />
          </Dropdown.Item>
          <Dropdown.Item>
            <Form.Check 
              type={'radio'}
              name={'sort_by_period'}
              id={'sort_by_Weekly'}
              label={'Weekly'}
              checked={false}
            />
          </Dropdown.Item>
          <Dropdown.Item>
            <Form.Check 
              type={'radio'}
              name={'sort_by_period'}
              id={'sort_by_Monthly'}
              label={'Monthly'}
              checked={false}
            />
          </Dropdown.Item>
        </DropdownButton>
      </Col>
      <Col xs={'auto'}>
        <RSuiteDateRangePickerGantt
          className={'date-range-picker'}
          placement={'bottomEnd'}
          placeholder={"Select date"}
          ranges={Utils.getDateRanges()}
          value={dateRange}
          cleanable={false}
          disabled={false}
          editable={false}
          icon={<CalendarIcon />}
          renderValue={(value: any, format: any) => {
            if( moment(value[0]).format(Utils.getGanttDateRangeFormat())  === moment(value[1]).format(Utils.getGanttDateRangeFormat())  ){
              let date = moment(value[0]).format(Utils.getGanttDateRangeFormat()) 
            
              return <div><span className="dateTicker">{date}</span></div>
            } else {
              let date = moment(value[0]).format(Utils.getGanttDateRangeFormat()) + " - " + moment(value[1]).format(Utils.getGanttDateRangeFormat());

              return <div><span className="dateTicker">{date}</span></div>
            }
          }}
          onChange={(value: any) => {
            const arr = (value && value.length > 0) ? value : [];

            const d1 = (arr && arr.length > 0) ? arr[0] : null;
            const d2 = (arr && arr.length > 1) ? arr[1] : null;

            let dateFormat: any = Utils.getAPIDateFormat();
            let fromDate: any = d1 ? moment(d1).format(dateFormat) : null;
            let toDate: any = d2 ? moment(d2).format(dateFormat) : null;

            let label = Utils.getDateRangeLabel(fromDate, toDate);
            let format = Utils.getDateRangeFormat(label);
            
            let dateRange = [];
            if(fromDate){
              dateRange.push(new Date(fromDate));
            }
            if(toDate){
              dateRange.push(new Date(toDate));
            }

            setFromDate(fromDate);
            setToDate(toDate);
            setDateRange(dateRange);
            setFormat(format);
          }}
        />
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-outlined'}
          size='sm'
          onClick={() => {
            // dispatch(slice.setShow({ show: true, id: null }));
          }}
        >
          Today
        </Button>
      </Col>
      <Col xs={'auto'} className='d-none d-md-block'><div className='v-divider'></div></Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-outlined'}
          size='sm'
          onClick={() => {
            setShow(!show);
          }}
        >
          <BarChart2Icon /> {(show) ? 'Hide Statistics' : 'Show Statistics'}
        </Button>
      </Col>
    </Row>
  }

  const statusSection = () => {
    return <Row className='align-items-center'>
      <Col xs={12}>
        <Carousel
          className={'custom-carousel'}
          itemsToShow={9}
          outerSpacing={0}
          itemPadding={[0, 0]}
          initialActiveIndex={0}
          breakPoints={[
            { width: 1, itemsToShow: 1 },
            { width: 200, itemsToShow: 2 },
            { width: 300, itemsToShow: 2 },
            { width: 400, itemsToShow: 2 },
            { width: 500, itemsToShow: 3 },
            { width: 600, itemsToShow: 3 },
            { width: 700, itemsToShow: 3 },
            { width: 800, itemsToShow: 4 },
            { width: 900, itemsToShow: 9 },
          ]}
          showEmptySlots={false}
          showArrows={true}
          pagination={false}
          isRTL={false}
        >
          <div className='custom-carousel-item active'
            onClick={() => {

            }}
          >
            <div className='count'>13</div>
            <div className='text'>Total jobs</div>
            <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
          </div>
          <div className='custom-carousel-item'
            onClick={() => {
              
            }}
          >
            <div className='count unallocated'>1</div>
            <div className='text'>Unset Time</div>
            <div className='v-divider v-divider-52 position-absolute top-0 end-0 mt-2'></div>
          </div>
          <div className='custom-carousel-item'
            onClick={() => {
              
            }}
          >
            <div className='count assigned'>9</div>
            <div className='text'>Assigned</div>
            <div className='v-divider v-divider-52 position-absolute top-0 end-0 mt-2'></div>
          </div>
          <div className='custom-carousel-item'
            onClick={() => {
              
            }}
          >
            <div className='count dispatched'>8</div>
            <div className='text'>Dispatched</div>
            <div className='v-divider v-divider-52 position-absolute top-0 end-0 mt-2'></div>
          </div>
          <div className='custom-carousel-item'
            onClick={() => {
              
            }}
          >
            <div className='count started'>5</div>
            <div className='text'>Started</div>
            <div className='v-divider v-divider-52 position-absolute top-0 end-0 mt-2'></div>
          </div>
          <div className='custom-carousel-item'
            onClick={() => {
              
            }}
          >
            <div className='count inprogress'>1</div>
            <div className='text'>In Progress</div>
            <div className='v-divider v-divider-52 position-absolute top-0 end-0 mt-2'></div>
          </div>
          <div className='custom-carousel-item'
            onClick={() => {
              
            }}
          >
            <div className='count completed'>5</div>
            <div className='text'>Completed</div>
            <div className='v-divider v-divider-52 position-absolute top-0 end-0 mt-2'></div>
          </div>
          <div className='custom-carousel-item'
            onClick={() => {
              
            }}
          >
            <div className='count cancelled'>0</div>
            <div className='text'>Cancellled/Failed</div>
          </div>
        </Carousel>
      </Col>
    </Row>
  }

  const legendSection = () => {
    return <Row className='align-items-center'>
      <Col xs={12} sm={true}>
        <Row className='align-items-center'>
          <Col xs={'auto'}><div className='dot dot-unallocated'></div>Unallocated Time</Col>
          <Col xs={'auto'}><div className='dot dot-assigned'></div>Assigned</Col>
          <Col xs={'auto'}><div className='dot dot-dispatched'></div>Dispatched</Col>
          <Col xs={'auto'}><div className='dot dot-started'></div>Started</Col>
          <Col xs={'auto'}><div className='dot dot-inprogress'></div>In Progress</Col>
          <Col xs={'auto'}><div className='dot dot-completed'></div>Completed</Col>
          <Col xs={'auto'}><div className='dot dot-cancelled'></div>Cancelled/Failed</Col>
        </Row>
      </Col>
      <Col xs={12} sm={'auto'} className='pt-7 pt-sm-0 ps-0'>
        <Form.Check
          id={'showActualJobTimeID'}
          type="switch"
          label={'Show Actual Job Time'}
          checked={showActualJobTime}
          onChange={(e) => {
            dispatch(slice.showActualJobTime(e.target.checked));
          }}
        />
      </Col>
      <Col xs={12} sm={'auto'} className='pt-7 pt-sm-0 ps-0'>
        <Form.Check
          id={'isVerticalID'}
          type="switch"
          label={'Vertical'}
          checked={isVertical}
          onChange={(e) => {
            dispatch(slice.setIsVertical(e.target.checked));
          }}
        />
      </Col>
    </Row>
  }


  return <div className={'new-gantt-chart-page'}>
    <Row>
      <Col xs={12} className={'top-section'}>
        {topSection()}
      </Col>
      <Col xs={12} className={'filter-section'}>
        {filterSection()}
      </Col>
      <Col xs={12} className={'status-section ' + (show ? 'd-block' : 'd-none')}>
        {statusSection()}
      </Col>
    </Row>
    <StyledRow>
      <Col xs={12} className={'legend-section'}>
        {legendSection()}
      </Col>
    </StyledRow>
    <Row>
      <Col xs={12} className={'list-section'}>
        <ListComponent />
      </Col>
    </Row>
  </div>
}

export default PageComponent;
