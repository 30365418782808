import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Overlay,
  Popover,
  Button,
} from 'react-bootstrap';

import {
  SvelteGantt,
  SvelteGanttTable,
  MomentSvelteGanttDateAdapter
} from "svelte-gantt";

// Redux
import { useDispatch, useSelector } from "react-redux";
import slice from './slice';

import Utils from '../../../utils/utils.js';
// import {  } from '../../../utils/enums.js';

import { ReactComponent as FilterIcon } from "../../../../_metronic/assets/icons/filter-gantt-chart.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../_metronic/assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../_metronic/assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpRightIcon } from "../../../../_metronic/assets/icons/arrow-up-right.svg";
import { ReactComponent as PlusIcon } from "../../../../_metronic/assets/icons/plus-gantt.svg";
import { ReactComponent as MinusIcon } from "../../../../_metronic/assets/icons/minus-gantt.svg";


export const GanttType = {
  None: 0,
  Empty: 1,
  Task: 2,
  Actual: 3,
  Row: 4,
}
export const GanttTableHeader = {
  Drivers: 'Drivers',
  Vehicle: 'Vehicle',
  Jobs: 'Jobs',
  Dispatched: 'Dispatched',
  Completed: 'Completed',
  Route: 'Route',
}


/** FUNCTIONS */
const time = (input) => {
  return moment(input, "DD/MM/YYYY hh:mm");
}
const addNoneTasks = (rows = [], dateStr, x = 1) => {
  let emptyTasks = [];

  emptyTasks = rows.map((row, i) => {
    let items = [];
    
    if(x > 0){
      for(let j = 0; j < x; j++){
        items.push({
          id: 'none_row_' + row.id + '_item_' + j,
          resourceId: row.id,
          label: '',
          from: time(dateStr + " 00:00"),
          to: time(dateStr + " 00:01"),
          draggable: false,
          resizable: false,
          classes: '',
          type: GanttType.None,
          data: null,
        });
      }
    }

    return items;
  });

  return _.flatten(emptyTasks);
};
const addEmptyTasks = (rows = [], dateStr, x = 3) => {
  let emptyTasks = [];

  emptyTasks = rows.map((row, i) => {
    let items = [];
    
    if(x > 0){
      for(let j = 0; j < x; j++){
        items.push({
          id: 'empty_row_' + row.id + '_item_' + j,
          resourceId: row.id,
          label: '',
          from: time(dateStr + " 00:00"),
          to: time(dateStr + " 00:01"),
          draggable: false,
          resizable: false,
          classes: '',
          type: GanttType.Empty,
          data: null,
        });
      }
    }

    return items;
  });

  return _.flatten(emptyTasks);
};
/** END FUNCTIONS */



/** COMPONENTS */
const Accordion = (props) => {
  const [isOpen, setIsOpen] = React.useState(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);

  React.useEffect(() => {
    setIsOpen(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);
  }, [props.isOpen]);

  return <>
    <Row className={'align-items-center'}>
      <Col xs={true} className={'text-driver'}>
        {props.title}
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-secondary-light'}
          className='button-expand'
          size='sm'
          onClick={() => {
            if(props.onChange){
              props.onChange(!isOpen);
            }
          }}
        >
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </Button>
      </Col>
    </Row>
    {isOpen && <Row>
      <Col xs={12}>{props.children}</Col>
    </Row>}
  </>
}


const CustomTaskComponent = ({ task }) => {
  return <div className={'custom-task ' + (task.draggable ? 'draggable' : '') + ' ' + (task.resizable ? 'resizable' : '')}>
    <div className='title'><span>{task?.label}</span></div>
    <div className='desc'><span>{task?.data?.desc}</span></div>
    <div className='desc'><span>{task?.data?.desc}</span></div>
  </div>
};

const CustomTaskPopupComponent = ({ task }) => {
  return <div className={'custom-task-popup'}>
    <div className='title'>{task?.label}</div>
    <div className='desc'>{task?.data?.desc}</div>
    <div className='desc'>{task?.data?.desc}</div>
  </div>
};


const CustomTableHead = ({ isVertical, minWidth }) => {
  if(isVertical){
    return <Row>
      <Col xs={true}>{GanttTableHeader.Drivers}</Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-icon-secondary'}
          onClick={() => {
            
          }}
        >
          <FilterIcon />
        </Button>
      </Col>
    </Row>
  } else {
    return <Row style={{ minWidth: minWidth }}>
      <Col xs={3}>
        <Row className='g-2'>
          <Col xs={'auto'}>{GanttTableHeader.Drivers}</Col>
          <Col xs={'auto'}>
            <Button
              variant={'custom-icon-secondary'}
              onClick={() => {
                
              }}
            >
              <FilterIcon />
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={true}>{GanttTableHeader.Vehicle}</Col>
      <Col xs={true}>{GanttTableHeader.Jobs}</Col>
      <Col xs={true}>{GanttTableHeader.Dispatched}</Col>
      <Col xs={true}>{GanttTableHeader.Completed}</Col>
      <Col xs={true}>{GanttTableHeader.Route}</Col>
    </Row>
  }
};
const CustomTableRow = ({ index, isVertical, row, minWidth, onOpen, onInit }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const isInitialRender = React.useRef(true);


  React.useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      
      if(onInit){
        onInit();
      }
    } else {
      if(onOpen){
        onOpen(isOpen);
      }
    }
  }, [isOpen]);


  if(isVertical){
    return <div>
      <Accordion
        title={row.driver}
        isOpen={isOpen}
        onChange={(isOpen) => {
          setIsOpen(isOpen);
        }}
      >
        <div className='card-driver-info'>
          <Row>
            <Col xs={6} className='text-other-vertical'>{GanttTableHeader.Vehicle}</Col>
            <Col xs={6} className='text-other-vertical active text-end'>{row.vehicle}</Col>
          </Row>
          <Row>
            <Col xs={6} className='text-other-vertical'>{GanttTableHeader.Jobs}</Col>
            <Col xs={6} className='text-other-vertical active text-end'>{row.jobs}</Col>
          </Row>
          <Row>
            <Col xs={6} className='text-other-vertical'>{GanttTableHeader.Dispatched}</Col>
            <Col xs={6} className='text-other-vertical active text-end'>{row.dispatched}</Col>
          </Row>
          <Row>
            <Col xs={6} className='text-other-vertical'>{GanttTableHeader.Completed}</Col>
            <Col xs={6} className='text-other-vertical active text-end'>{row.completed}</Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                className='w-100'
                variant={'custom-outlined'}
                size='sm'
                onClick={() => {
                  
                }}
              >
                <ArrowUpRightIcon /><span className='ps-2'>Show {GanttTableHeader.Route}</span>
              </Button>
            </Col>
          </Row>
        </div>
      </Accordion>
    </div>

  } else {
    return <Row style={{ minWidth: minWidth }}>
      <Col xs={true} className='text-driver'>{row.driver}</Col>
      <Col xs={true} className='text-other-horisontal'>{row.vehicle}</Col>
      <Col xs={true} className='text-other-horisontal'>{row.jobs}</Col>
      <Col xs={true} className='text-other-horisontal'>{row.dispatched}</Col>
      <Col xs={true} className='text-other-horisontal'>{row.completed}</Col>
      <Col xs={true} className='text-other-horisontal'>{row.route}</Col>
    </Row>
  }
};
const CustomTableRowActual = ({ index, row, minWidth }) => {
  return <Row style={{ minWidth: minWidth }}>
    <Col xs={true} className='text-driver'>{row.driver}</Col>
    <Col xs={'auto'} className='text-other-vertical'>Actual Job Time</Col>
  </Row>
};
const CustomZoom = () => {
  return <Row className='g-0'>
    <Col xs={'auto'}>
      <Button
        variant={'zoom'}
        onClick={() => {
          console.log('⚡ ZOOM | -:');
        }}
      >
        <MinusIcon />
      </Button>
    </Col>
    <Col xs={'auto'}>
      <Button
        variant={'zoom'}
        onClick={() => {
          console.log('⚡ ZOOM | +:');
        }}
      >
        <PlusIcon />
      </Button>
    </Col>
  </Row>
};
/** END COMPONENTS */



const ListComponent = React.forwardRef((props: any, ref: any) => {
  const GANTT = React.useRef(null);
  const refGanttChart = React.useRef();

  const [showPopup, setShowPopup] = React.useState(false);
  const [targetPopup, setTargetPopup] = React.useState(null);
  const [clickedTask, setClickedTask] = React.useState(null);
  const refPopup = React.useRef(null);
  
  const [rows, setRows] = React.useState([]);
  const [tasks, setTasks] = React.useState([]);
  
  const { isInitLoading, isVertical, showActualJobTime } = useSelector((state) => state.ganttChartSlice);
  const dispatch = useDispatch();
  

  const TABLE_WITDH_HORISONTAL = 455;
  const TABLE_WITDH_VERTICAL = 200;

  const todayDate = moment().format("DD/MM/YYYY");
  const todayDateTime = time(todayDate + " 07:30");

  // const currentStart = time(todayDate + " 00:00");
  // const currentEnd = time(todayDate + " 23:59");

  const currentStart = time("01/09/2024 00:00");
  const currentEnd = time("31/12/2024 23:59");

  let dataRows = [];
  for(let i = 0; i < 20; i++){
    dataRows.push({
      id: (i+1),
      driver: "David" + (i+1),
      vehicle: 'Vehicle ' + (i+1),
      jobs: i,
      dispatched: "2",
      completed: "4/5",
      route: "R2",
    });
  }

  let rowsItems = [];
  for(let i = 0; i < 20; i++){
    rowsItems.push({
      ...dataRows[i],
      id: (i+1),
      headerHtml: '<div class="my-custom-row"></div>',
      type: GanttType.Row,
    });
  }

  let rowsActulItems = [];
  for(let i = 0; i < 20; i++){
    rowsActulItems.push({
      ...dataRows[i],
      id: (i+1),
      headerHtml: '<div class="my-custom-row"></div>',
      type: GanttType.Row,
    });
    rowsActulItems.push({
      ...dataRows[i],
      id: (i+1) + '_actual',
      enableDragging: false,
      enableResize: false,
      headerHtml: '<div class="my-custom-row"></div>',
      type: GanttType.Actual,
    });
  }

  let dataTasks = [
    {
      id: 1,
      label: "assigned",
      desc: 'Wiegand, Schroeder, and Boerer 1',
      from: time(todayDate + " 08:00"),
      to: time(todayDate + " 08:45"),
    },
    {
      id: 2,
      label: "dispatched",
      desc: 'Wiegand, Schroeder, and Boerer 2',
      from: time(todayDate + " 08:00"),
      to: time(todayDate + " 09:00"),
    },
    {
      id: 3,
      label: "started",
      desc: 'Wiegand, Schroeder, and Boerer 3',
      from: time(todayDate + " 08:00"),
      to: time(todayDate + " 09:15"),
    },
    {
      id: 4,
      label: "inprogress",
      desc: 'Wiegand, Schroeder, and Boerer 4',
      from: time(todayDate + " 08:00"),
      to: time(todayDate + " 09:30"),
    },
    {
      id: 5,
      label: "completed",
      desc: 'Wiegand, Schroeder, and Boerer 5',
      from: time(todayDate + " 08:00"),
      to: time(todayDate + " 09:45"),
    },
    {
      id: 6,
      label: "cancelled",
      desc: 'Wiegand, Schroeder, and Boerer 6',
      from: time(todayDate + " 08:00"),
      to: time(todayDate + " 10:00"),
    },
    {
      id: 7,
      label: "unallocated",
      desc: 'Wiegand, Schroeder, and Boerer 7',
      from: time(todayDate + " 08:00"),
      to: time(todayDate + " 10:15"),
    }
  ];
  const tasksItems = [
    {
      ...dataTasks[0],
      resourceId: 1,
      draggable: true,
      resizable: true,
      classes: 'assigned',
      type: GanttType.Task,
      data: dataTasks[0],
    },
    {
      ...dataTasks[1],
      resourceId: 1,
      draggable: true,
      resizable: true,
      classes: 'dispatched',
      type: GanttType.Task,
      data: dataTasks[1],
    },
    {
      ...dataTasks[2],
      resourceId: 1,
      draggable: true,
      resizable: true,
      classes: 'started',
      type: GanttType.Task,
      data: dataTasks[2],
    },
    {
      ...dataTasks[3],
      resourceId: 1,
      draggable: true,
      resizable: true,
      classes: 'inprogress',
      type: GanttType.Task,
      data: dataTasks[3],
    },
    {
      ...dataTasks[4],
      resourceId: 1,
      draggable: true,
      resizable: true,
      classes: 'completed',
      type: GanttType.Task,
      data: dataTasks[4],
    },
    {
      ...dataTasks[5],
      resourceId: 1,
      draggable: true,
      resizable: true,
      classes: 'cancelled',
      type: GanttType.Task,
      data: dataTasks[5],
    },
    {
      ...dataTasks[6],
      resourceId: 1,
      draggable: true,
      resizable: true,
      classes: 'unallocated',
      type: GanttType.Task,
      data: dataTasks[6],
    },
    {
      ...dataTasks[6],
      id: dataTasks[6].id + '_actual',
      resourceId: 1 + '_actual',
      draggable: false,
      resizable: false,
      classes: 'unallocated',
      type: GanttType.Actual,
      data: dataTasks[6],
    }
  ];



	React.useImperativeHandle(
    ref,
    () => ({
      scrollToTask(params) {
        scrollToTask(params);
      },
      scrollToRow(params) {
        scrollToRow(params);
      },
      scrollToNow(params) {
        scrollToNow(params);
      },
    }),
  );



  // API Call and Gantt Chart destructor
  React.useEffect(() => {
    dispatch(slice.setIsInitLoading(false));
    setTimeout(() => {
      setRows(showActualJobTime ? rowsActulItems : rowsItems);
      setTasks(tasksItems);
      dispatch(slice.setIsInitLoading(true));
    }, 500);

    return () => {
      destroy();
    };
  }, []);

  // Init Gantt Chart, custom rows and tasks
  React.useEffect(() => {
    if(isInitLoading){
      initGanttChart();
    } else {
      destroy();
    }
  }, [isInitLoading]);

  // Init on task click popup
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (refPopup.current && !refPopup.current.contains(event.target)) {
        setShowPopup(false);
        setTargetPopup(null);
        setClickedTask(null);
      }
    };

    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);
  
  // Change table style (Horisontal/Vertical)
  React.useEffect(() => {
    dispatch(slice.setIsInitLoading(false));
    setTimeout(() => {
      dispatch(slice.setIsInitLoading(true));
    }, 500);
  }, [isVertical]);

  // API Call and On show actual job time
  React.useEffect(() => {
    dispatch(slice.setIsInitLoading(false));
    setTimeout(() => {
      setRows(showActualJobTime ? rowsActulItems : rowsItems);
      dispatch(slice.setIsInitLoading(true));
    }, 500);
  }, [showActualJobTime]);


  /** FUNCTIONS */
  const initGanttChart = () => {
    try {
      GANTT.current = new SvelteGantt({ target: refGanttChart.current, props: getOptions() });

      if(GANTT && GANTT.current){
        console.log('⚡ | GANTT.current:', GANTT.current);
        setTimeout(() => {
          addRowsComponenent();
          addZoomButtonsComponenent();

          let taskChanged = false;
          const tasksEls = document.querySelectorAll('.sg-task');
          tasksEls.forEach(taskElement => {
            taskElement.addEventListener('click', (event) => {
              if (!taskChanged) {
                const taskId = taskElement.getAttribute('data-task-id');
                let task = tasks.find(x => x.id == taskId);
                onTaskClick(task, event);
              }
              taskChanged = false;
            });
          });

          GANTT.current.api.tasks.on.changed((task) => {
            onTaskMove(task);
            taskChanged = true;
          });
          GANTT.current.api.tasks.on.move((task) => {
            taskChanged = true;
            setShowPopup(false);
            setTargetPopup(null);
            setClickedTask(null);
          });
          GANTT.current.api.tasks.on.resize((task) => {
            setShowPopup(false);
            setTargetPopup(null);
            setClickedTask(null);
          });

          GANTT.current.api.timeranges.on.changed((task) => {
            console.log('⚡ timeranges | changed:', task);
          });
          GANTT.current.api.timeranges.on.clicked((task) => {
            console.log('⚡ timeranges | clicked:', task);
          });
          GANTT.current.api.timeranges.on.resized((task) => {
            console.log('⚡ timeranges | resized:', task);
          });

          GANTT.current.api.gantt.on.dateSelected((task) => {
            console.log('⚡ gantt | dateSelected:', task);
          });
          GANTT.current.api.gantt.on.viewChanged((task) => {
            console.log('⚡ gantt | viewChanged:', task);
          });

          // scrollToRow({ id: 5 });
          scrollToNow();
        }, 0);
      }
    }catch(e){}
  };
  const getOptions = () => {
    return {
      dateAdapter: new MomentSvelteGanttDateAdapter(moment),
      ganttTableModules: [SvelteGanttTable],

      from: currentStart,
      to: currentEnd,
      
      rows: rows,
      tasks: tasks,
      dependencies: [],
      layout: 'expand',
      timeRanges: [],
      rowHeight: 57,
      rowPadding: 0,
      taskContent: (task) => {
        const fromMoment = moment.isMoment(task.from) ? task.from : moment(task.from);
        const toMoment = moment.isMoment(task.to) ? task.to : moment(task.to);
        
        return ReactDOMServer.renderToStaticMarkup(<CustomTaskComponent task={{ ...task, from: fromMoment, to: toMoment }} />);
      },
      columnStrokeColor: '#ececec',
      fitWidth: true,
      tableHeaders: [
        { title: 'header', property: 'header' },
      ],
      tableWidth: isVertical ? TABLE_WITDH_VERTICAL : TABLE_WITDH_HORISONTAL,
      
      magnetUnit: "minute",
      magnetOffset: 1,
      headers: [
        { unit: 'minute', offset: 30, format: 'HH:mm' },
      ],
      minWidth: 102400,
      columnUnit: 'minute',
      columnOffset: 30,

      zoomLevels: [
        {
          headers: [
            { unit: 'minute', offset: 30, format: 'HH:mm' },
          ],
          minWidth: 102400,
          columnUnit: 'minute',
          columnOffset: 30
        },
        {
          headers: [
            { unit: 'day', format: 'ddd' }
          ],
          minWidth: 25600,
          columnUnit: 'day',
          columnOffset: 1
        },
        {
          headers: [
            { unit: 'day', format: 'DD' }
          ],
          minWidth: 12800,
          columnUnit: 'day',
          columnOffset: 1
        },
      ],

      timeRanges: [
        {
          id: 1,
          from: time(moment(todayDateTime)),
          to: time(moment(todayDateTime).add(1, 'm')),
          classes: 'time-range-now',
          label: 'Now',
          resizable: false,
        },
      ]
    }
  };
  const destroy = () => {
    if(GANTT && GANTT.current){
      GANTT.current.$destroy();
    }
  };

  const addRowsComponenent = () => {
    try {
      const headers = document.querySelectorAll('.sg-table-header .sg-table-header-cell');
      headers.forEach((header) => {
        ReactDOM.render(<CustomTableHead
          isVertical={isVertical}
          minWidth={isVertical ? TABLE_WITDH_VERTICAL : TABLE_WITDH_HORISONTAL}
        />, header);
      });
      
      const rowsEls = document.querySelectorAll('.my-custom-row');
      rowsEls.forEach((row, i) => {
        if(rows[i] && rows[i].type == GanttType.Actual){
          // row.style.alignItems = 'center';

          ReactDOM.render(<CustomTableRowActual
            index={i}
            minWidth={isVertical ? TABLE_WITDH_VERTICAL : TABLE_WITDH_HORISONTAL}
            row={rows[i]}
          />, row);

        } else {
          // row.style.alignItems = isVertical ? 'flex-start' : 'center';

          ReactDOM.render(<CustomTableRow
            index={i}
            isVertical={isVertical}
            minWidth={isVertical ? TABLE_WITDH_VERTICAL : TABLE_WITDH_HORISONTAL}
            row={rows[i]}
            onOpen={(isOpen) => {
              if(isOpen){
                addEmptyTask(rows[i]);
              } else {
                removeEmptyTask(rows[i]);
              }
            }}
            onInit={() => {
              addNoneTask(rows[i]);
            }}
          />, row);
        }
      });
    }catch(e){}
  };
  const addZoomButtonsComponenent = () => {
    try {
      if(refGanttChart && refGanttChart.current){
        const zoomDiv = document.createElement('div');
        zoomDiv.classList.add('custom-zoom-buttons');
        refGanttChart.current.appendChild(zoomDiv);
        ReactDOM.render(<CustomZoom />, zoomDiv);
      }
    }catch(e){}
  };

  const addNoneTask = (row = null) => {
    try {
      if(row){
        let noneRows = addNoneTasks([row], todayDate);
        let alltasks = _.concat(noneRows, tasks);

        if(GANTT && GANTT.current){
          GANTT.current.updateTasks(alltasks);
        }
      }
    }catch(e){}
  };
  const addEmptyTask = (row = null) => {
    try {
      if(row){
        let emptyRows = addEmptyTasks([row], todayDate);
        let alltasks = _.concat(emptyRows, tasks);
        
        if(GANTT && GANTT.current){
          GANTT.current.updateTasks(alltasks);
        }
      }
    }catch(e){}
  };
  const removeEmptyTask = (row = null) => {
    try {
      if(row){
        let taskArr = GANTT.current.getTasks(row.id);
        let taskModelArr = (taskArr && taskArr.length > 0) ? taskArr.map(x => x.model) : []
        let removetasks = taskModelArr.filter(x => x.type === GanttType.Empty)

        if(GANTT && GANTT.current){
          GANTT.current.removeTasks(removetasks.map(x => x.id));
        }
      }
    }catch(e){}
  };

  const scrollToTask = ({ id = null, offset = 100, scrollBehavior = 'auto' } = {}) => {
    try {
      if(id){
        setTimeout(() => {
          if(refGanttChart && refGanttChart.current){
            const elementBody = refGanttChart.current.querySelector('.sg-timeline-body');
            const element = refGanttChart.current.querySelector(`.sg-foreground [data-task-id="${id}"]`);
            
            if (elementBody && element) {
              const elementRect = element.getBoundingClientRect();
              const bodyRect = elementBody.getBoundingClientRect();

              const scrollLeft = elementRect.left - bodyRect.left + elementBody.scrollLeft - offset;

              elementBody.scrollTo({
                left: scrollLeft,
                behavior: scrollBehavior
              });
            }
          }
        }, 50);
      }
    }catch(e){}
  };
  const scrollToRow = ({ id = null, offset = 65, scrollBehavior = 'auto' } = {}) => {
    try {
      if(id){
        setTimeout(() => {
          if(refGanttChart && refGanttChart.current){
            const rowElement = refGanttChart.current.querySelector(`[data-row-id="${id}"]`);
            
            if (rowElement) {
              const elementPosition = rowElement.getBoundingClientRect().top + window.pageYOffset;
              const offsetPosition = elementPosition - offset;

              window.scrollTo({
                top: offsetPosition,
                behavior: scrollBehavior,
              });
            }
          }
        }, 50);
      }
    }catch(e){}
  };
  const scrollToNow = ({ offset = 300, scrollBehavior = 'auto' } = {}) => {
    try {
      setTimeout(() => {
        if(refGanttChart && refGanttChart.current){
          const elementBody = refGanttChart.current.querySelector('.sg-timeline-body');
          const element = refGanttChart.current.querySelector('.sg-foreground .time-range-now');
          
          if (elementBody && element) {
            const elementRect = element.getBoundingClientRect();
            const bodyRect = elementBody.getBoundingClientRect();

            const scrollLeft = elementRect.left - bodyRect.left + elementBody.scrollLeft - offset;

            elementBody.scrollTo({
              left: scrollLeft,
              behavior: scrollBehavior
            });
          }
        }
      }, 50);
    }catch(e){}
  };


  const onTaskClick = (task, e) => {
    if(showPopup){
      setShowPopup(false);
      setTargetPopup(null);
      setClickedTask(null);
    } else {
      setShowPopup(true);
      setTargetPopup(e.target);
      setClickedTask(task);
    }
  };
  const onTaskMove = (task) => {
    console.log('⚡ onTaskMove:', task);
  };
  /** END FUNCTIONS */

  

  return <>
    {isInitLoading && <div ref={refGanttChart} className='position-relative' />}

    <Overlay
      show={showPopup}
      target={targetPopup}
      placement={'top'}
    >
      <Popover>
        <Popover.Body ref={refPopup}>
          <CustomTaskPopupComponent task={clickedTask} />
        </Popover.Body>
      </Popover>
    </Overlay>
  </>
});

export default ListComponent;
