/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import scrollToElement from 'scroll-to-element';
import Tinycon from 'tinycon';
import Moment from 'moment';
import _ from 'lodash';
import { extendMoment } from 'moment-range';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { isMobile, isTablet } from "react-device-detect";
import queryString from "query-string";
import diff from "diff-arrays-of-objects";
import styled from 'styled-components';
import { PagesPermissions, RolesPermissions, JobStepType, JobStepBinActivity, AccountDocSettingVariables, StepType, StepAddress, DayOfWeek, PageCookieSettingType, PayrollTemplateVariableType, ServiceType, AuditTrailObjectTypeText, RepeatOrder, JobAction, BillingStatus, ParentJobRelation, AccountSettingType, InOrOut, ReportUOM, XeroPaymentTermTypeItems, BillingTypes } from './enums';
import numeral from 'numeral';
import { City  }  from 'country-state-city';
import { getCountryCallingCode } from 'react-phone-number-input';
import isValidCoordinates from "is-valid-coordinates";
import parse from "html-react-parser";

import { DrawerComponent } from '../../_metronic/assets/ts/components'
import { DrawerEnum } from '../components/drawer/DrawerLayout'


import userCookieSettingApi from "../api/CommonProfile/UserCookieSetting";
import { readApi } from "../api/CRUD/DispatchCRUD";


import {
    ReportTable,
    ReportThead,
    ReportTbody,
    ReportTr,
    ReportTh,
    ReportTd,
} from "../../app/components/OLD/Pages/Reports/CustomTable.jsx";


const moment = extendMoment(Moment);

const { REACT_APP_IS_PRODUCTION } = process.env;

const CHARACTERS = ['\\', ':', ';', '*', '?', '"', '<', '>', '|',];


const Utils = {

    isProduction: () => {
        return (REACT_APP_IS_PRODUCTION === 'true') ? true : false;
    },

    isStaging: () => {
        return (REACT_APP_IS_PRODUCTION === 'true') ? false : true;
    },

    isMobileTablet: () => {
        return (isMobile || isTablet);
    },

    parseResult(result = null, success = null, error = null) {
        let status = null;
        if (result && result.status) {
            status = result.status;
        }

        if (status === 400) {
            if (error) {
                if (!result || !result.errorMessage || !result.errorMessage.error || result.errorMessage.error === '') {
                    if (result && result.errorMessage && result.errorMessage.NeedForceDelete) {
                        error(result.errorMessage.Error, 'error', [], true, 1);
                        console.info(result.errorMessage.Error);
                    } else {
                        error('The application has encountered an unknown error!', 'warning', null, false, 2);
                        console.info('The application has encountered an unknown error!');
                    }
                } else if (result && result.errorMessage && result.errorMessage.error && result.errorMessage.error !== '') {
                    error(result.errorMessage.error, 'error', [], false, 3);
                    console.info(result.errorMessage.error);
                } else {
                    error('Unknown error occurred. Please try again', 'warning', null, false, 4);
                    console.info('Unknown error occurred. Please try again');
                }
            }
            return;
        }

        if (result.errorMessage === null || result.errorMessage === '') {
            if (result && result.data && result.data.data) {
                let data = result.data.data;

                if (success) {
                    success(data, 'success');
                }
            } else if (result && result.data && result.data.data === '') {
                if (success) {
                    success('', 'success');
                }
            } else {
                if (error) {
                    error('An error occurred, please try again!', 'warning', null, false, 5);
                    console.info('An error occurred, please try again!');
                }
            }
        } else {
            if (typeof result.errorMessage === 'string') {
                if (error) {
                    error(result.errorMessage, 'error', null, false, 6);
                    console.info(result.errorMessage);
                }
            } else {
                if (result.errorMessage.errors) {
                    if (result.errorMessage.errors && (typeof result.errorMessage.errors === 'object')) {
                        let errorsArr = [];
                        let errArr = Object.keys(result.errorMessage.errors).map((err, i) => {
                            errorsArr = {
                                field: err,
                                error: result.errorMessage.errors[err][0]
                            };
                            return result.errorMessage.errors[err][0];
                        });

                        if (error) {
                            error(errArr, 'error', errorsArr, false, 7);
                            console.info(errArr);
                        }
                    } else {

                        let errorsArr = [];
                        let errArr = Object.keys(result.errorMessage).map((err, i) => {
                            errorsArr = {
                                field: err,
                                error: result.errorMessage.errors[err][0]
                            };
                            return result.errorMessage[err];
                        });

                        if (error) {
                            error(errArr, 'error', errorsArr, false, 8);
                            console.info(errArr);
                        }
                    }
                } else {
                    if (result.errorMessage.error) {
                        if (error) {
                            error(result.errorMessage.error, 'error', [], false);
                        }
                    } else {
                        if (error) {
                            error('Error', 'error', [], false, 9);
                            console.info('Error');
                        }
                    }
                }
            }
        }
    },
    parseError(error = null, callback = null) {
        let err = Utils.catchResponseError(error);

        if (callback) {
            callback({
                isError: err.isError,
                errorMessage: err.errorMessage,
                status: err.status,
            });
        }
    },
    parseErrorTS(error = null, callback: any = null) {
        let err = Utils.catchResponseError(error);

        if (callback) {
            callback({
                isError: err.isError,
                errorMessage: err.errorMessage,
                status: err.status,
            });
        }
    },
    catchResponseError(error = null) {
        let isError = true;
        let status = null;
        let errorMessage = null;

        try {
            status = error.response.status;

            if (error.response) {
                // Request made and server responded
                errorMessage = error.response.data;
            } else if (error.request) {
                // The request was made but no response was received
                errorMessage = error.request;
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
        } catch (err) {
            // errorMessage = 'Service Unavailable!'
        }

        // if(isError){
        //     console.info(errorMessage);
        // }

        return {
            isError: isError,
            errorMessage: errorMessage,
            status: status,
        }
    },
    isNeedForceDelete(err) {
        if (err && err.NeedForceDelete) {
            if (typeof err.NeedForceDelete == "boolean") {
                return err.NeedForceDelete;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    parseErr(err = null) {
        if (err) {
            if (typeof err === 'string' && err !== '') {
                return err
            } else if (typeof err === 'object') {
                if (err && err.error && err.error !== '') {
                    return err.error
                } else if (err && err.Error && err.Error !== '') {
                    return err.Error
                }
            }
        }
    },

    mapStateToProps(state) {
        return {
            isResponse: state.dispatchCrud.isResponse,
            name: state.dispatchCrud.name,
            path: state.dispatchCrud.path,
            data: state.dispatchCrud.data,
            err: state.dispatchCrud.err,
            isError: state.dispatchCrud.isError,
            isLoading: state.dispatchCrud.isLoading,
            statusCode: state.dispatchCrud.statusCode,
            variables: state.dispatchCrud.variables,
            callback: state.dispatchCrud.callback,
            callbackError500: state.dispatchCrud.callbackError500,
        }
    },
    reduxProps(nextProps = null, name = '', callback = null) {
        if (nextProps && nextProps.name === name) {
            let isResponse = ((nextProps.isResponse === false) || (nextProps.isResponse === true)) ? nextProps.isResponse : false;
            let statusCode = (nextProps.statusCode) ? nextProps.statusCode : null;
            let data = (nextProps.data) ? nextProps.data : null;
            let isLoading = ((nextProps.isLoading === false) || (nextProps.isLoading === true)) ? nextProps.isLoading : false;
            let isError = ((nextProps.isError === false) || (nextProps.isError === true)) ? nextProps.isError : false;
            let err = (nextProps.err) ? nextProps.err : null;
            let path = (nextProps.path) ? nextProps.path : null;
            let variables = (nextProps.variables) ? nextProps.variables : null;
            let isCallback = (!isLoading && isResponse && path === null) ? true : false;

            if (statusCode === 500) {
                console.info('⚡ onResponse | REDIRECT | /error/500:', {
                    isResponse: nextProps.isResponse,
                    name: nextProps.name,
                    path: nextProps.path,
                    params: nextProps.params,
                    data: nextProps.data,
                    err: nextProps.err,
                    isError: nextProps.isError,
                    statusCode: nextProps.statusCode,
                    callbackError500: nextProps.callbackError500,
                });

                if (nextProps.callbackError500) {
                    nextProps.callbackError500();
                } else {
                    window.location.href = '/error/500';
                }
            } else if (statusCode === 401) {
                window.location.href = '/';
            }

            let isNeedForceDelete = false;
            let error = Utils.parseErr(err);

            if (isError) {
                if (error && error !== '') {
                    Utils.toast(error, 'error');
                }

                if (Utils.isNeedForceDelete(err)) {
                    isNeedForceDelete = true;
                }
            }

            if (isResponse && callback) {
                callback(data, isLoading, isError, ((error && error !== '') ? error : err), statusCode, variables, (isCallback ? nextProps.callback : null), isNeedForceDelete);
            }
        }
    },
    reduxPropsTS(nextProps, name, callback) {
        if (nextProps && nextProps.name === name) {
            let isResponse = ((nextProps.isResponse === false) || (nextProps.isResponse === true)) ? nextProps.isResponse : false;
            let statusCode = (nextProps.statusCode) ? nextProps.statusCode : null;
            let data = (nextProps.data) ? nextProps.data : null;
            let isLoading = ((nextProps.isLoading === false) || (nextProps.isLoading === true)) ? nextProps.isLoading : false;
            let isError = ((nextProps.isError === false) || (nextProps.isError === true)) ? nextProps.isError : false;
            let err = (nextProps.err) ? nextProps.err : null;
            let path = (nextProps.path) ? nextProps.path : null;
            let variables = (nextProps.variables) ? nextProps.variables : null;
            let isCallback = (!isLoading && isResponse && path === null) ? true : false;

            if (statusCode === 500) {
                console.info('⚡ onResponse | REDIRECT | /error/500:', {
                    isResponse: nextProps.isResponse,
                    name: nextProps.name,
                    path: nextProps.path,
                    params: nextProps.params,
                    data: nextProps.data,
                    err: nextProps.err,
                    isError: nextProps.isError,
                    statusCode: nextProps.statusCode,
                    callbackError500: nextProps.callbackError500,
                });

                if (nextProps.callbackError500) {
                    nextProps.callbackError500();
                } else {
                    window.location.href = '/error/500';
                }
            } else if (statusCode === 401) {
                window.location.href = '/';
            }

            if (isResponse && callback) {
                callback(data, isLoading, isError, err, statusCode, variables, (isCallback ? nextProps.callback : null));
            }
        }
    },

    callProgressApis: async (apis, progressCallback, preCallback, postCallback, cancelCallback) => {
        if (apis && apis.length > 0) {
            if (preCallback) {
                preCallback();
            }

            try {
                await Utils.callApisSequentially(apis, progressCallback);
                if (postCallback) {
                    postCallback();
                }
            } catch (error) {
                if (cancelCallback) {
                    cancelCallback();
                }
            }
        }
    },
    async callApisSequentially(apis, progressCallback) {
        let count = 0;
        let total = (apis && apis.length > 0) ? apis.length : 0;

        for (let { api, path, params, callback } of apis) {
            count = count + 1;
            let percent = parseInt((count / total) * 100);

            try {
                let data = typeof params === 'function' ? params() : null;
                let response = await api(data, path);

                let res = { data: response, errorMessage: null, status: response.status };

                if (callback) {
                    callback(res);
                }

                if (progressCallback) {
                    progressCallback(percent)
                }

            } catch (error) {
                let errorObj = Utils.catchResponseError(error);
                let res = { data: null, errorMessage: errorObj.errorMessage, status: errorObj.status };

                if (callback) {
                    callback(res);
                }

                if (progressCallback) {
                    progressCallback(percent)
                }
            }
        }
    },

    callAllApi: (apis = null, preCallback = null, postCallback = null) => {
        if (apis && apis.length > 0) {
            if (typeof preCallback === 'function') {
                preCallback();
            }

            const promises = apis.map(({ api, path, params, callback, shouldCall = true }, i) => {
                if (shouldCall != true) {
                    let res = { data: null, errorMessage: null, status: null };

                    if (callback) {
                        callback(res)
                    }

                    return res
                } else {
                    let data = (typeof params === 'function') ? params() : null;

                    return api(data, path).then(response => {
                        let res = { data: response, errorMessage: null, status: response.status };

                        if (callback) {
                            callback(res)
                        }

                        return res
                    }).catch(error => {
                        let errorObj = Utils.catchResponseError(error);
                        let res = { data: null, errorMessage: errorObj.errorMessage, status: errorObj.status };

                        if (callback) {
                            callback(res)
                        }

                        return res
                    });
                }
            });

            Promise.all(promises).then(results => {
                if (typeof postCallback === 'function') {
                    postCallback(results);
                }
            });
        }
    },

    callSameApiMultipleTime: (tableRef = null, path = null, api = null, params = null, callback = null) => {
        let data = [];
        if (tableRef && api) {
            let selected = Utils.getSelectedRows(tableRef);

            if (selected && selected.length > 0) {
                data = selected.map((item, i) => {
                    return {
                        api: api,
                        path: path,
                        params: (typeof params === 'function') ? () => params(item) : null,
                    }
                });
            }

            Utils.callAllApi(data, null, callback);
        }
    },

    getSelectedRows: (tableRef = null) => {
        let selected = [];

        try {
            if (tableRef && tableRef.current) {
                selected = tableRef.current.getSelectedRows();
            }
        } catch (e) { }

        return selected;
    },
    getSelected: (tableRef = null) => {
        let selected = '';

        try {
            if (tableRef && tableRef.current) {
                let selectedArr = tableRef.current.getSelected();
                if (selectedArr && selectedArr.length > 0) {
                    selected = selectedArr.join(',');
                }
            }
        } catch (e) { }

        return selected;
    },
    setSelected: (tableRef = null) => {
        try {
            if (tableRef && tableRef.current) {
                tableRef.current.setSelected();
            }
        } catch (e) { }
    },

    getAllActiveInactiveText: (isIncludeInactive = null, isInactive = false, x) => {
        if (isIncludeInactive && isInactive == null) {
            return 'All';
        } else if (isIncludeInactive == null && !isInactive) {
            return 'Active';
        } else if (isIncludeInactive == null && isInactive) {
            return 'Inactive';
        } else {
            return 'All';
        }
    },
    getActiveDeletedText: (isInactive = false) => {
        if (isInactive == null || isInactive == false) {
            return 'Active';
        } else {
            return 'Deleted';
        }
    },

    getCurrentPreviousBoolValue: (currentPreviousVersion, field) => {
        return (currentPreviousVersion) ? (currentPreviousVersion[field] == true) ? 'Yes' : 'No' : 'No';
    },
    getPreviousValue: (previousVersion, field) => {
        return (previousVersion && previousVersion[field]) ? previousVersion[field] : null;
    },
    getCurrentValue: (currentVersion, field) => {
        return (currentVersion && currentVersion[field]) ? currentVersion[field] : null;
    },
    isDifferentValue: (prevValue, currValue) => {
        return (prevValue !== currValue) ? true : false;
    },
    getAuditTrailValue: (previousVersion, currentVersion, setRow, name, field, callback = null) => {
        if (typeof callback !== 'function') {
            let prevValue = Utils.getPreviousValue(previousVersion, field);
            let currValue = Utils.getCurrentValue(currentVersion, field);

            return setRow(name, prevValue, currValue, Utils.isDifferentValue(prevValue, currValue));
        } else {
            if (callback) {
                return callback();
            } else {
                return setRow(name, '-', '-', false);
            }
        }
    },
    getAuditTrailDiffArray: (previousVersion, currentVersion, setRow, name, arrField, arrId, field) => {
        let prevValue = [];
        let prevDefaultVehicles = Utils.getPreviousValue(previousVersion, arrField);
        let prevDefaultVehiclesArr = (prevDefaultVehicles && prevDefaultVehicles.length > 0) ? prevDefaultVehicles : [];
        if (prevDefaultVehiclesArr && prevDefaultVehiclesArr.length > 0) {
            prevValue = prevDefaultVehiclesArr.map((item, i) => {
                return <div key={i}>{item[field]}</div>
            });
        } else {
            prevValue = '-';
        }

        let currValue = [];
        let currDefaultVehicles = Utils.getCurrentValue(currentVersion, arrField);
        let currDefaultVehiclesArr = (currDefaultVehicles && currDefaultVehicles.length > 0) ? currDefaultVehicles : [];

        let diffObj = diff(prevDefaultVehiclesArr, currDefaultVehiclesArr, arrId);
        if (diffObj && diffObj.same && diffObj.same.length > 0) {
            for (let i = 0; i < diffObj.same.length; i++) {
                let fieldData = (diffObj.same[i] && diffObj.same[i][field]) ? diffObj.same[i][field] : '-';
                currValue.push(<div key={'same_' + i}>{fieldData}</div>);
            }
        }

        if (diffObj && diffObj.updated && diffObj.updated.length > 0) {
            for (let i = 0; i < diffObj.updated.length; i++) {
                let fieldData = (diffObj.updated[i] && diffObj.updated[i][field]) ? diffObj.updated[i][field] : '-';
                currValue.push(<div key={'updated_' + i} style={{ backgroundColor: '#fff2a8' }}>{fieldData}</div>);
            }
        }
        if (diffObj && diffObj.added && diffObj.added.length > 0) {
            for (let i = 0; i < diffObj.added.length; i++) {
                let fieldData = (diffObj.added[i] && diffObj.added[i][field]) ? diffObj.added[i][field] : '-';
                currValue.push(<div key={'added_' + i} style={{ backgroundColor: '#a8ffaf' }}>{fieldData}</div>);
            }
        }
        if (diffObj && diffObj.removed && diffObj.removed.length > 0) {
            for (let i = 0; i < diffObj.removed.length; i++) {
                let fieldData = (diffObj.removed[i] && diffObj.removed[i][field]) ? diffObj.removed[i][field] : '-';
                currValue.push(<div key={'removed_' + i} style={{ backgroundColor: '#ffa8a8' }}>{fieldData}</div>);
            }
        }

        if (!(currValue && currValue.length > 0)) {
            currValue = '-';
        }

        return setRow(name, prevValue, currValue, false);
    },


    getUserInfo: (user = '') => {
        let userObj = null;

        if (user && user !== '') {
            try {
                userObj = JSON.parse(user);
            } catch (e) { }
        }

        return userObj;
    },
    getUserInfoForBugsnagUser: (item = null) => {
        let obj = null;
        if (item) {
            let userId = (item && item.userId) ? item.userId : 0;
            let name = (item && item.name) ? item.name : '';
            let email = (item && item.email) ? item.email : '';

            obj = {
                id: userId,
                name: name,
                email: email
            };
        }

        return obj;
    },
    getUserInfoForBugsnagCompany: (item = null) => {
        let obj = null;
        if (item) {
            let userAccount = (item && item.userAccount) ? item.userAccount : null;
            if (userAccount) {
                let accountId = (userAccount && userAccount.accountId) ? userAccount.accountId : 0;
                let accountName = (userAccount && userAccount.accountName) ? userAccount.accountName : '';
                let accountEmail = (userAccount && userAccount.accountEmail) ? userAccount.accountEmail : '';

                obj = {
                    id: accountId,
                    name: accountName,
                    email: accountEmail
                };
            }
        }

        return obj;
    },
    getAuth: () => {
        let obj = null;

        let storage = localStorage.getItem("persist:auth");
        if (storage && storage !== '') {
            try {
                obj = JSON.parse(storage);
            } catch (e) { }
        }

        return obj;
    },
    getPermissions: () => {
        let permissions = [];

        let storage = localStorage.getItem("persist:auth");
        if (storage && storage !== '') {
            try {
                let obj = JSON.parse(storage);
                let user = (obj && obj.user) ? obj.user : null;
                let userObj = JSON.parse(user);
                permissions = (userObj && userObj.permissions && userObj.permissions.length > 0) ? userObj.permissions : [];
            } catch (e) { }
        }

        return permissions;
    },
    getCountryName: () => {
        let countryName = '';

        let storage = localStorage.getItem("persist:auth");
        if (storage && storage !== '') {
            try {
                let obj = JSON.parse(storage);
                let dispatchUser = (obj && obj.dispatchUser) ? obj.dispatchUser : null;
                let dispatchUserObj = JSON.parse(dispatchUser);
                let accountSettings = (dispatchUserObj && dispatchUserObj.accountSettings && dispatchUserObj.accountSettings.length > 0) ? dispatchUserObj.accountSettings : [];

                if (accountSettings && accountSettings.length > 0) {
                    let countryNameIndex = accountSettings.findIndex(x => x.type == AccountSettingType.DefaultMapPositionCountryName);
                    if (countryNameIndex > -1) {
                        countryName = accountSettings[countryNameIndex].value;
                    }
                }
            } catch (e) { }
        }

        return countryName;
    },
    getCountryCode: (cName = '') => {
        let allCities = City.getAllCities();
        let allUniqCities = _.uniqBy(allCities, (x) => x.name);
        let countryName = (cName && cName != '') ? cName : Utils.getCountryName();
        
        let countryCode = '';
        if ((countryName && countryName != '') && (allUniqCities && allUniqCities.length > 0)) {
            try {
                let item = allUniqCities.find(x => x.name === countryName);
                if(item){
                    countryCode = item.countryCode;
                }
            } catch (e) { }
        }

        return (countryCode && countryCode != '') ? countryCode : 'SG';
    },
    parsePhoneNumber: (value) => {
        try {
            let newValue = value;
            let countryCode = '+' + getCountryCallingCode(Utils.getCountryCode());
            if(value.trim() === countryCode.trim()){
                newValue = '';
            }
            return newValue;
        } catch (e) {
            return value;
        }
    },
    getCurrency: () => {
        let currencyCode = '';

        let storage = localStorage.getItem("persist:auth");
        if (storage && storage !== '') {
            try {
                let obj = JSON.parse(storage);
                let dispatchUser = (obj && obj.dispatchUser) ? obj.dispatchUser : null;
                let dispatchUserObj = JSON.parse(dispatchUser);
                let accountSettings = (dispatchUserObj && dispatchUserObj.accountSettings && dispatchUserObj.accountSettings.length > 0) ? dispatchUserObj.accountSettings : [];

                if (accountSettings && accountSettings.length > 0) {
                    let currencyCodeIndex = accountSettings.findIndex(x => x.type == AccountSettingType.CurrencyCode);
                    if (currencyCodeIndex > -1) {
                        currencyCode = accountSettings[currencyCodeIndex].value;
                    }
                }
            } catch (e) { }
        }

        return currencyCode;
    },
    getUOM: () => {
        let defaultUoM = 0;

        let storage = localStorage.getItem("persist:auth");
        if (storage && storage !== '') {
            try {
                let obj = JSON.parse(storage);
                let dispatchUser = (obj && obj.dispatchUser) ? obj.dispatchUser : null;
                let dispatchUserObj = JSON.parse(dispatchUser);
                defaultUoM = (dispatchUserObj) ? dispatchUserObj.defaultUoM : 0;
            } catch (e) { }
        }

        return defaultUoM;
    },
    getBillingUOM: () => {
        let defaultBillingUoM = 0;

        let storage = localStorage.getItem("persist:auth");
        if (storage && storage !== '') {
            try {
                let obj = JSON.parse(storage);
                let dispatchUser = (obj && obj.dispatchUser) ? obj.dispatchUser : null;
                let dispatchUserObj = JSON.parse(dispatchUser);
                defaultBillingUoM = (dispatchUserObj) ? dispatchUserObj.defaultBillingUoM : 0;
            } catch (e) { }
        }

        return defaultBillingUoM;
    },
    getPrice: (price = null) => {
        try {
            if (price) {
                return price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            } else {
                return price;
            }
        } catch (e) {
            return price;
        }
    },
    
    formatNumber: (numb = 0, format = '0,0.00') => {
        try {
            return numeral(numb).format(format);
        } catch (e) {
            return numb;
        }
    },

    hasDecimal: (num) => {
        try {
            const str = String(num);
            return str.includes('.') && str.split('.')[1].length > 0;
        } catch (e) {
            return false;
        }
    },

    kgToTones: (kg) => {
        try {
            return (kg / 1000).toFixed(3);
        } catch (e) {
            return kg;
        }
    },
    tonsToKg: (t) => {
        try {
            return (t * 1000).toFixed(0);
        } catch (e) {
            return t;
        }
    },
    smartConversionKgTons: (billableWeight) => {
        let uom = Utils.getUOM();
        let billingUom = Utils.getBillingUOM();

        try {
            if(billingUom === ReportUOM.kg){
                if(uom === ReportUOM.kg){
                    return billableWeight.toFixed(0);
                } else {
                    return Utils.tonsToKg(billableWeight);
                }
            } else {
                if(uom === ReportUOM.kg){
                    return Utils.kgToTones(billableWeight)
                } else {
                    return billableWeight.toFixed(3);
                }
            }
        } catch (e) {
            return billableWeight;
        }
    },

    convertUom: (billableWeight, from, to) => {
        try {
            if(billableWeight > 0){
                if(from == ReportUOM.kg && to == ReportUOM.tons){
                    return Utils.kgToTones(billableWeight);
                } else if (from == ReportUOM.tons && to == ReportUOM.kg){
                    return Utils.tonsToKg(billableWeight);
                } else if(from == ReportUOM.kg && to == ReportUOM.kg){
                    return billableWeight.toFixed(0);
                } else if(from == ReportUOM.tons && to == ReportUOM.tons){
                    return billableWeight.toFixed(3);
                } else {
                    return billableWeight;
                }
            } else {
                return billableWeight;
            }
        } catch (e) {
            return billableWeight;
        }
    },

    initDrawers: (callback = null, time = 1000) => {
        setTimeout(() => {
            if (callback) {
                callback();
            }
        }, time);
    },
    getInstanceDrawer: (selector = null, callbackShow = null, callbackHide = null) => {
        let name = (selector && selector !== '') ? selector : DrawerEnum.NAME;
        DrawerComponent.reinitialization();
        let drawer = DrawerComponent.getInstance(DrawerEnum.PREFIX + name);

        return drawer;
    },
    onShowDrawer: (drawer = null, drawerForm = null, callback = null) => {
        if (drawer) {
            drawer.on('kt.drawer.show', () => {
                Utils.validateForm(drawerForm);

                if (callback) {
                    callback();
                }
            });
        }
    },
    onHideDrawer: (drawer = null, drawerForm = null, callback = null) => {
        if (drawer) {
            drawer.on('kt.drawer.hide', () => {
                Utils.setDrawerData(drawer);
                Utils.resetForm(drawerForm);

                if (callback) {
                    callback();
                }
            });
        }
    },
    showDrawer: (drawer = null, state = true) => {
        if (drawer) {
            if (state) {
                drawer.show();
            } else {
                drawer.hide();
            }
        }
    },
    setDrawerData: (drawer = null, dataParams = null, obj = null) => {
        if (drawer) {
            drawer.element.setAttribute('data-dataparams', ((dataParams) ? JSON.stringify(dataParams) : null));
        }
    },
    getDrawerData: (drawer = null) => {
        if (drawer && drawer.element && drawer.element.dataset && drawer.element.dataset.dataparams) {
            return JSON.parse(drawer.element.dataset.dataparams);
        }
        return null;
    },
    resetForm: (drawerForm = null) => {
        if (drawerForm && drawerForm.current) {
            drawerForm.current.resetForm();
        }
    },
    validateForm: (drawerForm = null) => {
        if (drawerForm && drawerForm.current) {
            drawerForm.current.validateForm();
        }
    },
    isBlockingDrawer: (isLoading = false, isBlocking = false) => {
        return isLoading || isBlocking
    },
    shouldDrawerRenderChildren: (isLoading = false, isBlocking = false) => {
        // return isLoading ? true : isBlocking ? false : true;
        return true;
    },

    setBodyOverFlow: (overflow = 'initial', ms = 700) => {
        setTimeout(() => {
            document.body.style.overflow = 'initial';
        }, ms)
    },

    getIntProps: (props = null, field = 'id') => {
        let id = null;

        if (props == null || props[field] == null) {
            let q = queryString.parse(window.location.search);
            id = (q[field]) ? parseInt(q[field]) : 0;
        } else {
            id = (props[field]) ? props[field] : null;
        }

        return id;
    },
    getStringProps: (props = null, field = 'name') => {
        let name = '';

        if (props == null || props[field] == null) {
            let q = queryString.parse(window.location.search);
            name = (q[field]) ? decodeURIComponent(q[field]) : '';
        } else {
            name = (props[field]) ? decodeURIComponent(props[field]) : '';
        }

        return name;
    },
    getBoolProps: (props = null, field = 'isCopy') => {
        let state = '';
        let param = 'false';

        if (props == null || props[field] == null) {
            let q = queryString.parse(window.location.search);
            param = (q[field]) ? decodeURIComponent(q[field]) : 'false';
        } else {
            param = (props[field]) ? decodeURIComponent(props[field]) : 'false';
        }


        if (param.toLowerCase().trim() == 'true') {
            state = true;
        } else if (param.toLowerCase().trim() == 'false') {
            state = false;
        } else {
            state = false;
        }

        return state;
    },

    getDataArrayValues: (data = null, arrayName = null, field = null, separator = ', ', callback = null, defValue = '-') => {
        let val = defValue;

        let values = [];
        if (data && arrayName && field) {
            if (data[arrayName] && data[arrayName].length > 0) {
                data[arrayName].forEach((item, i) => {
                    if (item[field] && item[field] !== '') {
                        if (callback) {
                            values.push(callback(item[field]));
                        } else {
                            values.push(item[field]);
                        }
                    }
                });
            }
        }

        if (values && values.length > 0) {
            val = values.join(separator);
        }

        return val;
    },
    getDataValues: (data = null, fields = null, separator = ', ', callback = null, defValue = '-') => {
        let val = defValue;

        let values = [];
        if (data && fields && fields.length > 0) {
            fields.forEach((field, i) => {
                if (data[field] && data[field] !== '') {
                    if (callback) {
                        values.push(callback(data[field]));
                    } else {
                        values.push(data[field]);
                    }
                }
            });
        }

        if (values && values.length > 0) {
            val = values.join(separator);
        }

        return val;
    },
    getDataValue: (data: any = null, field: any = null, callback = null, defValue = '-') => {
        let val = defValue;

        if (data && field) {
            if (data[field] && data[field] !== '') {
                if (callback) {
                    val = callback(data[field]);
                } else {
                    val = data[field];
                }
            }
        }

        return val;
    },
    getDataIntValue: (data = null, field = null, callback = null, defValue = '-') => {
        let val = defValue;

        if (data && field) {
            if (data[field] && data[field] !== '' || data[field] == '0') {
                if (callback) {
                    val = callback(data[field]);
                } else {
                    val = data[field];
                }
            }
        }

        return val;
    },
    getDataBoolValue: (data = null, field = null, callback = null, defValue = '-') => {
        let val = defValue;

        if (data && field) {
            if (data[field] !== '') {
                if (callback) {
                    val = callback(data[field]);
                } else {
                    val = data[field];
                }
            }
        }

        return val;
    },
    getDataDetailsValue: (data = null, details = null, field = null, callback = null, defValue = '-') => {
        let val = defValue;

        if (data && details && field) {
            if (data[details] && data[details][field] && data[details][field] !== '') {
                if (callback) {
                    val = callback(data[details][field]);
                } else {
                    val = data[details][field];
                }
            }
        }

        return val;
    },

    scrollToEl: (selector: string = '', time: number = 100) => {
        setTimeout(() => {
          const element = document.querySelector(selector);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, time);
    },
    scrollTo(className = '', offset = -65, duration = 1500) {
        if (className && className !== '') {
            var elements = document.getElementsByClassName(className);
            if (elements && elements.length > 0) {
                scrollToElement(elements[0], {
                    offset: offset,
                    ease: 'out-expo',
                    duration: duration
                });
            }
        }
    },
    scrollToBottom(ref = null, ms = 200) {
        try {
            setTimeout(() => ref.current.scrollTop = ref.current.scrollHeight, ms);
        } catch (e) { }
    },
    scrollToBottomByClassName(className = '') {
        try {
            var els = document.getElementsByClassName(className);
            if (els && els.length > 0) {
                els[0].scrollTop = els[0].scrollHeight + 50;
            }
        } catch (e) { }
    },
    scrollToInvalidField() {
        setTimeout(() => {
            const invalidElement = document.querySelector('.is-invalid');
            if (invalidElement) {
            invalidElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    },

    generateFileName: (path = '', fileName = '') => {
        let pathArr = path.split('/');

        let generatedFileName = '';
        if (pathArr && pathArr.length > 0) {
            for (let i = 0; i < pathArr.length; i++) {
                generatedFileName += ((i === 0) ? '' : '-') + pathArr[i];
            }
        }

        generatedFileName += moment().format('YYYYMMDD');
        generatedFileName += Utils.rndChars(6);
        generatedFileName += '-' + fileName;

        return generatedFileName;
    },

    rndChars(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    },



    permissionsToPageTree(permissions = []) {
        let pages = [];


        let itemsDashboard = [
            PagesPermissions.Dashboard.read
        ];
        let childrenDashboard = [];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Dashboard', null, PagesPermissions.Dashboard.read, RolesPermissions.Dashboard, itemsDashboard, childrenDashboard, false);


        let itemsMap = [
            PagesPermissions.Map.read
        ];
        let childrenMap = [];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Map', null, PagesPermissions.Map.read, RolesPermissions.Map, itemsMap, childrenMap, false);


        let itemsCustomer = [
            PagesPermissions.Customers.read,
            PagesPermissions.CustomerSites.read,
            PagesPermissions.CustomerServices.read,
            PagesPermissions.CustomersOverview.read,
        ];
        let childrenCustomer = [
            {
                label: 'Customer table',
                value: RolesPermissions.Customers,
                id: PagesPermissions.Customers.read,
                items: [PagesPermissions.Customers.read],
                mainId: null,
            },
            {
                label: 'Overview',
                value: RolesPermissions.CustomersOverview,
                id: PagesPermissions.CustomersOverview.read,
                items: [PagesPermissions.CustomersOverview.read],
                mainId: null,
            },
            {
                label: 'Local service',
                value: RolesPermissions.CustomersServices,
                id: PagesPermissions.CustomerServices.read,
                items: [PagesPermissions.CustomerServices.read],
                mainId: null,
            },
            {
                label: 'Sites',
                value: RolesPermissions.CustomersSites,
                id: PagesPermissions.CustomerSites.read,
                items: [PagesPermissions.CustomerSites.read],
                mainId: null,
            }
        ];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Customers', null, PagesPermissions.Customers.read, RolesPermissions.Customer, itemsCustomer, childrenCustomer, false);


        let itemsJobs = [
            PagesPermissions.JobDetails.read,
            PagesPermissions.EditJobAfterCompleted.read,
            PagesPermissions.JobProofOfDelivery.read,
            PagesPermissions.JobAuditTrail.read,
            PagesPermissions.JobBilling.read,
        ];
        let childrenJobs = [
            {
                label: 'Job form',
                value: RolesPermissions.JobsJobForm,
                id: PagesPermissions.JobDetails.read,
                items: [PagesPermissions.JobDetails.read],
                mainId: PagesPermissions.Jobs.read,
            },
            {
                label: 'Edit Job After Completed',
                value: RolesPermissions.EditJobAfterCompleted,
                id: PagesPermissions.EditJobAfterCompleted.read,
                items: [PagesPermissions.EditJobAfterCompleted.read],
                mainId: PagesPermissions.Jobs.read,
            },
            {
                label: 'Proof of delivery',
                value: RolesPermissions.JobsProofOfDelivery,
                id: PagesPermissions.JobProofOfDelivery.read,
                items: [PagesPermissions.JobProofOfDelivery.read],
                mainId: PagesPermissions.Jobs.read,
            },
            {
                label: 'Audit trails',
                value: RolesPermissions.JobsAuditTrails,
                id: PagesPermissions.JobAuditTrail.read,
                items: [PagesPermissions.JobAuditTrail.read],
                mainId: PagesPermissions.Jobs.read,
            },
            {
                label: 'Job billing',
                value: RolesPermissions.JobsJobBilling,
                id: PagesPermissions.JobBilling.read,
                items: [PagesPermissions.JobBilling.read],
                mainId: PagesPermissions.Jobs.read,
            }
        ];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Jobs table', PagesPermissions.Jobs.read, PagesPermissions.Jobs.read, RolesPermissions.Jobs, itemsJobs, childrenJobs, false);


        let itemsBilling = [
            PagesPermissions.BillingInvoice.read,
            PagesPermissions.BillingPurchase.read,
            PagesPermissions.BillingExpense.read,
            PagesPermissions.BillingCategory.read,
        ];
        let childrenBilling = [
            {
                label: 'Billing Invoice',
                value: RolesPermissions.BillingInvoice,
                id: PagesPermissions.BillingInvoice.read,
                items: [PagesPermissions.BillingInvoice.read],
                mainId: null,
            },
            {
                label: 'Billing Purchase',
                value: RolesPermissions.BillingPurchase,
                id: PagesPermissions.BillingPurchase.read,
                items: [PagesPermissions.BillingPurchase.read],
                mainId: null,
            },
            {
                label: 'Billing Expense',
                value: RolesPermissions.BillingExpense,
                id: PagesPermissions.BillingExpense.read,
                items: [PagesPermissions.BillingExpense.read],
                mainId: null,
            },
            {
                label: 'Billing Category',
                value: RolesPermissions.BillingCategory,
                id: PagesPermissions.BillingCategory.read,
                items: [PagesPermissions.BillingCategory.read],
                mainId: null,
            }
        ];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Billing table', null, null, RolesPermissions.Billing, itemsBilling, childrenBilling, true);


        let itemsGlobalService = [PagesPermissions.GlobalService.read];
        let childrenGlobalService = [];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Global Service', null, PagesPermissions.GlobalService.read, RolesPermissions.GlobalService, itemsGlobalService, childrenGlobalService, false);


        let itemsManage = [
            PagesPermissions.ManageDrivers.read,
            PagesPermissions.ManageVehicles.read,
            PagesPermissions.ManageWaste.read,
            PagesPermissions.ManageBins.read,
            PagesPermissions.ManageJobtemplate.read,
            PagesPermissions.ManageService.read,
            PagesPermissions.ManageTime.read,
            PagesPermissions.ManagePayroll.read,
            PagesPermissions.ManageGeofence.read,
            PagesPermissions.ManageWorkHours.read,
            PagesPermissions.ManageUsers.read,
            PagesPermissions.ManageCompany.read,
            PagesPermissions.ManageGeneral.read,
            PagesPermissions.ManageSubscription.read,
            PagesPermissions.ManageHoliday.read,
        ];
        let childrenManage = [
            {
                label: 'Resources',
                value: RolesPermissions.ManageResources,
                id: null,
                items: [
                    PagesPermissions.ManageDrivers.read,
                    PagesPermissions.ManageVehicles.read,
                    PagesPermissions.ManageWaste.read,
                    PagesPermissions.ManageBins.read,
                ],
                mainId: PagesPermissions.Manage.read,
            },
            {
                label: 'Jobs',
                value: RolesPermissions.ManageJobs,
                id: null,
                items: [
                    PagesPermissions.ManageJobtemplate.read,
                    PagesPermissions.ManageService.read,
                    PagesPermissions.ManageTime.read,
                ],
                mainId: PagesPermissions.Manage.read,
            },
            {
                label: 'Payroll',
                value: RolesPermissions.ManagePayroll,
                id: null,
                items: [
                    PagesPermissions.ManagePayroll.read,
                    PagesPermissions.ManageWorkHours.read,
                ],
                mainId: PagesPermissions.Manage.read,
            },
            {
                label: 'Geofence',
                value: RolesPermissions.ManageGeofence,
                id: null,
                items: [
                    PagesPermissions.ManageGeofence.read,
                ],
                mainId: PagesPermissions.Manage.read,
            },
            {
                label: 'Users',
                value: RolesPermissions.ManageUsers,
                id: null,
                items: [
                    PagesPermissions.ManageUsers.read,
                ],
                mainId: PagesPermissions.Manage.read,
            },
            {
                label: 'Profile',
                value: RolesPermissions.ManageProfile,
                id: null,
                items: [
                    PagesPermissions.ManageCompany.read,
                ],
                mainId: PagesPermissions.Manage.read,
            },
            {
                label: 'Settings',
                value: RolesPermissions.ManageSettings,
                id: null,
                items: [
                    PagesPermissions.ManageGeneral.read,
                    PagesPermissions.ManageSubscription.read,
                    PagesPermissions.ManageHoliday.read,
                ],
                mainId: PagesPermissions.Manage.read,
            }
        ];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Manage', PagesPermissions.Manage.read, PagesPermissions.Manage.read, RolesPermissions.Manage, itemsManage, childrenManage, false);


        let itemsBinCenter = [
            PagesPermissions.BinCenter.read
        ];
        let childrenBinCenter = [];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Bin center', null, PagesPermissions.BinCenter.read, RolesPermissions.BinCenter, itemsBinCenter, childrenBinCenter, false);


        let itemsReports = [
            PagesPermissions.ReportsBins.read,
            PagesPermissions.ReportsBinsActivities.read,
            PagesPermissions.ReportsCollectionSummary.read,
            PagesPermissions.ReportsCollectionDetailed.read,
            PagesPermissions.ReportsPurchaseDetailed.read,
            PagesPermissions.ReportsDriverTripPay.read,
        ];
        let childrenReports = [
            {
                label: 'Bins on site',
                value: RolesPermissions.ReportsBinsOnSite,
                id: null,
                items: [
                    PagesPermissions.ReportsBins.read,
                ],
                mainId: PagesPermissions.Reports.read,
            },
            {
                label: 'Bins activities',
                value: RolesPermissions.ReportsBinsActivities,
                id: null,
                items: [
                    PagesPermissions.ReportsBinsActivities.read,
                ],
                mainId: PagesPermissions.Reports.read,
            },
            {
                label: 'Collection report (summary)',
                value: RolesPermissions.ReportsCollectionSummary,
                id: null,
                items: [
                    PagesPermissions.ReportsCollectionSummary.read,
                ],
                mainId: PagesPermissions.Reports.read,
            },
            {
                label: 'Collection report (detailed)',
                value: RolesPermissions.ReportsCollectionDetailed,
                id: null,
                items: [
                    PagesPermissions.ReportsCollectionDetailed.read,
                ],
                mainId: PagesPermissions.Reports.read,
            },
            {
                label: 'Purchase report (detailed)',
                value: RolesPermissions.ReportsPurchaseDetailed,
                id: null,
                items: [
                    PagesPermissions.ReportsPurchaseDetailed.read,
                ],
                mainId: PagesPermissions.Reports.read,
            },
            {
                label: 'Driver trip pay',
                value: RolesPermissions.ReportsDriverTripPay,
                id: null,
                items: [
                    PagesPermissions.ReportsDriverTripPay.read,
                ],
                mainId: PagesPermissions.Reports.read,
            }
        ];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Reports', PagesPermissions.Reports.read, PagesPermissions.Reports.read, RolesPermissions.Reports, itemsReports, childrenReports, false);


        let itemsAuditTrail = [PagesPermissions.AuditTrail.read];
        let childrenAuditTrail = [];
        pages = Utils.addPermissionsToPage(permissions, pages, 'Audit trail', null, PagesPermissions.AuditTrail.read, RolesPermissions.AuditTrail, itemsAuditTrail, childrenAuditTrail, false);


        return pages;
    },
    addPermissionsToPage(permissions = [], pages = [], label: '', mainId = null, id = null, value = null, items = [], childrens = [], shouldHideIfhasNoChildren = false) {
        let permissionItem = id ? Utils.findPermission(permissions, id) : true;
        if (permissionItem) {
            let mainItemItems = [];

            let mainItem = {
                label: label,
                value: value,
                main: mainId,
            };

            if (items && items.length > 0) {
                items.forEach((y, i) => {
                    let mpItem = Utils.findPermission(permissions, y);
                    if (mpItem) {
                        mainItemItems.push(y);
                    }
                });
            }

            if (mainItemItems && mainItemItems.length > 0) {
                mainItem['items'] = mainItemItems;
            }

            let children = [];
            if (childrens && childrens.length > 0) {
                childrens.forEach((x, i) => {
                    let cpItem = x.id ? Utils.findPermission(permissions, x.id) : true;
                    if (cpItem) {
                        let childItems = [];

                        let childItem = {
                            label: x.label,
                            value: x.value,
                            main: x.mainId,
                        };

                        if (x.items && x.items.length > 0) {
                            x.items.forEach((z, i) => {
                                let ccpItem = Utils.findPermission(permissions, z);
                                if (ccpItem) {
                                    childItems.push(z);
                                }
                            });
                        }

                        if (childItems && childItems.length > 0) {
                            childItem['items'] = childItems;
                            children.push(childItem);
                        }
                    }
                });
            }

            if (children && children.length > 0) {
                mainItem['children'] = children;
            }

            if (shouldHideIfhasNoChildren && (children.length == 0)) {
                return pages;
            }

            pages.push(mainItem);
        }

        return pages;
    },
    findPermission: (permissions = [], permissionId = 0) => {
        if (permissions && permissions.length > 0) {
            let permissionItem = permissions.filter(x => x.permissionId === permissionId);
            return (permissionItem && permissionItem.length > 0) ? permissionItem[0] : null;
        } else {
            return null;
        }
    },

    hasPermission: (permissions = [], permissionId = 0) => {
        if (permissionId !== null) {
            if (permissions && permissions.length > 0) {
                let permissionItem = permissions.filter(x => x.permissionId === permissionId);
                return (permissionItem && permissionItem.length > 0) ? true : false;
            } else {
                return false;
            }
        } else {
            return true;
        }
    },
    hasPagePermission: (path = '') => {
        let noPermission = '/error/no-permission';

        if (path === '/Dashboard') {
            return '/Map';
        }

        if (path === '/Map') {
            return '/Customers';
        }

        if (path === '/Customers') {
            return '/Jobs';
        }

        if (path === '/Jobs') {
            return '/billing/invoice-table/list-by-dates';
        }
        if (path === '/billing/invoice-table/list-by-dates') {
            return '/billing/purchase-table/list-by-dates';
        }
        if (path === '/billing/purchase-table/list-by-dates') {
            return '/manage/resource/drivers';
        }

        if (path === '/manage/resource/drivers') {
            return '/manage/resource/vehicles';
        }
        if (path === '/manage/resource/vehicles') {
            return '/manage/resource/waste-types';
        }
        if (path === '/manage/resource/waste-types') {
            return '/manage/resource/bin-types';
        }
        if (path === '/manage/resource/bin-types') {
            return '/manage/job/job-types';
        }

        if (path === '/manage/job/job-types') {
            return '/manage/job/standard-services';
        }
        if (path === '/manage/job/standard-services') {
            return '/manage/job/time';
        }
        if (path === '/manage/job/time') {
            return '/manage/payroll/payroll';
        }

        if (path === '/manage/payroll/payroll') {
            return '/manage/payroll/hours';
        }
        if (path === '/manage/payroll/hours') {
            return '/manage/user/users';
        }

        if (path === '/manage/user/users') {
            return '/manage/profile/company';
        }

        if (path === '/manage/profile/company') {
            return '/manage/geofence/general';
        }
        if (path === '/manage/setting/general') {
            return '/manage/setting/subscription';
        }

        if (path === '/manage/setting/subscription') {
            return '/bin-center';
        }

        if (path === '/bin-center') {
            return '/Reports';
        }

        return noPermission;
    },
    hasManageResourcesPermission: (permissions = []) => {
        let noPermission = '/manage/job/job-types';

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageDrivers.read) !== -1) {
                return '/manage/resource/drivers';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageVehicles.read) !== -1) {
                return '/manage/resource/vehicles';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageWaste.read) !== -1) {
                return '/manage/resource/waste-types';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageBins.read) !== -1) {
                return '/manage/resource/bin-types';
            }
        }

        return noPermission;
    },
    hasManageJobsPermission: (permissions = []) => {
        let noPermission = '/manage/payroll/payroll';

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageJobtemplate.read) !== -1) {
                return '/manage/job/job-types';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageService.read) !== -1) {
                return '/manage/job/standard-services';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageTime.read) !== -1) {
                return '/manage/job/time';
            }
        }

        return noPermission;
    },
    hasManagePayrollsPermission: (permissions = []) => {
        let noPermission = '/manage/user/users';

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManagePayroll.read) !== -1) {
                return '/manage/payroll/payroll';
            }
        }

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageWorkHours.read) !== -1) {
                return '/manage/payroll/hours';
            }
        }

        return noPermission;
    },
    hasManageUsersPermission: (permissions = []) => {
        let noPermission = '/manage/profile/company';

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageUsers.read) !== -1) {
                return '/manage/user/users';
            }
        }

        return noPermission;
    },
    hasManageProfilesPermission: (permissions = []) => {
        let noPermission = '/manage/setting/general';

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageCompany.read) !== -1) {
                return '/manage/profile/company';
            }
        }

        return noPermission;
    },
    hasManageSettingsPermission: (permissions = []) => {
        let noPermission = '/Reports';

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageGeneral.read) !== -1) {
                return '/manage/setting/general';
            }
        }

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ManageSubscription.read) !== -1) {
                return '/manage/setting/subscription';
            }
        }

        // if(permissions && permissions.length > 0){
        //     if(permissions.findIndex(x => x.permissionId === PagesPermissions.ManageHoliday.read) !== -1){
        //         return '/manage/setting/holiday';
        //     }
        // }

        return noPermission;
    },
    hasReportsPermission: (permissions = []) => {
        let noPermission = '/no-permission';

        if (permissions && permissions.length > 0) {
            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsBins.read) !== -1) {
                return '/Reports-bins-on-site';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsBinsActivities.read) !== -1) {
                return '/Reports-bin-activities';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsCollectionSummary.read) !== -1) {
                return '/Reports-waste-collections-summary';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsCollectionDetailed.read) !== -1) {
                return '/Reports-waste-collections-detailed';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsPurchaseDetailed.read) !== -1) {
                return '/Reports-waste-purchase-detailed';
            }

            if (permissions.findIndex(x => x.permissionId === PagesPermissions.ReportsDriverTripPay.read) !== -1) {
                return '/Reports-driver-trip-pay';
            }
        }

        return noPermission;
    },

    hasBillingPermission(permissions = []) {
        let state = true;

        if (permissions && permissions.length > 0) {
            let billingInv = permissions.findIndex(x => x.permissionId === PagesPermissions.BillingInvoice.read);
            let billingPurch = permissions.findIndex(x => x.permissionId === PagesPermissions.BillingPurchase.read);
            let billingExp = permissions.findIndex(x => x.permissionId === PagesPermissions.BillingExpense.read);
            let billingCat = permissions.findIndex(x => x.permissionId === PagesPermissions.BillingCategory.read);

            if ((billingInv == -1) && (billingPurch == -1) && (billingExp == -1) && (billingCat == -1)) {
                state = false;
            }
        }

        return state;
    },
    hasBillingCategoryPermission(permissions = []) {
        let state = true;

        if (permissions && permissions.length > 0) {
            let billingCat = permissions.findIndex(x => x.permissionId === PagesPermissions.BillingCategory.read);

            if (billingCat == -1) {
                state = false;
            }
        }

        return state;
    },


    toast: (text = '', type = '', icon = null, autoClose = 3000, position = 'top-center') => {
        let options = {
            position: position,
            autoClose: autoClose,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            pauseOnFocusLoss: false,
        };

        let layout = null;
        if (typeof text !== 'object') {
            layout = <div>
                {icon && <i className={'material-icons'}>{icon}</i>}
                <span>{text}</span>
            </div>;
        } else {
            layout = text;
        }

        if (type !== '') {
            toast[type](layout, options);
        } else {
            toast(layout, options);
        }
    },


    setFavNumb: (x) => {
        Tinycon.setBubble(x);

        Tinycon.setOptions({
            color: 'white',
            background: '#f50057',
            fallback: true
        });
    },


    getJsonFromString: (data = null) => {
        let item = [];

        if (data && data !== '') {
            try {
                item = JSON.parse(data);
            } catch (e) { }
        }

        return item;
    },

    disableEnterKey: (keyEvent = null) => {
        try {
            if (keyEvent && ((keyEvent.charCode || keyEvent.keyCode) === 13)) {
                keyEvent.preventDefault();
            }
        } catch (e) { }
    },


    setTemplateFunctionsToColumns: (initialColumnsX = [], columnsFromCookieX = [], field = 'id') => {
        let initialColumns = Array.from(initialColumnsX);
        let columnsFromCookie = Array.from(columnsFromCookieX);

        let newColomuns = [];
        if (columnsFromCookie && columnsFromCookie.length > 0) {
            for (let i = 0; i < columnsFromCookie.length; i++) {
                let item = columnsFromCookie[i];

                let colItem = initialColumns.filter(x => x[field] === item[field]);
                let index = initialColumns.findIndex(x => x[field] === item[field]);
                if (colItem && colItem.length > 0) {
                    if (colItem[0].template) {
                        item.template = colItem[0].template;
                    }
                    if (colItem[0].exportTemplate) {
                        item.exportTemplate = colItem[0].exportTemplate;
                    }
                    if (colItem[0].exportCSVTemplate) {
                        item.exportCSVTemplate = colItem[0].exportCSVTemplate;
                    }
                    if (colItem[0].detailsTemplate) {
                        item.detailsTemplate = colItem[0].detailsTemplate;
                    }
                }

                newColomuns.push(columnsFromCookie[i]);

                if (index > -1) {
                    initialColumns.splice(index, 1);
                }
            }
        }

        return newColomuns;
    },

    getUserCookieSettingsIndex: (settings = null, settingsType = null, pageName = null) => {
        let index = -1;

        if ((settings && settings.length > 0) && (settingsType !== null)) {
            if (pageName !== null) {
                index = settings.findIndex(x => (x.type === settingsType) && (x.name === pageName));
            } else {
                index = settings.findIndex(x => x.type === settingsType);
            }
        }

        return index;
    },
    getUserCookieSettings: (settings = null, settingsType = null, pageName = null) => {
        let item = null;

        if ((settings && settings.length > 0) && (settingsType !== null)) {
            let index = Utils.getUserCookieSettingsIndex(settings, settingsType, pageName);
            if (index !== -1 && settings[index] && settings[index].value && settings[index].value !== '') {
                item = Utils.getJsonFromString(settings[index].value);
            }
        }

        return item;
    },
    resetUserCookieSettingsByDate: (settings = []) => {
        if (settings && settings.length > 0) {
            for (let i = 0; i < settings.length; i++) {
                let createdDate = (settings[i].created && settings[i].created !== '') ? moment.utc(settings[i].created).startOf('day') : null;
                let updatedDate = (settings[i].updated && settings[i].updated !== '') ? moment.utc(settings[i].updated).startOf('day') : null;
                // let nowDate = moment().utc().startOf('day');
                let nowDate = moment.utc(Utils.getUtcDateFromSettings()).startOf('day');

                let isToday = false;
                if (createdDate) {
                    if (createdDate.isSame(nowDate)) {
                        isToday = true;
                    }
                }

                if (updatedDate) {
                    if (updatedDate.isSame(nowDate)) {
                        isToday = true;
                    }
                }

                let item = Utils.getJsonFromString(settings[i].value);

                if (!isToday) {
                    let newValue = Object.assign({}, item);
                    delete newValue[PageCookieSettingType.fromDate];
                    delete newValue[PageCookieSettingType.toDate];
                    delete newValue[PageCookieSettingType.dateRange];
                    delete newValue[PageCookieSettingType.selectedDateFilterIndex];
                    delete newValue[PageCookieSettingType.search];
                    settings[i].value = JSON.stringify(newValue);
                }
            }
        }

        return settings;
    },
    saveUserCookieSettings: (row, token, callback: any = null) => {
        userCookieSettingApi.create(row, token).then((result) => {
            Utils.parseResult(result,
                (data) => {
                    if (callback) {
                        callback(data);
                    }
                },
                (error, type) => {
                    if (callback) {
                        callback(null);
                    }
                }
            );
        });
    },


    getAccountSettings: () => {
        let accountSettings = [];

        let storage = localStorage.getItem("persist:auth");
        if (storage && storage !== '') {
            try {
                let obj = JSON.parse(storage);
                let dispatchUser = (obj && obj.dispatchUser) ? obj.dispatchUser : null;
                let settings = JSON.parse(dispatchUser);
                accountSettings = (settings && settings.accountSettings && settings.accountSettings.length > 0) ? settings.accountSettings : [];
            } catch (e) { }
        }

        return accountSettings;
    },


    getUserTimezoneUtcOffset: () => {
        let timezoneUtcOffset = null;

        let storage = localStorage.getItem("persist:auth");
        if (storage && storage !== '') {
            try {
                let obj = JSON.parse(storage);
                let dispatchUser = (obj && obj.dispatchUser) ? obj.dispatchUser : null;
                let dispatchUserObj = JSON.parse(dispatchUser);
                timezoneUtcOffset = (dispatchUserObj && dispatchUserObj.timezoneUtcOffset) ? dispatchUserObj.timezoneUtcOffset : null;
            } catch (e) { }
        }

        return timezoneUtcOffset;
    },
    getUtcDateFromSettings: () => {
        let timezoneUtcOffset = Utils.getUserTimezoneUtcOffset();
        if(timezoneUtcOffset){
            return moment.utc().add(timezoneUtcOffset, "minutes").format();
        } else {
            return moment().add(0, "minutes").format();
        }
    },
    getTZDateFromSettings: () => {
        let timezoneUtcOffset = Utils.getUserTimezoneUtcOffset();
        let newDate = Utils.convertDateToUTC(new Date())
        if(timezoneUtcOffset){
            return moment(newDate).add(timezoneUtcOffset, "minutes");
        } else {
            return moment(newDate).add(0, "minutes");
        }
    },
    setTZDateFromSettings: (date) => {
        let timezoneUtcOffset = Utils.getUserTimezoneUtcOffset();
        if(timezoneUtcOffset, date){
            return moment(date).utc().add(timezoneUtcOffset, "minutes");
        } else {
            return date;
        }
    },
    
    convertDateToUTC: (date) => { 
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
    },


    getReduceValue(obj, path) {
        return path.reduce((prev, curr) => {
            return prev ? prev[curr] : undefined;
        }, obj);
    },
    getDifferences(currentData, previousData, id) {
        const fata = [];

        const result = diff(previousData, currentData, id, { updatedValues: diff.updatedValues.both });

        result.removed.forEach(item => {
            fata.push({
                ...item,
                _color: '#FFE7D1'
            });
        });

        result.added.forEach(item => {
            fata.push({
                ...item,
                _color: '#E0FFCC',
                _fontWeight: 'normal',
            });
        });

        result.updated.forEach(item => {
            item.forEach((item, i) => {
                if (i == 0) {
                    fata.push({
                        ...item,
                        _color: '#FEFFD6',
                        _fontWeight: 'normal',

                    });
                } else {
                    fata.push({
                        ...item,
                        _color: '#FEFFD6',
                        _fontWeight: 'bold',
                    });
                }
            });
        });

        result.same.forEach(item => {
            fata.push({
                ...item,
                _color: 'transparent',
                _fontWeight: 'normal',
            });
        });

        return fata
    },
    printTable(data, fields) {
        const ReportTableStyle = styled(ReportTable)`
            width: 100%;

            thead {
                tr {
                    border-bottom: 1px solid #DFDFDF;

                    th {
                        color: #7A7A7A;
                        padding-bottom: 5px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        color: #7A7A7A;
                        padding-top: 3px;
                        padding-bottom: 3px;
                    }

                    &:hover {
                        td {
                            background-color: #EFF6E4;
                        }
                    }
                }
            }
        `;

        return <ReportTableStyle className={'no-responsive-table'}>
            <ReportThead>
                <ReportTr>
                    {fields.map((head, h) => {
                        return <ReportTh key={'head_' + h}>{(typeof head.title === 'function') ? head.title(head, h) : head.title}</ReportTh>
                    })}
                </ReportTr>
            </ReportThead>
            <ReportTbody>
                {data.map((row, r) => {
                    return <ReportTr key={'row_' + r}
                        style={{ backgroundColor: row._color, fontWeight: row._fontWeight }}
                    >
                        {fields.map((col, c) => {
                            let value = Utils.getReduceValue(row, col.value);
                            return <ReportTd key={'row_' + r + '_col_' + c}>{(col.format) ? (typeof col.format === 'function') ? col.format({ row: row, col: col, value: value, rowCount: r, colCount: c }) : col.format : value}</ReportTd>
                        })}
                    </ReportTr>
                })}
            </ReportTbody>
        </ReportTableStyle>
    },

    compareArrays(array1, array2) {
        try {
            return array1.every(i => array2.includes(i));
        } catch (err) {
            return false;
        }
    },
    isInArray(array, value) {
        return array.indexOf(value) > -1;
    },

    unshiftToArr(arr = [], value) {
        let index = arr.findIndex((x) => x === value);

        if (index === -1) {
            arr.unshift(value);
        } else {
            arr.splice(index, 1);
            arr.unshift(value);
        }

        return arr;
    },
    addToArr(arr = [], value) {
        let index = arr.findIndex((x) => x === value);

        if (index === -1) {
            arr.push(value);
        } else {
            arr.splice(index, 1);
            arr.push(value);
        }

        return arr;
    },
    removeFromArr(array, value) {
        if (array && array.length > 0) {
            let index = array.indexOf(value);
            if (index > -1) {
                array.splice(index, 1);
            }
        }
        return array;
    },

    removeFromArray(array, value) {
        let index = array.indexOf(value);
        if (index > -1) {
            array.splice(index, 1);
        }
        return array;
    },

    removeFromArrayWithField(arr = [], value, field, item = null) {
        let index = arr.findIndex(x => x[field] == value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    },

    unshiftToArrayWithField(arr = [], value, field, item = null) {
        let index = arr.findIndex((x) => x[field] == value);

        if (index === -1) {
            arr.unshift(item);
        } else {
            arr.splice(index, 1);
            arr.unshift(item);
        }

        return arr;
    },
    addToArrayWithField(arr = [], value, field, item = null) {
        let index = arr.findIndex((x) => x[field] == value);

        if (index === -1) {
            arr.push(item);
        } else {
            arr.splice(index, 1);
            arr.push(item);
        }

        return arr;
    },

    unshiftToArray(arr = [], value, item = null) {
        let index = arr.findIndex((x) => x.value == value);

        if (index === -1) {
            arr.unshift(item);
        } else {
            arr.splice(index, 1);
            arr.unshift(item);
        }

        return arr;
    },
    addToArray(arr = [], value, item = null) {
        let index = arr.findIndex((x) => x.value == value);

        if (index === -1) {
            arr.push(item);
        } else {
            arr.splice(index, 1);
            arr.push(item);
        }

        return arr;
    },
    addToArrayByTitle(arr = [], title, item = null) {
        let index = arr.findIndex((x) => x.title && x.title.toString().trim() == title.toString().trim());

        if (index === -1) {
            arr.push(item);
        }

        return arr.filter(x => x.title && x.title != '');
    },
    addToArrayIfNotExist(arr = [], value, item = null) {
        let index = arr.findIndex((x) => x.value == value);

        if (index === -1) {
            arr.push(item);
        }

        return arr;
    },
    addToArrayWithTwoValues(arr = [], field1, value1, field2, value2, item = null) {
        let index = arr.findIndex((x) => ((x[field1] == value1) && (x[field2] == value2)));

        if (index === -1) {
            arr.push(item);
        } else {
            arr.splice(index, 1);
            arr.push(item);
        }

        return arr;
    },

    getMinValue(arr = [], field = 'value') {
        if (arr && arr.length > 0) {
            let newArr = arr.map(x => x[field]);
            return Math.min(...newArr)
        } else {
            return 0;
        }
    },
    getNextMinValue(arr = [], field = 'value') {
        let min = Utils.getMinValue(arr, field);
        return (min - 1);
    },


    getIndexOfArray(array, param, value) {
        if (array && array.length > 0) {
            let index = 0;

            for (var i = 0; i < array.length; i++) {
                if (array[i][param] === value) {
                    index = i;
                    break;
                }
            }

            return index;
        } else {
            return null;
        }
    },

    createEditorState(html) {
        if (html && html !== '') {
            const contentBlock = htmlToDraft(html);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return editorState;
        } else {
            return EditorState.createEmpty();
        }
    },


    escapeChar(e = null, char = null) {
        if (e && char) {
            if (char === e.key) {
                e.preventDefault();
                e.stopPropagation();
                return true;
            }
        }

        return false;
    },
    escapeChars(e = null) {
        if (e) {
            if (CHARACTERS.includes(e.key)) {
                return Utils.escapeChar(e, e.key);
            }
        }

        return false;
    },
    escapeCharsInString(str = '') {
        if (str && str != '') {
            CHARACTERS.forEach(x => {
                str = str.replaceAll(x, '');
            })
        }
        return str;
    },
    escapeCharsInStringOnPaste(str = '') {
        let cleanedText = str;
        CHARACTERS.forEach((character) => {
            cleanedText = cleanedText.split(character).join('');
        });
        return cleanedText;
    },


    limitRowsBillingAddress: (value, rows = 5, callback: any = null) => {
        try {
            if(rows > 0){
                const lines = value.split('\n');
                if (lines.length <= rows) {
                    if(callback){
                        callback(value);
                    }

                } else if(lines.length > rows){
                    let newRows = [];
                    let newExtraRows = [];

                    lines.forEach((row: any, i: number) => {
                        if(i < rows){
                            newRows.push(row);
                        } else {
                            newExtraRows.push(row);
                        }
                    });

                    let newValue = (newRows && newRows.length > 0) ? newRows.join('\n') : '';
                    let newExtraValue = (newExtraRows && newExtraRows.length > 0) ? newExtraRows.join(' ') : '';
                    let result = newValue + ((newExtraValue && newExtraValue != '') ? ' ' + newExtraValue : '');

                    if(callback){
                        callback(result);
                    }
                }
            } else {
                if(callback){
                    callback(value);
                }
            }
        } catch(e){}

        
        // This is 29 char limit per each row
        // try {
        //     if(rows > 0){
        //         let result = '';
        //         const lines = value.split('\n');
        //         if (lines.length <= rows) {
        //             result = value;

        //         } else if(lines.length > rows){
        //             let newRows = [];
        //             let newExtraRows = [];

        //             lines.forEach((row: any, i: number) => {
        //                 if(i < rows){
        //                     newRows.push(row);
        //                 } else {
        //                     newExtraRows.push(row);
        //                 }
        //             });

        //             let newValue = (newRows && newRows.length > 0) ? newRows.join('\n') : '';
        //             let newExtraValue = (newExtraRows && newExtraRows.length > 0) ? newExtraRows.join(' ') : '';
        //             result = newValue + ((newExtraValue && newExtraValue != '') ? ' ' + newExtraValue : '');
        //         }


        //         const maxLength = 29;
        //         const arr = [];
                
        //         const newLines = result.split('\n');
        //         if(newLines && newLines.length > 0){
        //             for (let i = 0; i < newLines.length; i++) {
        //                 let result = newLines[i].substring(0, maxLength);
        //                 arr.push(result);
        //             }
        //         }
                
        //         let newResult = (arr && arr.length > 0) ? arr.join('\n') : '';

        //         if(callback){
        //             callback(newResult);
        //         }
        //     } else {
        //         if(callback){
        //             callback(value);
        //         }
        //     }
        // } catch(e){}
    },
    limitRows: (value, rows = 5, callback: any = null) => {
        try {
            if(rows > 0){
                const lines = value.split('\n');
                if (lines.length <= rows) {
                    if(callback){
                        callback(value);
                    }

                } else if(lines.length > rows){
                    let newRows = [];
                    let newExtraRows = [];

                    lines.forEach((row: any, i: number) => {
                        if(i < rows){
                            newRows.push(row);
                        } else {
                            newExtraRows.push(row);
                        }
                    });

                    let newValue = (newRows && newRows.length > 0) ? newRows.join('\n') : '';
                    let newExtraValue = (newExtraRows && newExtraRows.length > 0) ? newExtraRows.join(' ') : '';
                    let result = newValue + ((newExtraValue && newExtraValue != '') ? ' ' + newExtraValue : '');

                    if(callback){
                        callback(result);
                    }
                }
            } else {
                if(callback){
                    callback(value);
                }
            }
        } catch(e){}
    },
    placeholderRows: (rows = 5, firstLineText = '', lineText = '') => {
        let newRows = [];
        for(let i = 0; i < rows; i++){
            if(firstLineText != ''){
                if(i === 0){
                    newRows.push(firstLineText);
                } else {
                    if(lineText != ''){
                        newRows.push(lineText + i);
                    }
                }
            } else {
                if(lineText != ''){
                    newRows.push(lineText + (i+1));
                }
            }
        }
        return (newRows && newRows.length > 0) ? newRows.join('\n') : '';
    },

    stringToHtmlTrim: (value) => {
        try {
            let result = value && value !== "" ? value.toString().replace(/[ \t]+/g, ' ').trim() : value;
            return parse(result)
        } catch(e){
            return value
        }
    },
    
    parseFloat(x) {
        if (x === 0 || x === "0" || x === "0.00") {
            return 0;
        } else {
            return isNaN(parseFloat(x)) ? parseFloat(0) : parseFloat(x);
        }
    },
    parseFloatToFIxed(x) {
        if (x === undefined || x === null || x === 0 || x === "0" || x === "0.00") {
            return 0.00;
        } else {
            return isNaN(parseFloat(x)) ? 0.00 : parseFloat(x);
        }
    },
    customParseInt(x) {
        let numb = 0;

        try {
            numb = isNaN(parseInt(x)) ? 0 : parseInt(x);
        } catch (e) { }

        return numb;
    },
    isNumber(x) {
        return /^\d+(\.\d+)?$/.test(x);
    },
    isNumberAvoidZero(x) {
        return /^(\d+(\.\d*)?|\.\d+)$/.test(x) && parseFloat(x) !== 0;
    },
    isNumeric(value){
        return !isNaN(parseFloat(value)) && isFinite(value)
    },


    isSmallScreen(width = 992) {
        return (window.innerWidth <= width) ? true : false;
    },


    getJoinSeparator: (separator = null) => {
        return separator ? separator : ';';
    },
    getSelectedColumns: (columns, searchColumnKey = 'id', advancedColumnKey = 'id') => {
        let items = [];

        if (columns && columns.length > 0) {
            for (let i = 0; i < columns.length; i++) {
                if (searchColumnKey === advancedColumnKey) {
                    if (columns[i][searchColumnKey]) {
                        items.push(columns[i][searchColumnKey]);
                    } else {
                        items.push(columns[i]['id']);
                    }
                } else {
                    if (columns[i][advancedColumnKey]) {
                        items.push(columns[i][searchColumnKey]);
                    } else {
                        items.push(columns[i]['id']);
                    }
                }
            }
        }

        return items.join(Utils.getJoinSeparator());
    },
    getSelectedOperator: (operators, defaultOperator = 'or') => {
        return operators && operators.length > 0 ? operators[0].operator : defaultOperator;
    },
    joinItems: (items) => {
        return (items && items.length > 0) ? items.join(Utils.getJoinSeparator()) : '';
    },
    filterParams: (items) => {
        let filterParams = [];

        if (items && items.length > 0) {
            items.forEach((item, i) => {
                if (item && (item.column && item.column != '') && (item.tags && item.tags.length > 0)) {
                    filterParams.push({
                        column: item.column,
                        value: item.tags.join(Utils.getJoinSeparator(',')),
                    })
                }
            });
        }

        return filterParams;
    },






    passwordValidation(str, regex = null) {
        if (regex === null) {
            // Minimum eight characters, at least one letter, one number and one special character
            return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/g.test(str);
        } else {
            return regex.test(str);
        }
    },
    passwordValidationSimple(str) {
        // The password must contain at least six letters or numbers
        return /[A-Za-z\d@$!%*#?&]{6,}/g.test(str);
    },

    emailValidation() {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },

    timeRegex() {
        return "(?:^(00:|0[0-9]:)|^(1[012]:|1:|[1-9]:))([0-5][0-9]) ((a|p)m|(A|P)M)$"
    },

    numberValidation() {
        return /^\d{0,}$/g;
    },

    character50Value() {
        return /^[\d\w\W]{0,50}$/g;
    },

    numberValidation10() {
        return /^\d{0,10}$/g;
    },
    decimalValidation() {
        // return /^(?:[-.]?)?(?:\d{1,2})?(?:[.,]\d{1,3})?$/gm; // Validate positive and negative two-digit numbers with 3 decimal places
        return /^(?:\d{1,5})?(?:[.,]\d{1,3})?$/g; // Validate only positive two-digit numbers with 3 decimal places
    },
    decimalValidationInputText() {
        return /^(\d{0,5})?(?:[.,]+\d{0,3})?$/g; // Validate only positive two-digit numbers with 3 decimal places and dot
    },
    decimalValidation150() {
        return /^\d(^[0-9]?(?:[.,]+\d{0,1})?$|^10$)|(^[0-9][0-9]?(?:[.,]+\d{0,1})?$|^100$)|(^[0-9][0-4][0-9]?(?:[.,]+\d{0,1})?$|^150$)+?$/gm; // Validate payroll slider range 1-150 with decimal
    },
    decimalValidationMustHaveDot() {
        return /^(\d{0,5})?(?:[.,]+\d{1,1})?$/g; // Validate only positive two-digit numbers with 1 decimal places and dot
    },
    character3Validation() {
        return /^[\d\w\W]{0,3}$/g;
    },
    decimal3Validation() {
        // return /^\d+(?:[.,]\d{1,3})?$/g;
        return /^(?:\d{1,2}||^$)+(?:[.,]\d{0,3})?$/g;
    },
    character6Validation() {
        return /^[\d\w\W^-]{0,6}$/g;
    },
    character6AndDashAtTheEndValidation() {
        // return /^(^[a-z0-9A-Z]{0,6}$)|((?!^-)(^[a-z0-9A-Z]{0,5})(-$))$/g;
        return /^[a-z0-9A-Z-]{0,6}$/g;
    },
    removeDashAtTheEndOfString() {
        return /[-]+$/g;
    },
    maxInputNumber012() {
        return /^[012]$/g;
    },
    maxInputNumber12() {
        return /^[12]$/g;
    },
    max24With1Decimal() {
        return /^(?:[1-9]|1[0-9]|2[0-3])(?:\.5)?$/;
    },
    minNumber2() {
        return /^(?!.*\b1\b)[2-9]|[1-9][0-9]+$/;
    },
    onlyPositiveNumbers() {
        return /^(0|[1-9]\d*)$/;
    },
    onlyPositiveAndEmptyNumbers() {
        return /^[0-9]*$/;
    },
    onlyPositiveAndEmptyNumbers100() {
        return /^[0-9]{0,3}$/;
    },
    max7chars() {
        return /^(?:.{0,7})$/;
    },
    number2Decimal() {
        return /^$|^(\d+(\.\d{0,2})?)?$/;
    },

    validateLatLng(lat, lng) {
        let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');

        return pattern.test(lat) && pattern.test(lng);
    },
    validateCoordinates(coordinates) {
        try {
            let args = coordinates.split(',');

            let latitude = (args && args.length > 0) ? (/^-?\d*\.?\d*$/.test(args[0].trim())) ? parseFloat(args[0].trim()) : '' : '';
            let longitude = (args && args.length > 1) ? (/^-?\d*\.?\d*$/.test(args[1].trim())) ? parseFloat(args[1].trim()) : '' : '';
            // return isValidCoordinates(latitude, longitude);
            return (isValidCoordinates.latitude(latitude) && isValidCoordinates.longitude(longitude))
        } catch (e) {
            return false;
        }
    },
    parseCoordinates(coordinates, defaultCoordinates = null) {
        return Utils.validateCoordinates(coordinates) ? Utils.getCoordinates(coordinates.split(','), defaultCoordinates) : defaultCoordinates;
    },
    getCoordinates(coordinates = [], defaultCoordinates = null) {
        let coord = null;

        if (coordinates && coordinates.length == 2) {
            coord = { lat: parseFloat(coordinates[0].trim()), lng: parseFloat(coordinates[1].trim()) }
        }

        return (coord) ? coord : defaultCoordinates;
    },
    joinCoordinates(lat, lng, separator = ', ') {
        let coord = [];

        if (lat != "") {
            coord.push(lat);
        }

        if (lng != "") {
            coord.push(lng);
        }

        return (coord.length > 0) ? coord.join(separator) : '';
    },

    dinamicZoom(map, lat, lng, extendBy = 0.0010) {
        try {
            let bounds = new window.google.maps.LatLngBounds();
            let centerCoordinate = new window.google.maps.LatLng(lat, lng);
            bounds.extend(centerCoordinate);
            
            bounds.extend(new window.google.maps.LatLng(centerCoordinate.lat() + extendBy, centerCoordinate.lng() + extendBy));
            bounds.extend(new window.google.maps.LatLng(centerCoordinate.lat() - extendBy, centerCoordinate.lng() - extendBy));
            
            map.fitBounds(bounds);
        } catch(e){}
    },


    bytesToSizeWithDecimal(bytes) {
        let units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        let l = 0, n = parseInt(bytes, 10) || 0;

        while (n >= 1024 && ++l) {
            n = n / 1024;
        }

        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    },
    bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    mToKm(m) {
        var km = m / 1000;
        return km.toFixed(1) + " km"
    },



    getPaginationCount(totalRows, rowsPerPageCount) {
        let mod = totalRows % rowsPerPageCount;
        let pages = totalRows / rowsPerPageCount;
        return (mod > 0) ? (parseInt(pages) + 1) : pages;
    },
    getPaginationFromTo(totalRows, rowsPerPageCount, page) {
        let from = ((page * rowsPerPageCount) - rowsPerPageCount + 1);
        let to = ((page * rowsPerPageCount) >= totalRows ? totalRows : (page * rowsPerPageCount));
        return {
            from: from,
            to: to,
        }
    },

    getMaxPageSize: () => {
        return 99999;
    },
    getDefaultPageSize: (x = 10) => {
        return x;
    },

    getMinDate: () => {
        return '0001-01-01T00:00:00';
    },
    getMaxDate: () => {
        return '9999-12-31T23:59:59';
    },

    isValidDate: (value) => {
        const parsedDate = Date.parse(value);
        return !isNaN(parsedDate);
    },


    removeHeaderInTheme: () => {
        try {
            document.getElementById('kt_header').remove();
        }catch(e){}
    },


    getUint8Array: (base64) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    },
    saveByteArray(reportName, byte, type = "application/pdf") {
        if(byte){
            let byteArray = Utils.getUint8Array(byte);
            let blob = new Blob([byteArray], { type: type });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', reportName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    },
    printByteArray(title, html) {
        window.localStorage.setItem('print_' + title, html);

        const link = document.createElement('a');
        link.href = '/Report-Iframe?title=' + title;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
    },
    async getTemplateFromFile(fileName) {
        return await axios.get(fileName);
    },



    getLocalIsoDateTime: (dtString) => {
        try {
            if (dtString == "")
                return "";

            var offset = new Date().getTimezoneOffset();
            var localISOTime = (new Date(new Date(dtString) - offset * 60000 /*offset in milliseconds*/)).toISOString().slice(0, -1);
            //Next two lines can be removed if zone isn't needed.
            //var absO = Math.abs(offset);
            //var zone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(absO / 60)).slice(-2) + ":" + ("00" + (absO % 60)).slice(-2);
            return localISOTime;// + zone;
        } catch (e) { }
    },

    convertDateTime: (value) => {
        let currentDate = moment().format(Utils.getAPIDateFormat());
        let selectedTime = moment(value).format(Utils.getDefaultTimeFormat());

        return Utils.getLocalIsoDateTime(moment(currentDate + ' ' + selectedTime));
    },
    convertDateTimeByDate: (date, value) => {
        let currentDate = moment(date).format(Utils.getAPIDateFormat());
        let selectedTime = moment(value).format(Utils.getDefaultTimeFormat());

        return Utils.getLocalIsoDateTime(moment(currentDate + ' ' + selectedTime));
    },

    makeIDfromString: (str) => {
        try {
            return (str && str !== '') ? str.trim().replaceAll(' ', '_') : str;
        } catch (e) {
            return str;
        }
    },

    addSuffix(a) {
        if (a % 100 >= 11 && a % 100 <= 13) {
            return "th";
        }

        switch (a % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    },


    getJobFormLocationMarkers(data, values) {
        let customer1Lat = null;
        let customer1Lng = null;
        if (data.customerSiteId > 0) {
            let customerSiteArr = data.customerSiteItems.filter(x => x.value === data.customerSiteId);
            if (customerSiteArr && customerSiteArr.length > 0) {
                customer1Lat = customerSiteArr[0].item.latitude;
                customer1Lng = customerSiteArr[0].item.longitude;
            }
        }

        let customer2Lat = null;
        let customer2Lng = null;
        if (data.customerSiteShiftId > 0) {
            let customerSiteShiftArr = data.customerSiteShiftItems.filter(x => x.value === data.customerSiteShiftId);
            if (customerSiteShiftArr && customerSiteShiftArr.length > 0) {
                customer2Lat = customerSiteShiftArr[0].item.latitude;
                customer2Lng = customerSiteShiftArr[0].item.longitude;
            }
        }

        let markers = [];
        if (data.jobTemplateType === JobStepType.Exchange) {
            let coordStart = Utils.validateCoordinates([data.templateStepSites1Latitude, data.templateStepSites1Longitude].join(',')) ? { lat: data.templateStepSites1Latitude, lng: data.templateStepSites1Longitude } : null;
            let coordCustomer = Utils.validateCoordinates([customer1Lat, customer1Lng].join(',')) ? { lat: customer1Lat, lng: customer1Lng } : null;
            let coordEnd = Utils.validateCoordinates([data.templateStepSites2Latitude, data.templateStepSites2Longitude].join(',')) ? { lat: data.templateStepSites2Latitude, lng: data.templateStepSites2Longitude } : null;

            if (coordStart) {
                markers.push({
                    customerSiteId: null,
                    stepOrder: 1,
                    latitude: coordStart.lat,
                    longitude: coordStart.lng,
                });
            }
            if (coordCustomer) {
                markers.push({
                    customerSiteId: data.customerSiteId,
                    stepOrder: 2,
                    latitude: coordCustomer.lat,
                    longitude: coordCustomer.lng,
                });
            }
            if (coordEnd) {
                markers.push({
                    customerSiteId: null,
                    stepOrder: 3,
                    latitude: coordEnd.lat,
                    longitude: coordEnd.lng,
                });
            }

        } else if (data.jobTemplateType === JobStepType.OnTheSpot) {
            let coordStart = Utils.validateCoordinates([data.templateStepSites1Latitude, data.templateStepSites1Longitude].join(',')) ? { lat: data.templateStepSites1Latitude, lng: data.templateStepSites1Longitude } : null;
            let coordCustomer = Utils.validateCoordinates([customer1Lat, customer1Lng].join(',')) ? { lat: customer1Lat, lng: customer1Lng } : null;
            let coordEnd = Utils.validateCoordinates([data.templateStepSites2Latitude, data.templateStepSites2Longitude].join(',')) ? { lat: data.templateStepSites2Latitude, lng: data.templateStepSites2Longitude } : null;

            if (coordStart) {
                markers.push({
                    customerSiteId: null,
                    stepOrder: 1,
                    latitude: coordStart.lat,
                    longitude: coordStart.lng,
                });
            }
            if (coordCustomer) {
                markers.push({
                    customerSiteId: data.customerSiteId,
                    stepOrder: 2,
                    latitude: coordCustomer.lat,
                    longitude: coordCustomer.lng,
                });
            }
            if (coordEnd) {
                markers.push({
                    customerSiteId: null,
                    stepOrder: 3,
                    latitude: coordEnd.lat,
                    longitude: coordEnd.lng,
                });
            }

        } else if (data.jobTemplateType === JobStepType.Pull) {
            let coordCustomer = Utils.validateCoordinates([customer1Lat, customer1Lng].join(',')) ? { lat: customer1Lat, lng: customer1Lng } : null;
            let coordStart = Utils.validateCoordinates([data.templateStepSites1Latitude, data.templateStepSites1Longitude].join(',')) ? { lat: data.templateStepSites1Latitude, lng: data.templateStepSites1Longitude } : null;

            if (coordCustomer) {
                markers.push({
                    customerSiteId: data.customerSiteId,
                    stepOrder: 1,
                    latitude: coordCustomer.lat,
                    longitude: coordCustomer.lng,
                });
            }
            if (coordStart) {
                markers.push({
                    customerSiteId: null,
                    stepOrder: 2,
                    latitude: coordStart.lat,
                    longitude: coordStart.lng,
                });
            }

        } else if (data.jobTemplateType === JobStepType.Put) {
            let coordStart = Utils.validateCoordinates([data.templateStepSites1Latitude, data.templateStepSites1Longitude].join(',')) ? { lat: data.templateStepSites1Latitude, lng: data.templateStepSites1Longitude } : null;
            let coordCustomer = Utils.validateCoordinates([customer1Lat, customer1Lng].join(',')) ? { lat: customer1Lat, lng: customer1Lng } : null;

            if (coordStart) {
                markers.push({
                    customerSiteId: null,
                    stepOrder: 1,
                    latitude: coordStart.lat,
                    longitude: coordStart.lng,
                });
            }
            if (coordCustomer) {
                markers.push({
                    customerSiteId: data.customerSiteId,
                    stepOrder: 2,
                    latitude: coordCustomer.lat,
                    longitude: coordCustomer.lng,
                });
            }

        } else if (data.jobTemplateType === JobStepType.Task) {
            let coordStart = Utils.validateCoordinates([data.templateStepSites1Latitude, data.templateStepSites1Longitude].join(',')) ? { lat: data.templateStepSites1Latitude, lng: data.templateStepSites1Longitude } : null;
            let coordCustomer = Utils.validateCoordinates([customer1Lat, customer1Lng].join(',')) ? { lat: customer1Lat, lng: customer1Lng } : null;

            if (coordStart) {
                markers.push({
                    customerSiteId: null,
                    stepOrder: 1,
                    latitude: coordStart.lat,
                    longitude: coordStart.lng,
                });
            }
            if (coordCustomer) {
                markers.push({
                    customerSiteId: data.customerSiteId,
                    stepOrder: 2,
                    latitude: coordCustomer.lat,
                    longitude: coordCustomer.lng,
                });
            }

        } else if (data.jobTemplateType === JobStepType.Out) {
            let coordStart = Utils.validateCoordinates([data.templateStepSites1Latitude, data.templateStepSites1Longitude].join(',')) ? { lat: data.templateStepSites1Latitude, lng: data.templateStepSites1Longitude } : null;
            let coordCustomer = Utils.validateCoordinates([customer1Lat, customer1Lng].join(',')) ? { lat: customer1Lat, lng: customer1Lng } : null;

            if (coordStart) {
                markers.push({
                    customerSiteId: null,
                    stepOrder: 1,
                    latitude: coordStart.lat,
                    longitude: coordStart.lng,
                });
            }
            if (coordCustomer) {
                markers.push({
                    customerSiteId: data.customerSiteId,
                    stepOrder: 2,
                    latitude: coordCustomer.lat,
                    longitude: coordCustomer.lng,
                });
            }

        } else if (data.jobTemplateType === JobStepType.Shift) {
            let coordCustomer1 = Utils.validateCoordinates([customer1Lat, customer1Lng].join(',')) ? { lat: customer1Lat, lng: customer1Lng } : null;
            let coordCustomer2 = Utils.validateCoordinates([customer2Lat, customer2Lng].join(',')) ? { lat: customer2Lat, lng: customer2Lng } : null;

            if (coordCustomer1) {
                markers.push({
                    customerSiteId: data.customerSiteId,
                    stepOrder: 1,
                    latitude: coordCustomer1.lat,
                    longitude: coordCustomer1.lng,
                });
            }
            if (coordCustomer2) {
                markers.push({
                    customerSiteId: data.customerSiteShiftId,
                    stepOrder: 2,
                    latitude: coordCustomer2.lat,
                    longitude: coordCustomer2.lng,
                });
            }
        }

        return markers;
    },
    getJobFormLocationCustomerMarker(formOptions) {
        let {
            values,
        } = formOptions;


        let customer1Lat = null;
        let customer1Lng = null;
        let customerSiteId = values.customerSiteId;
        let customerSiteItems = values.customFormParams.customerSiteItems;
        if (customerSiteId > 0) {
            let customerSiteArr = customerSiteItems.filter(x => x.value === customerSiteId);
            if (customerSiteArr && customerSiteArr.length > 0) {
                customer1Lat = customerSiteArr[0].item.latitude;
                customer1Lng = customerSiteArr[0].item.longitude;
            }
        }

        let coordCustomer = Utils.validateCoordinates([customer1Lat, customer1Lng].join(',')) ? { lat: customer1Lat, lng: customer1Lng } : null;

        return coordCustomer;
    },


    getJobTypeInfoByText(jobType) {
        if (jobType === "Pull") {
            return JobStepType.Pull.toString();
        } else if (jobType === "Put") {
            return JobStepType.Put.toString();
        } else if (jobType === "Task") {
            return JobStepType.Task.toString();
        } else if (jobType === "Exchange") {
            return JobStepType.Exchange.toString();
        } else if (jobType === "On The Spot") {
            return JobStepType.OnTheSpot.toString();
        } else if (jobType === "Out") {
            return JobStepType.Out.toString();
        } else if (jobType === "Shift") {
            return JobStepType.Shift.toString();
        } else if (jobType === "Throw At Customer") {
            return JobStepType.ThrowAtCustomer.toString();
        } else {
            return "";
        }
    },
    getJobTypeInfo(jobType) {
        if (jobType === JobStepType.Pull.toString()) {
            return "Pull";
        } else if (jobType === JobStepType.Put.toString()) {
            return "Put";
        } else if (jobType === JobStepType.Task.toString()) {
            return "Task";
        } else if (jobType === JobStepType.Exchange.toString()) {
            return "Exchange";
        } else if (jobType === JobStepType.OnTheSpot.toString()) {
            return "On The Spot";
        } else if (jobType === JobStepType.Out.toString()) {
            return "Out";
        } else if (jobType === JobStepType.Shift.toString()) {
            return "Shift";
        } else if (jobType === JobStepType.ThrowAtCustomer.toString()) {
            return "Throw At Customer";
        } else {
            return "";
        }
    },

    getInOut(stepType, wasteBinType) {
        if (stepType === JobStepType.Put) {
            return "OUT";
        } else if (stepType === JobStepType.Pull) {
            return "IN";
        } else if (stepType === JobStepType.Exchange) {
            if (wasteBinType === 1) {
                return "OUT";
            } else {
                return "IN";
            }
        } else if (stepType === JobStepType.OnTheSpot) {
            return "";
        } else if (stepType === JobStepType.Out) {
            return "OUT";
        } else if (stepType === JobStepType.Shift) {
            return "OUT";
        } else if (stepType === JobStepType.ThrowAtCustomer) {
            return "";
        } else {
            return "";
        }
    },
    getBinActivity(step, jobType) {
        if (jobType === JobStepType.Put) {
            if (step === 1) {
                return JobStepBinActivity.LoadBin;
            } else if (step === 2) {
                return JobStepBinActivity.PutBin;
            } else if (step === 3) {
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
        } else if (jobType === JobStepType.Task) {
            if (step === 1) {
                return JobStepBinActivity.NoActivity;
            } else if (step === 2) {
                return JobStepBinActivity.NoActivity;
            } else if (step === 3) {
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
        } else if (jobType === JobStepType.Pull) {
            if (step === 1) {
                return JobStepBinActivity.LoadBin;
            } else if (step === 2) {
                return JobStepBinActivity.PutBin;
            } else if (step === 3) {
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
        } else if (jobType === JobStepType.Exchange) {
            if (step === 1) {
                return JobStepBinActivity.LoadBin;
            } else if (step === 2) {
                return JobStepBinActivity.ExchangeBin;
            } else if (step === 3) {
                return JobStepBinActivity.PutBin;
            } else {
                return null;
            }
        } else if (jobType === JobStepType.OnTheSpot) {
            if (step === 1) {
                return JobStepBinActivity.LoadBin;
            } else if (step === 2) {
                return JobStepBinActivity.NoActivity;
            } else if (step === 3) {
                return JobStepBinActivity.PutBin;
            } else {
                return null;
            }
        } else if (jobType === JobStepType.Out) {
            if (step === 1) {
                return JobStepBinActivity.LoadBin;
            } else if (step === 2) {
                return JobStepBinActivity.PutBin;
            } else if (step === 3) {
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
        } else if (jobType === JobStepType.Shift) {
            if (step === 1) {
                return JobStepBinActivity.LoadBin;
            } else if (step === 2) {
                return JobStepBinActivity.PutBin;
            } else if (step === 3) {
                return JobStepBinActivity.NoActivity;
            } else {
                return null;
            }
        } else if (jobType === JobStepType.ThrowAtCustomer) {
            if (step === 1) {
                return JobStepBinActivity.LoadBin;
            } else if (step === 2) {
                return JobStepBinActivity.NoActivity;
            } else if (step === 3) {
                return JobStepBinActivity.PutBin;
            } else {
                return null;
            }
        } else {
            return null;
        }
    },
    getStepStruct(jobT) {
        // stepTypeDraw - if it is NONE it will not be displayed in form

        let jobType = jobT;
        try {
            jobType = parseInt(jobT);
        } catch (e) { }

        if (jobType === JobStepType.Put) {
            return [
                {
                    jobType: JobStepType.Put,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Put',
                    stepDescription: 'Deliver Bin to Site',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Put,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Put',
                    stepDescription: 'Deliver Bin to Site',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
            
        } else if(jobType === JobStepType.Task){
            return [ 
                {
                    jobType: JobStepType.Task,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.NoActivity,
                    stepTitle: 'Task',
                    stepDescription: 'Do task from this address',
                    stepName: 'Start',
                    binCount: 0,
                },
                {
                    jobType: JobStepType.Task,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.NoActivity,
                    stepTitle: 'Task',
                    stepDescription: 'Do task to this address',
                    stepName: 'Complete',
                    binCount: 0,
                }
            ];

        } else if (jobType === JobStepType.Pull) {
            return [
                {
                    jobType: JobStepType.Pull,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.BIN_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Pull',
                    stepDescription: 'Collect bin on site',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Pull,
                    stepTypeDraw: StepType.BIN_IN,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Pull',
                    stepDescription: 'Collect bin on site',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
        } else if (jobType === JobStepType.Exchange) {
            return [
                {
                    jobType: JobStepType.Exchange,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Exchange',
                    stepDescription: 'Deliver Bin to Site & Collect Bin from Site',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Exchange,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.BIN_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.ExchangeBin,
                    stepTitle: 'Exchange',
                    stepDescription: 'Deliver Bin to Site & Collect Bin from Site',
                    stepName: 'In Progress',
                    binCount: 0,
                },
                {
                    jobType: JobStepType.Exchange,
                    stepTypeDraw: StepType.BIN_IN,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Exchange',
                    stepDescription: 'Deliver Bin to Site & Collect Bin from Site',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
        } else if (jobType === JobStepType.OnTheSpot) {
            return [
                {
                    jobType: JobStepType.OnTheSpot,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'On The Spot',
                    stepDescription: 'Instant dumping of waste',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.OnTheSpot,
                    stepTypeDraw: StepType.WASTE_COLLECTION,
                    stepTypeSave: StepType.BIN_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.NoActivity,
                    stepTitle: 'On The Spot',
                    stepDescription: 'Instant dumping of waste',
                    stepName: 'In Progress',
                    binCount: 0,
                },
                {
                    jobType: JobStepType.OnTheSpot,
                    stepTypeDraw: StepType.BIN_IN,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'On The Spot',
                    stepDescription: 'Instant dumping of waste',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
        } else if (jobType === JobStepType.Shift) {
            return [
                {
                    jobType: JobStepType.Shift,
                    stepTypeDraw: StepType.SHIFT_EXCHANGE,
                    stepTypeSave: StepType.SHIFT_OUT,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Shift Bin',
                    stepDescription: 'Shift bin from address 1 to address 2',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Shift,
                    stepTypeDraw: StepType.SHIFT_EXCHANGE,
                    stepTypeSave: StepType.SHIFT_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Shift Bin',
                    stepDescription: 'Shift bin from address 1 to address 2',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];

        } else if (jobType === JobStepType.Out) {
            return [ 
                {
                    jobType: JobStepType.Out,
                    stepTypeDraw: StepType.BIN_OUT,
                    stepTypeSave: StepType.BIN_OUT,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Out',
                    stepDescription: 'Deliver Bin to Site',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.Out,
                    stepTypeDraw: StepType.BIN_EXCHANGE,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Out',
                    stepDescription: 'Deliver Bin to Site',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
            
        /* this below is not used for now */
        } else if (jobType === JobStepType.ThrowAtCustomer) {
            return [
                {
                    jobType: JobStepType.ThrowAtCustomer,
                    stepTypeDraw: StepType.NONE,
                    stepTypeSave: StepType.BIN_IN,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.LoadBin,
                    stepTitle: 'Throw At Customer Site',
                    stepDescription: 'Act on customer\'s request',
                    stepName: 'Start',
                    binCount: -1,
                },
                {
                    jobType: JobStepType.ThrowAtCustomer,
                    stepTypeDraw: StepType.WASTE_COLLECTION,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.SITE_ADDRESS,
                    binActivity: JobStepBinActivity.NoActivity,
                    stepTitle: 'Throw At Customer Site',
                    stepDescription: 'Act on customer\'s request',
                    stepName: 'In Progress',
                    binCount: 0,
                },
                {
                    jobType: JobStepType.ThrowAtCustomer,
                    stepTypeDraw: StepType.NONE,
                    stepTypeSave: StepType.NONE,
                    addressType: StepAddress.CUSTOMER_ADDRESS,
                    binActivity: JobStepBinActivity.PutBin,
                    stepTitle: 'Throw At Customer Site',
                    stepDescription: 'Act on customer\'s request',
                    stepName: 'Complete',
                    binCount: 1,
                }
            ];
        } else {
            return null;
        }
    },

    getBinActivityText: (binActivity, stepType, jobType) => {
        if ((stepType === StepType.WASTE_COLLECTION)) {
            return 'Collect waste at';
        } else {
            if (binActivity === JobStepBinActivity.LoadBin) {
                return 'Collect bin from';
            } else if (binActivity === JobStepBinActivity.PutBin) {
                if ((jobType === JobStepType.Put) || (jobType === JobStepType.Shift)) {
                    return 'Deliver bin to';
                } else {
                    return 'Return bin to';
                }
            } else if (binActivity === JobStepBinActivity.ExchangeBin) {
                return 'Exchange bin at';
            } else {
                return '';
            }
        }
    },
    
    getBinActivityLabel: (binActivity) => {
        if(binActivity === JobStepBinActivity.NoActivity){
            return ''
        } else if(binActivity === JobStepBinActivity.ExchangeBin){
            return 'IN'
        } else if(binActivity === JobStepBinActivity.PutBin){
            return 'OUT'
        } else if(binActivity === JobStepBinActivity.LoadBin){
            return 'IN'
        } else {
            return ''
        }
    },

    formatCustomerSiteAddress(item = null) {
        let address = '';

        if (item) {
            let siteName = (item.siteName && item.siteName !== '') ? item.siteName : '';
            let siteNameDisplay = (item.siteNameDisplay && item.siteNameDisplay !== '') ? item.siteNameDisplay : '';
            let siteN = (siteNameDisplay !== '') ? siteNameDisplay : siteName;

            let blockNo = (item.blockNo && item.blockNo !== '') ? item.blockNo : '';
            let street = (item.street && item.street !== '') ? item.street : '';
            let unitNo = (item.unitNo && item.unitNo !== '') ? item.unitNo : '';
            let postalCode = (item.postalCode && item.postalCode !== '') ? item.postalCode : '';

            let addressParts = [];
            if (blockNo !== '') {
                addressParts.push('Block ' + blockNo);
            }
            if (street !== '') {
                addressParts.push(street);
            }
            if (unitNo !== '') {
                addressParts.push('Unit No. ' + unitNo);
            }
            if (postalCode !== '') {
                addressParts.push('Post Code ' + postalCode);
            }

            address = siteN;
            if (addressParts && addressParts.length > 0) {
                address += ' (' + addressParts.join(', ') + ')';
            }
        }

        return address;
    },

    isRecurringFormAndisActive(isRecurringForm, isActive) {
        return (!isRecurringForm ? !isActive : false);
    },
    isStartedOrInProgressOrCompletedOrCancelledOrFailed(status = '') {
        if (status.toString().toLowerCase() === 'completed') {
            return true;
        } else if (status.toString().toLowerCase() === 'cancelled') {
            return true;
        } else if (status.toString().toLowerCase() === 'failed') {
            return true;
        } else if (status.toString().toLowerCase() === 'started') {
            return true;
        } else if (status.toString().toLowerCase() === 'in progress') {
            return true;
        } else {
            return false;
        }
    },
    isRejectedOrCancelledOrFailedOrIsNotActive(status = '', isActive = true) {
        if (!isActive) {
            return true;
        } else {
            if (status && status != '') {
                if (status.toString().toLowerCase() === 'rejected') {
                    return true;
                } else if (status.toString().toLowerCase() === 'cancelled') {
                    return true;
                } else if (status.toString().toLowerCase() === 'failed') {
                    return true;
                } else if (status.toString().toLowerCase() === 'deleted') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },
    isRejectedOrCancelledOrFailedOrDeleted(status = '') {
        if (status && status != '') {
            if (status.toString().toLowerCase() === 'rejected') {
                return true;
            } else if (status.toString().toLowerCase() === 'cancelled') {
                return true;
            } else if (status.toString().toLowerCase() === 'failed') {
                return true;
            } else if (status.toString().toLowerCase() === 'deleted') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    isCompleted(status = '') {
        if (status.toString().toLowerCase() === 'completed') {
            return true;
        } else {
            return false;
        }
    },
    isCompletedOrCancelledOrFailed(status = '') {
        if (status.toString().toLowerCase() === 'completed') {
            return true;
        } else if (status.toString().toLowerCase() === 'cancelled') {
            return true;
        } else if (status.toString().toLowerCase() === 'failed') {
            return true;
        } else {
            return false;
        }
    },
    isUnassignedOrCancelledOrFailed(status = '') {
        if (status.toString().toLowerCase() === 'cancelled') {
            return true;
        } else if (status.toString().toLowerCase() === 'failed') {
            return true;
        } else if (status.toString().toLowerCase() === 'unassigned') {
            return true;
        } else {
            return false;
        }
    },
    isUnassignedOrCancelledOrFailedOrDraft(status = '') {
        if (status.toString().toLowerCase() === 'cancelled') {
            return true;
        } else if (status.toString().toLowerCase() === 'failed') {
            return true;
        } else if (status.toString().toLowerCase() === 'unassigned') {
            return true;
        } else if (status.toString().toLowerCase() === 'draft') {
            return true;
        } else {
            return false;
        }
    },
    isCancelledOrFailed(status = '') {
        if (status.toString().toLowerCase() === 'cancelled') {
            return true;
        } else if (status.toString().toLowerCase() === 'failed') {
            return true;
        } else {
            return false;
        }
    },
    isCancelled(status = '') {
        if (status.toString().toLowerCase() === 'cancelled') {
            return true;
        } else {
            return false;
        }
    },
    isFailed(status = '') {
        if (status.toString().toLowerCase() === 'failed') {
            return true;
        } else {
            return false;
        }
    },
    isDraft(status = '') {
        if (status.toString().toLowerCase() === 'draft') {
            return true;
        } else {
            return false;
        }
    },
    isVisibleAutoSuggestDriver(status = '') {
        if (status.toString().toLowerCase() === '') {
            return true;
        } else if (status.toString().toLowerCase() === 'unassigned') {
            return true;
        } else if (status.toString().toLowerCase() === 'assigned') {
            return true;
        } else if (status.toString().toLowerCase() === 'dispatched') {
            return true;
        } else {
            return false;
        }
    },
    sortStatus(status, field = 'order') {
        if (status && status.length > 0) {
            return status.sort((a, b) => {
                return a[field] - b[field];
            });
        } else {
            return [];
        }
    },
    findStatus(statuses = [], param = '', text = '') {
        let status = null;

        if (statuses && statuses.length > 0) {
            let statusItem = statuses.filter(x => x[param].toLowerCase().trim() === text.toLowerCase().trim());

            if (statusItem && statusItem.length > 0) {
                status = statusItem[0];
            }
        }

        return status;
    },
    setStatusDisable(statuses = [], currentStatusId = null, driverId = null, isEdit = false, isCopy = false, jobTemplateType = null) {
        let statusUnassigned = Utils.findStatus(statuses, "title", "unassigned");
        let statusAssigned = Utils.findStatus(statuses, "title", "assigned");
        let statusDispatched = Utils.findStatus(statuses, "title", "dispatched");
        let statusAcknowledged = Utils.findStatus(statuses, "title", "acknowledged");
        let statusStarted = Utils.findStatus(statuses, "title", "Started");
        let statusInProgress = Utils.findStatus(statuses, "title", "In Progress");
        let statusCompleted = Utils.findStatus(statuses, "title", "Completed");
        let statusCancelled = Utils.findStatus(statuses, "title", "Cancelled");
        let statusFailed = Utils.findStatus(statuses, "title", "Failed");
        let statusRejected = Utils.findStatus(statuses, "title", "Rejected");

        let statusUnassignedId = statusUnassigned && statusUnassigned.value ? statusUnassigned.value : null;
        let statusAssignedId = statusAssigned && statusAssigned.value ? statusAssigned.value : null;
        let statusDispatchedId = statusDispatched && statusDispatched.value ? statusDispatched.value : null;
        let statusAcknowledgedId = statusAcknowledged && statusAcknowledged.value ? statusAcknowledged.value : null;
        let statusStartedId = statusStarted && statusStarted.value ? statusStarted.value : null;
        let statusInProgressId = statusInProgress && statusInProgress.value ? statusInProgress.value : null;
        let statusCompletedId = statusCompleted && statusCompleted.value ? statusCompleted.value : null;
        let statusCancelledId = statusCancelled && statusCancelled.value ? statusCancelled.value : null;
        let statusFailedId = statusFailed && statusFailed.value ? statusFailed.value : null;
        let statusRejectedId = statusRejected && statusRejected.value ? statusRejected.value : null;

        let canInProgress = Utils.canInProgressStatus(jobTemplateType);

        let js = [];
        if (statuses && statuses.length > 0) {
            statuses.forEach((item, i) => {
                if (!isCopy && isEdit) {
                    if (currentStatusId == statusUnassignedId) {
                        if (driverId === null) {
                            if (item.value == statusAssignedId) {
                                js = Utils.addToArr(js, statusAssignedId);
                            } else if (item.value == statusDispatchedId) {
                                js = Utils.addToArr(js, statusDispatchedId);
                            } else if (item.value == statusAcknowledgedId) {
                                js = Utils.addToArr(js, statusAcknowledgedId);
                            } else if (item.value == statusStartedId) {
                                js = Utils.addToArr(js, statusStartedId);
                            } else if (item.value == statusInProgressId) {
                                js = Utils.addToArr(js, statusInProgressId);
                            } else if (item.value == statusCompletedId) {
                                js = Utils.addToArr(js, statusCompletedId);
                            } else if (item.value == statusCancelledId) {
                                js = Utils.addToArr(js, statusCancelledId);
                            } else if (item.value == statusFailedId) {
                                js = Utils.addToArr(js, statusFailedId);
                            } else if (item.value == statusRejectedId) {
                                js = Utils.addToArr(js, statusRejectedId);
                            }
                        } else {
                            if (item.value == statusCompletedId) {
                                js = Utils.addToArr(js, statusCompletedId);
                            } else if (item.value == statusCancelledId) {
                                js = Utils.addToArr(js, statusCancelledId);
                            } else if (item.value == statusFailedId) {
                                js = Utils.addToArr(js, statusFailedId);
                            } else if (item.value == statusRejectedId) {
                                js = Utils.addToArr(js, statusRejectedId);
                            }
                        }

                    } else if (currentStatusId == statusAssignedId) {
                        if (driverId === null) {
                            if (item.value == statusAssignedId) {
                                js = Utils.addToArr(js, statusAssignedId);
                            } else if (item.value == statusDispatchedId) {
                                js = Utils.addToArr(js, statusDispatchedId);
                            } else if (item.value == statusAcknowledgedId) {
                                js = Utils.addToArr(js, statusAcknowledgedId);
                            } else if (item.value == statusStartedId) {
                                js = Utils.addToArr(js, statusStartedId);
                            } else if (item.value == statusInProgressId) {
                                js = Utils.addToArr(js, statusInProgressId);
                            } else if (item.value == statusCompletedId) {
                                js = Utils.addToArr(js, statusCompletedId);
                            }
                        } else {
                            if (item.value == statusCompletedId) {
                                js = Utils.addToArr(js, statusCompletedId);
                            }
                        }
                    } else if (currentStatusId == statusDispatchedId) {
                        if (driverId === null) {
                            if (item.value == statusAssignedId) {
                                js = Utils.addToArr(js, statusAssignedId);
                            } else if (item.value == statusDispatchedId) {
                                js = Utils.addToArr(js, statusDispatchedId);
                            } else if (item.value == statusAcknowledgedId) {
                                js = Utils.addToArr(js, statusAcknowledgedId);
                            } else if (item.value == statusStartedId) {
                                js = Utils.addToArr(js, statusStartedId);
                            } else if (item.value == statusInProgressId) {
                                js = Utils.addToArr(js, statusInProgressId);
                            } else if (item.value == statusCompletedId) {
                                js = Utils.addToArr(js, statusCompletedId);
                            }
                        } else {
                            if (item.value == statusUnassignedId) {
                                js = Utils.addToArr(js, statusUnassignedId);
                            }
                        }
                    } else if (currentStatusId == statusAcknowledgedId) {
                        if (driverId === null) {
                            if (item.value == statusAssignedId) {
                                js = Utils.addToArr(js, statusAssignedId);
                            } else if (item.value == statusDispatchedId) {
                                js = Utils.addToArr(js, statusDispatchedId);
                            } else if (item.value == statusAcknowledgedId) {
                                js = Utils.addToArr(js, statusAcknowledgedId);
                            } else if (item.value == statusStartedId) {
                                js = Utils.addToArr(js, statusStartedId);
                            } else if (item.value == statusInProgressId) {
                                js = Utils.addToArr(js, statusInProgressId);
                            } else if (item.value == statusCompletedId) {
                                js = Utils.addToArr(js, statusCompletedId);
                            }
                        } else {
                            if (item.value == statusUnassignedId) {
                                js = Utils.addToArr(js, statusUnassignedId);
                            }
                        }
                    } else if (currentStatusId == statusStartedId) {
                        if (item.value == statusUnassignedId) {
                            js = Utils.addToArr(js, statusUnassignedId);
                        } else if (item.value == statusAssignedId) {
                            js = Utils.addToArr(js, statusAssignedId);
                        } else if (item.value == statusDispatchedId) {
                            js = Utils.addToArr(js, statusDispatchedId);
                        } else if (item.value == statusAcknowledgedId) {
                            js = Utils.addToArr(js, statusAcknowledgedId);
                        }
                    } else if (currentStatusId == statusInProgressId) {
                        if (item.value == statusUnassignedId) {
                            js = Utils.addToArr(js, statusUnassignedId);
                        } else if (item.value == statusAssignedId) {
                            js = Utils.addToArr(js, statusAssignedId);
                        } else if (item.value == statusDispatchedId) {
                            js = Utils.addToArr(js, statusDispatchedId);
                        } else if (item.value == statusAcknowledgedId) {
                            js = Utils.addToArr(js, statusAcknowledgedId);
                        } else if (item.value == statusStartedId) {
                            js = Utils.addToArr(js, statusStartedId);
                        }
                    } else if (currentStatusId == statusCompletedId) {
                        if (item.value == statusUnassignedId) {
                            js = Utils.addToArr(js, statusUnassignedId);
                        } else if (item.value == statusAssignedId) {
                            js = Utils.addToArr(js, statusAssignedId);
                        } else if (item.value == statusDispatchedId) {
                            js = Utils.addToArr(js, statusDispatchedId);
                        } else if (item.value == statusAcknowledgedId) {
                            js = Utils.addToArr(js, statusAcknowledgedId);
                        } else if (item.value == statusStartedId) {
                            js = Utils.addToArr(js, statusStartedId);
                        } else if (item.value == statusInProgressId) {
                            js = Utils.addToArr(js, statusInProgressId);
                        } else if (item.value == statusCompletedId) {
                            js = Utils.addToArr(js, statusCompletedId);
                        } else if (item.value == statusRejectedId) {
                            js = Utils.addToArr(js, statusRejectedId);
                        }
                    } else if (currentStatusId == statusCancelledId) {
                        if (item.value == statusUnassignedId) {
                            js = Utils.addToArr(js, statusUnassignedId);
                        } else if (item.value == statusAssignedId) {
                            js = Utils.addToArr(js, statusAssignedId);
                        } else if (item.value == statusDispatchedId) {
                            js = Utils.addToArr(js, statusDispatchedId);
                        } else if (item.value == statusAcknowledgedId) {
                            js = Utils.addToArr(js, statusAcknowledgedId);
                        } else if (item.value == statusStartedId) {
                            js = Utils.addToArr(js, statusStartedId);
                        } else if (item.value == statusInProgressId) {
                            js = Utils.addToArr(js, statusInProgressId);
                        } else if (item.value == statusCompletedId) {
                            js = Utils.addToArr(js, statusCompletedId);
                        } else if (item.value == statusFailedId) {
                            js = Utils.addToArr(js, statusFailedId);
                        } else if (item.value == statusRejectedId) {
                            js = Utils.addToArr(js, statusRejectedId);
                        }
                    } else if (currentStatusId == statusFailedId) {
                        if (item.value == statusUnassignedId) {
                            js = Utils.addToArr(js, statusUnassignedId);
                        } else if (item.value == statusAssignedId) {
                            js = Utils.addToArr(js, statusAssignedId);
                        } else if (item.value == statusDispatchedId) {
                            js = Utils.addToArr(js, statusDispatchedId);
                        } else if (item.value == statusAcknowledgedId) {
                            js = Utils.addToArr(js, statusAcknowledgedId);
                        } else if (item.value == statusStartedId) {
                            js = Utils.addToArr(js, statusStartedId);
                        } else if (item.value == statusInProgressId) {
                            js = Utils.addToArr(js, statusInProgressId);
                        } else if (item.value == statusCompletedId) {
                            js = Utils.addToArr(js, statusCompletedId);
                        } else if (item.value == statusCancelledId) {
                            js = Utils.addToArr(js, statusCancelledId);
                        } else if (item.value == statusRejectedId) {
                            js = Utils.addToArr(js, statusRejectedId);
                        }
                    } else if (currentStatusId == statusRejectedId) {
                        if (item.value == statusUnassignedId) {
                            js = Utils.addToArr(js, statusUnassignedId);
                        } else if (item.value == statusAssignedId) {
                            js = Utils.addToArr(js, statusAssignedId);
                        } else if (item.value == statusDispatchedId) {
                            js = Utils.addToArr(js, statusDispatchedId);
                        } else if (item.value == statusAcknowledgedId) {
                            js = Utils.addToArr(js, statusAcknowledgedId);
                        } else if (item.value == statusStartedId) {
                            js = Utils.addToArr(js, statusStartedId);
                        } else if (item.value == statusInProgressId) {
                            js = Utils.addToArr(js, statusInProgressId);
                        } else if (item.value == statusCompletedId) {
                            js = Utils.addToArr(js, statusCompletedId);
                        } else if (item.value == statusCancelledId) {
                            js = Utils.addToArr(js, statusCancelledId);
                        } else if (item.value == statusFailedId) {
                            js = Utils.addToArr(js, statusFailedId);
                        }
                    }
                } else {
                    // Copy/Create Job
                    if (item.value == statusAcknowledgedId) {
                        js = Utils.addToArr(js, statusAcknowledgedId);
                    } else if (item.value == statusStartedId) {
                        js = Utils.addToArr(js, statusStartedId);
                    } else if (item.value == statusInProgressId) {
                        js = Utils.addToArr(js, statusInProgressId);
                    } else if (item.value == statusCompletedId) {
                        js = Utils.addToArr(js, statusCompletedId);
                    } else if (item.value == statusCancelledId) {
                        js = Utils.addToArr(js, statusCancelledId);
                    } else if (item.value == statusFailedId) {
                        js = Utils.addToArr(js, statusFailedId);
                    } else if (item.value == statusRejectedId) {
                        js = Utils.addToArr(js, statusRejectedId);
                    }
                }

                if (!canInProgress) {
                    js = Utils.addToArr(js, statusInProgressId);
                }

                return item;
            });
        }

        return js;
    },
    canInProgressStatus(jobTemplateType = null) {
        return ((jobTemplateType == JobStepType.Exchange) || (jobTemplateType == JobStepType.OnTheSpot)) ? true : false;
    },


    onClearCustomerSite(formOptions) {
        let {
            setValues,
            values,
        } = formOptions;

        setValues({
            ...values,

            customerSiteId: null,
            customerSiteName: '',
            customerSiteAddress: '',
            customerSiteItem: null,

            driverId: null,
            driverName: '',
            vehicleId: null,
            vehicleName: '',
            siteRemarks: '',
            contactPersonOne: '',
            contactNumberOne: '',
            contactPersonTwo: '',
            contactNumberTwo: '',
        });
    },
    onClearSteps(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            stepExchangeOutServiceItemId: null,
            stepExchangeOutServiceItemName: '',
            stepExchangeOutBinTypeId: null,
            stepExchangeOutBinTypeName: '',
            stepExchangeOutWasteTypeId: null,
            stepExchangeOutWasteTypeName: '',
            stepExchangeOutWasteTypeIds: null,
            stepExchangeOutBinNumber: '',
            stepExchangeOutIsCopyService: false,

            stepExchangeInServiceItemId: null,
            stepExchangeInServiceItemName: '',
            stepExchangeInBinTypeId: null,
            stepExchangeInBinTypeName: '',
            stepExchangeInWasteTypeId: null,
            stepExchangeInWasteTypeName: '',
            stepExchangeInWasteTypeIds: null,
            stepExchangeInBinNumber: '',
            stepExchangeInIsCopyService: false,

            stepPutServiceItemId: null,
            stepPutServiceItemName: '',
            stepPutBinTypeId: null,
            stepPutBinTypeName: '',
            stepPutWasteTypeId: null,
            stepPutWasteTypeName: '',
            stepPutWasteTypeIds: null,
            stepPutBinNumber: '',
            stepPutIsCopyService: false,

            stepOutServiceItemId: null,
            stepOutServiceItemName: '',
            stepOutBinTypeId: null,
            stepOutBinTypeName: '',
            stepOutWasteTypeId: null,
            stepOutWasteTypeName: '',
            stepOutWasteTypeIds: null,
            stepOutBinNumber: '',
            stepOutIsCopyService: false,

            stepPullServiceItemId: null,
            stepPullServiceItemName: '',
            stepPullBinTypeId: null,
            stepPullBinTypeName: '',
            stepPullWasteTypeId: null,
            stepPullWasteTypeName: '',
            stepPullWasteTypeIds: null,
            stepPullBinNumber: '',
            stepPullIsCopyService: false,

            stepShiftServiceItemId: null,
            stepShiftServiceItemName: '',
            stepShiftBinTypeId: null,
            stepShiftBinTypeName: '',
            stepShiftWasteTypeId: null,
            stepShiftWasteTypeName: '',
            stepShiftWasteTypeIds: null,
            stepShiftBinNumber: '',
            stepShiftIsCopyService: false,

            stepOnTheSpotServiceItemId: null,
            stepOnTheSpotServiceItemName: '',
            stepOnTheSpotBinTypeId: null,
            stepOnTheSpotBinTypeName: '',
            stepOnTheSpotWasteTypeId: null,
            stepOnTheSpotWasteTypeName: '',
            stepOnTheSpotWasteTypeIds: null,
            stepOnTheSpotBinNumber: '',
            stepOnTheSpotIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                stepExchangeInBinWasteTypeId: null,
                stepPutBinWasteTypeId: null,
                stepOutBinWasteTypeId: null,
                stepPullBinWasteTypeId: null,
                stepShiftBinWasteTypeId: null,
                stepOnTheSpotBinWasteTypeId: null,
                isSameAsBinOut: false,
            },
        });
    },
    onClearServices(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            pricings: [],

            stepBinWeight: '',
            binOutWeight: '',
            weighChitTareWeight: '0',
            weighChitNettWeight: '0',
            nonBillableBinWeight: '0',
            weighChitBillableWeight: '0',

            stepExchangeOutServiceItemId: null,
            stepExchangeOutServiceItemName: '',
            stepExchangeOutBinTypeId: null,
            stepExchangeOutBinTypeName: '',
            stepExchangeOutBinTypeItem: null,
            stepExchangeOutWasteTypeItem: null,
            stepExchangeOutWasteTypeId: null,
            stepExchangeOutWasteTypeName: '',
            stepExchangeOutWasteTypeIds: null,
            stepExchangeOutSelectedIds: [],
            stepExchangeOutBinNumber: '',
            stepExchangeOutIsServiceOrHistory: false,
            stepExchangeOutIsCopyService: false,

            stepExchangeInServiceItemId: null,
            stepExchangeInServiceItemName: '',
            stepExchangeInBinTypeId: null,
            stepExchangeInBinTypeName: '',
            stepExchangeInWasteTypeId: null,
            stepExchangeInWasteTypeName: '',
            stepExchangeInWasteTypeIds: null,
            stepExchangeInBinNumber: '',
            stepExchangeInSelectedIds: [],
            stepExchangeInIsServiceOrHistory: false,
            stepExchangeInIsCopyService: false,

            stepOnTheSpotServiceItemId: null,
            stepOnTheSpotServiceItemName: '',
            stepOnTheSpotBinTypeId: null,
            stepOnTheSpotBinTypeName: '',
            stepOnTheSpotWasteTypeId: null,
            stepOnTheSpotWasteTypeName: '',
            stepOnTheSpotWasteTypeIds: null,
            stepOnTheSpotBinNumber: '',
            stepOnTheSpotIsServiceOrHistory: false,
            stepOnTheSpotSelectedIds: [],
            stepOnTheSpotIsCopyService: false,

            stepShiftServiceItemId: null,
            stepShiftServiceItemName: '',
            stepShiftBinTypeId: null,
            stepShiftBinTypeName: '',
            stepShiftWasteTypeId: null,
            stepShiftWasteTypeName: '',
            stepShiftWasteTypeIds: null,
            stepShiftBinNumber: '',
            stepShiftIsServiceOrHistory: false,
            stepShiftSelectedIds: [],
            stepShiftIsCopyService: false,

            stepPutServiceItemId: null,
            stepPutServiceItemName: '',
            stepPutBinTypeId: null,
            stepPutBinTypeName: '',
            stepPutWasteTypeId: null,
            stepPutWasteTypeName: '',
            stepPutWasteTypeIds: null,
            stepPutBinNumber: '',
            stepPutIsServiceOrHistory: false,
            stepPutSelectedIds: [],
            stepPutIsCopyService: false,

            stepOutServiceItemId: null,
            stepOutServiceItemName: '',
            stepOutBinTypeId: null,
            stepOutBinTypeName: '',
            stepOutWasteTypeId: null,
            stepOutWasteTypeName: '',
            stepOutWasteTypeIds: null,
            stepOutBinNumber: '',
            stepOutIsServiceOrHistory: false,
            stepOutSelectedIds: [],
            stepOutIsCopyService: false,

            stepPullServiceItemId: null,
            stepPullServiceItemName: '',
            stepPullBinTypeId: null,
            stepPullBinTypeName: '',
            stepPullWasteTypeId: null,
            stepPullWasteTypeName: '',
            stepPullWasteTypeIds: null,
            stepPullBinNumber: '',
            stepPullIsServiceOrHistory: false,
            stepPullSelectedIds: [],
            stepPullIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                isServiceOrHistory: false,

                stepExchangeOutBinWasteTypeId: null,
                stepExchangeOutWasteTypeItems: [],
                stepExchangeInBinWasteTypeId: null,
                stepExchangeInWasteTypeItems: [],
                isSameAsBinOut: false,

                stepOnTheSpotBinWasteTypeId: null,
                stepOnTheSpotWasteTypeItems: [],

                stepShiftBinWasteTypeId: null,
                stepShiftWasteTypeItems: [],

                stepPutBinWasteTypeId: null,
                stepPutWasteTypeItems: [],

                stepOutBinWasteTypeId: null,
                stepOutWasteTypeItems: [],

                stepPullBinWasteTypeId: null,
                stepPullWasteTypeItems: [],
            },
        });
    },
    onClearExchangeOUT(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            stepExchangeOutServiceItemId: null,
            stepExchangeOutServiceItemName: '',
            stepExchangeOutBinTypeId: null,
            stepExchangeOutBinTypeName: '',
            stepExchangeOutBinTypeItem: null,
            stepExchangeOutWasteTypeItem: null,
            stepExchangeOutWasteTypeId: null,
            stepExchangeOutWasteTypeName: '',
            stepExchangeOutWasteTypeIds: null,
            stepExchangeOutSelectedIds: [],
            stepExchangeOutIsServiceOrHistory: false,
            stepExchangeOutBinNumber: '',
            stepExchangeOutIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                stepExchangeOutBinWasteTypeId: null,
                stepExchangeOutWasteTypeItems: [],
                isSameAsBinOut: false,
            },
        });
    },
    onClearExchangeIN(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            pricings: [],

            nonBillableBinWeight: '0',

            stepExchangeInServiceItemId: null,
            stepExchangeInServiceItemName: '',
            stepExchangeInBinTypeId: null,
            stepExchangeInBinTypeName: '',
            stepExchangeInWasteTypeId: null,
            stepExchangeInWasteTypeName: '',
            stepExchangeInWasteTypeIds: null,
            stepExchangeInSelectedIds: [],
            stepExchangeInIsServiceOrHistory: false,
            stepExchangeInBinNumber: '',
            stepExchangeInIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                isServiceOrHistory: false,

                stepExchangeInBinWasteTypeId: null,
                stepExchangeInWasteTypeItems: [],
                isSameAsBinOut: false,
            },
        });
    },
    onClearOnTheSpot(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            pricings: [],

            nonBillableBinWeight: '0',

            stepOnTheSpotServiceItemId: null,
            stepOnTheSpotServiceItemName: '',
            stepOnTheSpotBinTypeId: null,
            stepOnTheSpotBinTypeName: '',
            stepOnTheSpotWasteTypeId: null,
            stepOnTheSpotWasteTypeName: '',
            stepOnTheSpotWasteTypeIds: null,
            stepOnTheSpotBinNumber: '',
            stepOnTheSpotIsServiceOrHistory: false,
            stepOnTheSpotSelectedIds: [],
            stepOnTheSpotIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                isServiceOrHistory: false,

                stepOnTheSpotBinWasteTypeId: null,
                stepOnTheSpotWasteTypeItems: [],
            },
        });
    },
    onClearShift(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            pricings: [],

            nonBillableBinWeight: '0',

            stepShiftServiceItemId: null,
            stepShiftServiceItemName: '',
            stepShiftBinTypeId: null,
            stepShiftBinTypeName: '',
            stepShiftWasteTypeId: null,
            stepShiftWasteTypeName: '',
            stepShiftWasteTypeIds: null,
            stepShiftIsServiceOrHistory: false,
            stepShiftSelectedIds: [],
            stepShiftBinNumber: '',
            stepShiftIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                isServiceOrHistory: false,

                stepShiftBinWasteTypeId: null,
                stepShiftWasteTypeItems: [],
            },
        });
    },
    onClearPut(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            pricings: [],

            nonBillableBinWeight: '0',

            stepPutServiceItemId: null,
            stepPutServiceItemName: '',
            stepPutBinTypeId: null,
            stepPutBinTypeName: '',
            stepPutWasteTypeId: null,
            stepPutWasteTypeName: '',
            stepPutWasteTypeIds: null,
            stepPutIsServiceOrHistory: false,
            stepPutSelectedIds: [],
            stepPutBinNumber: '',
            stepPutIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                isServiceOrHistory: false,

                stepPutBinWasteTypeId: null,
                stepPutWasteTypeItems: [],
            },
        });
    },
    onClearOut(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            pricings: [],

            nonBillableBinWeight: '0',

            stepOutServiceItemId: null,
            stepOutServiceItemName: '',
            stepOutBinTypeId: null,
            stepOutBinTypeName: '',
            stepOutWasteTypeId: null,
            stepOutWasteTypeName: '',
            stepOutWasteTypeIds: null,
            stepOutIsServiceOrHistory: false,
            stepOutSelectedIds: [],
            stepOutBinNumber: '',
            stepOutIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                isServiceOrHistory: false,

                stepOutBinWasteTypeId: null,
                stepOutWasteTypeItems: [],
            },
        });
    },
    onClearPull(formOptions) {
        let {
            setValues,
            values
        } = formOptions;

        setValues({
            ...values,

            pricings: [],

            nonBillableBinWeight: '0',

            stepPullServiceItemId: null,
            stepPullServiceItemName: '',
            stepPullBinTypeId: null,
            stepPullBinTypeName: '',
            stepPullWasteTypeId: null,
            stepPullWasteTypeName: '',
            stepPullWasteTypeIds: null,
            stepPullIsServiceOrHistory: false,
            stepPullSelectedIds: [],
            stepPullBinNumber: '',
            stepPullIsCopyService: false,

            customFormParams: {
                ...values.customFormParams,

                isServiceOrHistory: false,

                stepPullBinWasteTypeId: null,
                stepPullWasteTypeItems: [],
            },
        });
    },

    getJobStepId(values = null) {
        let jobStepId = null;

        if(values && values.customFormParams){
            if (values.customFormParams.jobTemplateType === JobStepType.Exchange) {
                jobStepId = values.customFormParams.jobStepId2;
            } else if (values.customFormParams.jobTemplateType === JobStepType.OnTheSpot) {
                jobStepId = values.customFormParams.jobStepId2;
            } else if (values.customFormParams.jobTemplateType === JobStepType.Shift) {
                jobStepId = values.customFormParams.jobStepId1;
            } else if (values.customFormParams.jobTemplateType === JobStepType.Put) {
                jobStepId = values.customFormParams.jobStepId1;
            } else if (values.customFormParams.jobTemplateType === JobStepType.Out) {
                jobStepId = values.customFormParams.jobStepId1;
            } else if (values.customFormParams.jobTemplateType === JobStepType.Pull) {
                jobStepId = values.customFormParams.jobStepId1;
            }
        }
        
        return jobStepId;
    },

    getServiceTagName(wasteTypeName = '', binTypeName = '') {
        let wasteType = wasteTypeName && wasteTypeName !== '' ? wasteTypeName : '';
        let binType = binTypeName && binTypeName !== '' ? binTypeName : '';

        return Utils.customConcat([binType, wasteType], " / ");
    },
    getServiceName(serviceTag = '', serviceTagName = '', binTypeId = null, wasteTypeId = null) {
        if (binTypeId && wasteTypeId) {
            return ((serviceTag != '') ? serviceTag + ' ' : '') + serviceTagName;
        } else {
            return serviceTagName;
        }
    },
    async getJobServiceName(values = null, type = InOrOut.NONE) {
        if (values) {
            let id = null;

            if (values.jobTemplateType === JobStepType.Exchange) {
                if (type == InOrOut.OUT) {
                    id = (values && values.stepExchangeOutServiceItemId) ? values.stepExchangeOutServiceItemId : null;
                } else if (type == InOrOut.IN) {
                    id = (values && values.stepExchangeInServiceItemId) ? values.stepExchangeInServiceItemId : null;
                }
            } else if (values.jobTemplateType === JobStepType.OnTheSpot) {
                id = (values && values.stepOnTheSpotServiceItemId) ? values.stepOnTheSpotServiceItemId : null;
            } else if (values.jobTemplateType === JobStepType.Shift) {
                id = (values && values.stepShiftServiceItemId) ? values.stepShiftServiceItemId : null;
            } else if (values.jobTemplateType === JobStepType.Put) {
                id = (values && values.stepPutServiceItemId) ? values.stepPutServiceItemId : null;
            } else if (values.jobTemplateType === JobStepType.Out) {
                id = (values && values.stepOutServiceItemId) ? values.stepOutServiceItemId : null;
            } else if (values.jobTemplateType === JobStepType.Pull) {
                id = (values && values.stepPullServiceItemId) ? values.stepPullServiceItemId : null;
            }

            if (id) {
                let serviceTag = '-';
                let isGlobalService = false;

                let err = <span className={'text-danger'}>No service name found</span>;

                await readApi(null, 'service-item/' + id).then((res) => {
                    let data = (res && res.data) ? res.data : null;
                    serviceTag = (data && data.serviceTag) ? data.serviceTag : err;
                    isGlobalService = (data && data.serviceTag) ? (data && data.customerId) ? false : true : false;
                }).catch(() => {
                    serviceTag = err;
                    isGlobalService = false;
                });

                return {
                    serviceTag: serviceTag,
                    isGlobalService: isGlobalService,
                };
            } else {
                return {
                    serviceTag: '-',
                    isGlobalService: false,
                };
            }
        } else {
            return {
                serviceTag: '-',
                isGlobalService: false,
            };
        }
    },
    callServiceItemDetailsApi: (id, token, callback = null) => {
        userCookieSettingApi.read(id, token).then((result) => {
            Utils.parseResult(result,
                (data) => {
                    if (callback) {
                        callback(data);
                    }
                },
                (error, type) => {
                    if (callback) {
                        callback(null);
                    }
                }
            );
        });
    },
    getJobServiceTagName: async (rowValues = null) => {
        if (rowValues.jobTemplateType === JobStepType.Exchange) {
            let stepExchangeOutServiceItem = await Utils.getJobServiceName(rowValues, InOrOut.OUT)
            let stepExchangeInServiceItem = await Utils.getJobServiceName(rowValues, InOrOut.IN)

            return {
                stepExchangeOutServiceItemName: stepExchangeOutServiceItem.serviceTag,
                stepExchangeOutServiceItemIsGlobal: stepExchangeOutServiceItem.isGlobalService,
                stepExchangeInServiceItemName: stepExchangeInServiceItem.serviceTag,
                stepExchangeInServiceItemIsGlobal: stepExchangeInServiceItem.isGlobalService,
                stepOnTheSpotServiceItemName: '',
                stepShiftServiceItemName: '',
                stepPutServiceItemName: '',
                stepPullServiceItemName: '',
            }
        } else if (rowValues.jobTemplateType === JobStepType.OnTheSpot) {
            let stepOnTheSpotServiceItem = await Utils.getJobServiceName(rowValues)

            return {
                stepExchangeOutServiceItemName: '',
                stepExchangeInServiceItemName: '',
                stepOnTheSpotServiceItemName: stepOnTheSpotServiceItem.serviceTag,
                stepOnTheSpotServiceItemIsGlobal: stepOnTheSpotServiceItem.isGlobalService,
                stepShiftServiceItemName: '',
                stepPutServiceItemName: '',
                stepPullServiceItemName: '',
            }
        } else if (rowValues.jobTemplateType === JobStepType.Shift) {
            let stepShiftServiceItem = await Utils.getJobServiceName(rowValues)

            return {
                stepExchangeOutServiceItemName: '',
                stepExchangeInServiceItemName: '',
                stepOnTheSpotServiceItemName: '',
                stepShiftServiceItemName: stepShiftServiceItem.serviceTag,
                stepShiftServiceItemIsGlobal: stepShiftServiceItem.isGlobalService,
                stepPutServiceItemName: '',
                stepPullServiceItemName: '',
            }
        } else if (rowValues.jobTemplateType === JobStepType.Put) {
            let stepPutServiceItem = await Utils.getJobServiceName(rowValues)

            return {
                stepExchangeOutServiceItemName: '',
                stepExchangeInServiceItemName: '',
                stepOnTheSpotServiceItemName: '',
                stepShiftServiceItemName: '',
                stepPutServiceItemName: stepPutServiceItem.serviceTag,
                stepPutServiceItemIsGlobal: stepPutServiceItem.isGlobalService,
                stepPullServiceItemName: '',
            }
        } else if (rowValues.jobTemplateType === JobStepType.Out) {
            let stepOutServiceItem = await Utils.getJobServiceName(rowValues)

            return {
                stepExchangeOutServiceItemName: '',
                stepExchangeInServiceItemName: '',
                stepOnTheSpotServiceItemName: '',
                stepShiftServiceItemName: '',
                stepPutServiceItemName: '',
                stepOutServiceItemName: stepOutServiceItem.serviceTag,
                stepOutServiceItemIsGlobal: stepOutServiceItem.isGlobalService,
                stepPullServiceItemName: '',
            }
        } else if (rowValues.jobTemplateType === JobStepType.Pull) {
            let stepPullServiceItem = await Utils.getJobServiceName(rowValues)

            return {
                stepExchangeOutServiceItemName: '',
                stepExchangeInServiceItemName: '',
                stepOnTheSpotServiceItemName: '',
                stepShiftServiceItemName: '',
                stepPutServiceItemName: '',
                stepPullServiceItemName: stepPullServiceItem.serviceTag,
                stepPullServiceItemIsGlobal: stepPullServiceItem.isGlobalService,
            }
        } else {
            return {
                stepExchangeOutServiceItemName: '',
                stepExchangeInServiceItemName: '',
                stepOnTheSpotServiceItemName: '',
                stepShiftServiceItemName: '',
                stepPutServiceItemName: '',
                stepPullServiceItemName: '',

                stepExchangeOutServiceItemIsGlobal: true,
                stepExchangeInServiceItemIsGlobal: true,
                stepOnTheSpotServiceItemIsGlobal: true,
                stepShiftServiceItemIsGlobal: true,
                stepPutServiceItemIsGlobal: true,
                stepPullServiceItemIsGlobal: true,
            }
        }
    },

    async weighChitCalc(formOptions = null) {
        try {
            let {
                setFieldValue,
                values,
            } = formOptions;

            let weighChitTareWeight = Utils.getCalculatedTareWeight(values, values.vehicleId);
            await setFieldValue('weighChitTareWeight', weighChitTareWeight);

            if (values.stepBinWeight && values.stepBinWeight != '') {
                let grossWeightVal = Utils.parseFloat(values.stepBinWeight);
                let tareWeightVal = Utils.parseFloat(weighChitTareWeight);
                let nonBillableBinWeightVal = Utils.parseFloat(values.nonBillableBinWeight);

                let nettWeightVal = grossWeightVal - tareWeightVal;
                await setFieldValue('weighChitNettWeight', nettWeightVal);

                let billableWeight = nettWeightVal - nonBillableBinWeightVal;
                await setFieldValue('weighChitBillableWeight', billableWeight);

                let newPricings = Utils.setQtyAtMainPricings(values.pricings, billableWeight);
                await setFieldValue('pricings', newPricings);
            }
        } catch (e) { }
    },

    isMainPricings(pricings = [], item = null) {
        let isMain = false;

        if (pricings && pricings.length > 0) {
            if (item) {
                let standardServiceIndex = pricings.findIndex(x => Utils.isStandardService(x));
                if (standardServiceIndex > -1) {
                    if (pricings[standardServiceIndex] && pricings[standardServiceIndex].bundleServiceTag) {
                        if ((item.useBinWeightQuantity == true) && (item.bundleServiceTag == pricings[standardServiceIndex].bundleServiceTag)) {
                            isMain = true;
                        }
                    }
                }
            }
        }

        return isMain;
    },
    removeDuplicatedStandardPricings(pricings = []) {
        if (pricings && pricings.length > 0) {
            let standardItems = pricings.filter(x => Utils.isStandardService(x));
            let otherItems = pricings.filter(x => !Utils.isStandardService(x));

            let arr = [];
            if (otherItems && otherItems.length > 0) {
                arr = otherItems;
            }

            if (standardItems && standardItems.length > 0) {
                arr.unshift(standardItems[0]);
            }

            return arr;
        } else {
            return []
        }
    },
    setQtyAtMainPricings(pricings = [], billableWeight) {
        if (pricings && pricings.length > 0) {
            let standardServiceIndex = pricings.findIndex(x => Utils.isStandardService(x));
            if (standardServiceIndex > -1) {
                if (pricings[standardServiceIndex] && pricings[standardServiceIndex].bundleServiceTag) {
                    let mainAdditionalIndex = pricings.findIndex(x => (x.useBinWeightQuantity == true) && (x.bundleServiceTag == pricings[standardServiceIndex].bundleServiceTag));
                    if (mainAdditionalIndex > -1) {
                        // Need Review
                        //if (billableWeight && billableWeight != '' && billableWeight != '0') {
                        let quantity = Utils.convertUom(billableWeight, Utils.getUOM(), Utils.getBillingUOM());
                        pricings[mainAdditionalIndex].quantity = quantity;
                        pricings[mainAdditionalIndex].isMain = true;
                        //}
                    }
                }
            }

            return pricings;
        } else {
            return []
        }
    },
    setQtyAtMainPricingsWithoutConversion(pricings = [], billableWeight) {
        if (pricings && pricings.length > 0) {
            let standardServiceIndex = pricings.findIndex(x => Utils.isStandardService(x));
            if (standardServiceIndex > -1) {
                if (pricings[standardServiceIndex] && pricings[standardServiceIndex].bundleServiceTag) {
                    let mainAdditionalIndex = pricings.findIndex(x => (x.useBinWeightQuantity == true) && (x.bundleServiceTag == pricings[standardServiceIndex].bundleServiceTag));
                    if (mainAdditionalIndex > -1) {
                        pricings[mainAdditionalIndex].quantity = billableWeight;
                        pricings[mainAdditionalIndex].isMain = true;
                    }
                }
            }

            return pricings;
        } else {
            return []
        }
    },
    setNonBillableAtMainPricings(pricings = [], qty) {
        if (pricings && pricings.length > 0) {
            let standardServiceIndex = pricings.findIndex(x => Utils.isStandardService(x));
            if (standardServiceIndex > -1) {
                if (pricings[standardServiceIndex] && pricings[standardServiceIndex].bundleServiceTag) {
                    let mainAdditionalIndex = pricings.findIndex(x => (x.useBinWeightQuantity == true) && (x.bundleServiceTag == pricings[standardServiceIndex].bundleServiceTag));
                    if (mainAdditionalIndex > -1) {
                        pricings[mainAdditionalIndex].qty = qty;
                    }
                }
            }

            return pricings;
        } else {
            return []
        }
    },
    setWeightChitAtPricings(newPricings = [], prices1 = [], qty) {
        let serviceItemId = null;
        let jobStepId = null;
        let ticketNumber = '';
        let grossWeight = '';
        let tareWeight = '';
        let nettWeight = '';
        let billableWeight = '';
        // let nonBillableBinWeight = '';
        let remarks = '';
        let weighBy = '';
        let date = null;
        let time = null;

        if (prices1 && prices1.length > 0) {
            let indx = prices1.findIndex(x => !Utils.isStandardService(x) && x.useBinWeightQuantity);
            if (indx > -1) {
                serviceItemId = (prices1[indx] && prices1[indx].serviceItemId) ? prices1[indx].serviceItemId : null;
                jobStepId = (prices1[indx] && prices1[indx].jobStepId) ? prices1[indx].jobStepId : null;
                ticketNumber = (prices1[indx] && prices1[indx].ticketNumber) ? prices1[indx].ticketNumber : '';
                grossWeight = (prices1[indx] && prices1[indx].grossWeight) ? prices1[indx].grossWeight : '';
                tareWeight = (prices1[indx] && prices1[indx].tareWeight) ? prices1[indx].tareWeight : '';
                nettWeight = (prices1[indx] && prices1[indx].nettWeight) ? prices1[indx].nettWeight : '';
                billableWeight = (prices1[indx] && prices1[indx].billableWeight) ? prices1[indx].billableWeight : '';
                // nonBillableBinWeight = (prices1[indx] && prices1[indx].nonBillableBinWeight) ? prices1[indx].nonBillableBinWeight : '';
                remarks = (prices1[indx] && prices1[indx].remarks) ? prices1[indx].remarks : '';
                weighBy = (prices1[indx] && prices1[indx].weighBy) ? prices1[indx].weighBy : '';
                date = (prices1[indx] && prices1[indx].date) ? prices1[indx].date : null;
                time = (prices1[indx] && prices1[indx].time) ? prices1[indx].time : null;
            }
        }

        let index = -1;
        if (newPricings && newPricings.length > 0) {
            let indx1 = newPricings.findIndex(x => x.serviceItemId == serviceItemId);
            if (indx1 > -1) {
                index = indx1;
            } else {
                let indx2 = newPricings.findIndex(x => x.useBinWeightQuantity);
                index = indx2;
            }
        }

        if (index > -1) {
            newPricings[index]['jobStepId'] = jobStepId;
            newPricings[index]['ticketNumber'] = ticketNumber;
            newPricings[index]['grossWeight'] = grossWeight;
            newPricings[index]['tareWeight'] = tareWeight;
            newPricings[index]['nettWeight'] = nettWeight;
            newPricings[index]['billableWeight'] = billableWeight;
            newPricings[index]['nonBillableBinWeight'] = qty;
            newPricings[index]['remarks'] = remarks;
            newPricings[index]['weighBy'] = weighBy;
            newPricings[index]['date'] = date;
            newPricings[index]['time'] = time;
            newPricings[index]['quantity'] = '';

            let nettWeightVal = Utils.parseFloat(newPricings[index]['nettWeight']);
            if (nettWeightVal != '' && qty != '') {
                let nonBillableBinWeightVal = Utils.parseFloat(qty);
                let billableWeight = nettWeightVal - nonBillableBinWeightVal;

                newPricings[index]['billableWeight'] = billableWeight.toFixed(3);
                newPricings[index]['quantity'] = billableWeight.toFixed(2);
                newPricings[index]['isMain'] = true;
            }
        }

        return newPricings;
    },
    setStepIdPricings(newPricings = [], prices1 = []) {
        if (newPricings && newPricings.length > 0) {
            newPricings.forEach((item, i) => {
                let items = (item.serviceItemId) ? prices1.filter(x => x.serviceItemId == item.serviceItemId) : prices1.filter(x => x.serviceTag == item.serviceTag);
                if (items && items.length > 0) {
                    item['jobStepId'] = items[0].jobStepId;
                    item['jobPricingId'] = items[0].jobPricingId;
                }
            });
        }

        return newPricings;
    },
    getNonBillableWeight(bundleServices = []) {
        let nonBillableWeight = null;

        if (bundleServices && bundleServices.length > 0) {
            let useBinWeightQuantityIndex = bundleServices.findIndex(x => x.useBinWeightQuantity);
            if (useBinWeightQuantityIndex > -1) {
                nonBillableWeight = bundleServices[useBinWeightQuantityIndex].nonBillableWeight;
            }
        }

        return nonBillableWeight;
    },
    setOnlyMainPricings(pricings = []) {
        if (pricings && pricings.length > 0) {
            let standardServiceIndex = pricings.findIndex(x => Utils.isStandardService(x));
            if (standardServiceIndex > -1) {
                let bundleServiceTag = (pricings[standardServiceIndex].bundleServiceTag && pricings[standardServiceIndex].bundleServiceTag != '') ? pricings[standardServiceIndex].bundleServiceTag : '';
                return pricings.filter(x => x.bundleServiceTag == bundleServiceTag);
            }
        }

        return pricings;
    },

    getNewPriceFromStandardServices(serviceCustomerSiteDetails = null, pricing = null) {
        let price = (pricing && pricing.price) ? pricing.price : 0;

        if (pricing && serviceCustomerSiteDetails && serviceCustomerSiteDetails.charges && serviceCustomerSiteDetails.charges.length > 0) {
            let standardServices = serviceCustomerSiteDetails.charges.filter(x => x.serviceTag == pricing.serviceTag);
            if (standardServices && standardServices.length > 0) {
                price = standardServices[0].price;
            }
        }
        // Need Review
        else if (pricing && serviceCustomerSiteDetails && serviceCustomerSiteDetails.length > 0) {
            let standardServices = serviceCustomerSiteDetails.filter(x => (pricing.serviceItemId && x.serviceItemId == pricing.serviceItemId) || (!pricing.serviceItemId && x.serviceTag == pricing.serviceTag));
            if (standardServices && standardServices.length > 0) {
                price = standardServices[0].price;
            }
        }

        return price;
    },
    getNewPriceFromBundledServices(serviceCustomerSiteDetails = null, pricing = null) {
        let price = (pricing && pricing.price) ? pricing.price : 0;

        if (pricing && serviceCustomerSiteDetails && serviceCustomerSiteDetails.charges && serviceCustomerSiteDetails.charges.length > 0) {
            let standardServices = serviceCustomerSiteDetails.charges.filter(x => x.serviceTag == pricing.bundleServiceTag);
            if (standardServices && standardServices.length > 0 && standardServices[0] && standardServices[0].additionalCharges && standardServices[0].additionalCharges.length > 0) {
                let additionalServices = standardServices[0].additionalCharges.filter(x => x.serviceTag == pricing.serviceTag);
                if (additionalServices && additionalServices.length > 0) {
                    price = additionalServices[0].price;
                }
            }
        }
        // Need Review
        else if (pricing && serviceCustomerSiteDetails && serviceCustomerSiteDetails.length > 0) {
            let standardServices = serviceCustomerSiteDetails.filter(x => (pricing.serviceItemId && x.serviceItemId == pricing.serviceItemId) || (!pricing.serviceItemId && x.serviceTag == pricing.bundleServiceTag));
            if (standardServices && standardServices.length > 0 && standardServices[0] && standardServices[0].additionalCharges && standardServices[0].additionalCharges.length > 0) {
                let additionalServices = standardServices[0].additionalCharges.filter(x => x.serviceTag == pricing.serviceTag);
                if (additionalServices && additionalServices.length > 0) {
                    price = additionalServices[0].price;
                }
            }
        }

        return price;
    },
    getNewPriceFromAdditionalServices(serviceCustomerSiteDetails = null, pricing = null) {
        let price = (pricing && pricing.price) ? pricing.price : 0;

        if (pricing && serviceCustomerSiteDetails && serviceCustomerSiteDetails.additionalCharges && serviceCustomerSiteDetails.additionalCharges.length > 0) {
            let additionalServices = serviceCustomerSiteDetails.additionalCharges.filter(x => x.serviceTag == pricing.serviceTag);
            if (additionalServices && additionalServices.length > 0) {
                price = additionalServices[0].price;
            }
        }
        // Need Review 
        else if (pricing && serviceCustomerSiteDetails && serviceCustomerSiteDetails.additionalCharges.length > 0) {
            let additionalServices = serviceCustomerSiteDetails.filter(x => (pricing.serviceItemId && x.serviceItemId == pricing.serviceItemId) || (!pricing.serviceItemId && x.serviceTag == pricing.serviceTag));
            if (additionalServices && additionalServices.length > 0) {
                price = additionalServices[0].price;
            }
        }

        return price;
    },

    getIsCopyServiceItem(values, serviceItemId) {
        let isCopyServiceItem = false;
        
        if (values.customFormParams.jobTemplateType === JobStepType.Exchange) {
            isCopyServiceItem = values.stepExchangeInIsCopyService;
        } else if (values.customFormParams.jobTemplateType === JobStepType.OnTheSpot) {
            isCopyServiceItem = values.stepOnTheSpotIsCopyService;
        } else if (values.customFormParams.jobTemplateType === JobStepType.Shift) {
            isCopyServiceItem = values.stepShiftIsCopyService;
        } else if (values.customFormParams.jobTemplateType === JobStepType.Put) {
            isCopyServiceItem = values.stepPutIsCopyService;
        } else if (values.customFormParams.jobTemplateType === JobStepType.Out) {
            isCopyServiceItem = values.stepOutIsCopyService;
        } else if (values.customFormParams.jobTemplateType === JobStepType.Pull) {
            isCopyServiceItem = values.stepPullIsCopyService;
        }

        return isCopyServiceItem;
    },

    weekDayNumb(formOptions) {
        let {
            values,
        } = formOptions;

        return (values && values.jobDate) ? moment(values.jobDate).isoWeekday() : 7;
    },
    isWeekend(weekDayNumb) {
        return ((weekDayNumb === 6) || (weekDayNumb === 7)) ? true : false;
    },

    onChangeSameAsBinOutToFalse(formOptions, stepExchangeInBinTypeId, stepExchangeInWasteTypeIds, callback = null) {
        let {
            setFieldValue,
            values,
        } = formOptions;

        let bId = (stepExchangeInBinTypeId) ? stepExchangeInBinTypeId : null;
        let wId = (stepExchangeInWasteTypeIds && stepExchangeInWasteTypeIds.length > 0) ? stepExchangeInWasteTypeIds[0] : null;

        let pricings = (values.pricings && values.pricings.length > 0) ? values.pricings : [];

        if (pricings && pricings.length > 0) {
            let mainIndex = pricings.findIndex(x => (x.binTypeId == bId) && (x.wasteTypeId == wId));
            if (mainIndex > -1) {
                if (pricings[mainIndex].bundleServiceTag != '') {
                    let newPricings = pricings.filter(x => x.bundleServiceTag != pricings[mainIndex].bundleServiceTag);
                    setFieldValue('pricings', newPricings);
                }
            }
        }
    },
    checkIsCopyServiceItem(values, item) {
        let isCopyServiceItem = false;

        if(item && item.item && item.item.customerId){
            let customerId = (values && values.customerId) ? values.customerId : null;

            if(customerId != item.item.customerId){
                isCopyServiceItem = true;
            }
        }
        
        return isCopyServiceItem;
    },
    async onChangeBinTypeJobForm(formOptions, items, selectedBinWaste, callback = null) {
        if (formOptions) {
            let {
                setValues,
                values,
            } = formOptions;

            let chargeCategoryItems = values.customFormParams.chargeCategoryItems;
            let pricings = (values.pricings && values.pricings.length > 0) ? values.pricings : [];


            let jobStepId = null;


            if (pricings && pricings.length > 0) {
                if (items && items.length > 0) {
                    // let addPricings = pricings.filter(x => x.bundleServiceTag == null && x.serviceType == ServiceType.AdditionalService);
                    // let addManualPricings = pricings.filter(x => x.serviceType == ServiceType.AdditionalService && (x.bundleServiceTag == null || x.bundleServiceTag == '' || x.bundleServiceTag == undefined));
                    
                    pricings = [];

                    items.forEach((item, i) => {
                        let newItem = (item.item) ? item.item : null;
                        let bundleServiceTag = (newItem && newItem.serviceTag) ? newItem.serviceTag : '';

                        // if(pricings.findIndex(x => x.bundleServiceTag == bundleServiceTag) === -1){
                        let serviceItemId = (newItem && newItem.serviceItemId) ? newItem.serviceItemId : null;

                        let binTypeId = (item.binTypeId) ? item.binTypeId : null;
                        let wasteTypeId = (item.wasteTypeId) ? item.wasteTypeId : null;

                        let price = (newItem && newItem.price) ? newItem.price : '';
                        let billingType = (newItem) ? newItem.billingType : null;
                        let chargeCategoryId = (newItem && newItem.chargeCategoryId) ? newItem.chargeCategoryId : null;

                        let useBinWeightQuantity1 = (newItem) ? newItem.useBinWeightQuantity : false;
                        let quantity1 = (newItem && newItem.quantity) ? newItem.quantity : useBinWeightQuantity1 ? '' : 1;

                        let chargeCategoryObj = (newItem && newItem.chargeCategory) ? newItem.chargeCategory : null;
                        let chargeCategoryName = (chargeCategoryObj && chargeCategoryObj.chargeCategoryName) ? chargeCategoryObj.chargeCategoryName : '';
                        let chargeCategoryItem = null;
                        if (chargeCategoryObj) {
                            chargeCategoryItem = {
                                value: chargeCategoryId,
                                title: chargeCategoryName,
                                item: chargeCategoryObj,
                            }

                            chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem)
                        }

                        let standardServiceItem = {
                            serviceType: ServiceType.StandardService,
                            binTypeId: binTypeId,
                            wasteTypeId: wasteTypeId,
                            serviceTag: bundleServiceTag,
                            bundleServiceTag: bundleServiceTag,
                            description: bundleServiceTag,
                            price: price,
                            quantity: quantity1,
                            billingType: billingType,
                            chargeCategoryId: chargeCategoryId,
                            chargeCategoryName: chargeCategoryName,
                            chargeCategory: chargeCategoryItem,
                            jobStepId: jobStepId,
                            serviceItemId: serviceItemId,
                            useBinWeightQuantity: (item) ? useBinWeightQuantity1 : false,
                            isTaxInclusive: (item) ? newItem.isTaxInclusive : false,
                            invoiceTaxRate: (item) ? newItem.invoiceTaxRate : null,
                            purchaseTaxRate: (item) ? newItem.purchaseTaxRate : null,
                            sortOrder: i,
                        };

                        if (item.additionalCharges && item.additionalCharges.length > 0) {
                            for (let i = 0; i < item.additionalCharges.length; i++) {
                                let additionalItem = item.additionalCharges[i];

                                let serviceTag = additionalItem.serviceTag;

                                let subServiceItemId = (additionalItem && additionalItem.serviceItemId) ? additionalItem.serviceItemId : null;

                                let price = (additionalItem && additionalItem.price) ? additionalItem.price : '0';
                                let billingType = (additionalItem) ? additionalItem.billingType : null;
                                let chargeCategoryId = (additionalItem && additionalItem.chargeCategoryId) ? additionalItem.chargeCategoryId : null;
                                let useBinWeightQuantity = (additionalItem) ? additionalItem.useBinWeightQuantity : false;
                                let quantity = (additionalItem && additionalItem.quantity) ? additionalItem.quantity : useBinWeightQuantity ? '' : 1;

                                let chargeCategoryObj = (additionalItem && additionalItem.chargeCategory) ? additionalItem.chargeCategory : null;
                                let chargeCategoryName = (chargeCategoryObj && chargeCategoryObj.chargeCategoryName) ? chargeCategoryObj.chargeCategoryName : '';
                                let chargeCategoryItem = null;
                                if (chargeCategoryObj) {
                                    chargeCategoryItem = {
                                        value: chargeCategoryId,
                                        title: chargeCategoryName,
                                        item: chargeCategoryObj,
                                    }

                                    chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem)
                                }

                                let additionalServiceItem = {
                                    serviceType: ServiceType.AdditionalService,
                                    binTypeId: null,
                                    wasteTypeId: null,
                                    serviceTag: serviceTag,
                                    bundleServiceTag: bundleServiceTag,
                                    description: serviceTag,
                                    price: price,
                                    quantity: quantity,
                                    billingType: billingType,
                                    chargeCategoryId: chargeCategoryId,
                                    chargeCategoryName: chargeCategoryName,
                                    chargeCategory: chargeCategoryItem,
                                    jobStepId: jobStepId,
                                    serviceItemId: subServiceItemId,
                                    useBinWeightQuantity: useBinWeightQuantity,
                                    isTaxInclusive: additionalItem ? additionalItem.isTaxInclusive : false,
                                    invoiceTaxRate: additionalItem ? additionalItem.invoiceTaxRate : null,
                                    purchaseTaxRate: additionalItem ? additionalItem.purchaseTaxRate : null,
                                };

                                pricings = Utils.addToArrayWithField(pricings, additionalServiceItem.serviceTag, 'serviceItemId', additionalServiceItem)
                            }
                        }

                        pricings = Utils.unshiftToArrayWithField(pricings, standardServiceItem.serviceTag, 'serviceTag', standardServiceItem)
                        // }

                    });

                    // if (addPricings && addPricings.length > 0) {
                    //     for (let i = 0; i < addPricings.length; i++) {
                    //         pricings.push(addPricings[i]);
                    //     }
                    // }

                    // if (addManualPricings && addManualPricings.length > 0) {
                    //     for (let i = 0; i < addManualPricings.length; i++) {
                    //         pricings.push(addManualPricings[i]);
                    //     }
                    // }
                    
                    pricings = Utils.addManualItems(pricings, values.pricings);

                    // pricings = pricings.filter(x => items.map(y => y.serviceTag).includes(x.bundleServiceTag));

                } else {
                    pricings = [];
                }
            } else {
                if (items && items.length > 0) {
                    items.forEach((item, i) => {
                        let newItem = (item.item) ? item.item : null;

                        let serviceItemId = (newItem && newItem.serviceItemId) ? newItem.serviceItemId : null;

                        let binTypeId = (item.binTypeId) ? item.binTypeId : null;
                        let wasteTypeId = (item.wasteTypeId) ? item.wasteTypeId : null;

                        let bundleServiceTag = (newItem && newItem.serviceTag) ? newItem.serviceTag : '';
                        let price = (newItem && newItem.price) ? newItem.price : '';
                        let billingType = (newItem) ? newItem.billingType : null;
                        let chargeCategoryId = (newItem && newItem.chargeCategoryId) ? newItem.chargeCategoryId : null;
                        
                        let useBinWeightQuantity1 = (newItem) ? newItem.useBinWeightQuantity : false;
                        let quantity1 = (newItem && newItem.quantity) ? newItem.quantity : useBinWeightQuantity1 ? '' : 1;

                        let chargeCategoryObj = (newItem && newItem.chargeCategory) ? newItem.chargeCategory : null;
                        let chargeCategoryName = (chargeCategoryObj && chargeCategoryObj.chargeCategoryName) ? chargeCategoryObj.chargeCategoryName : '';
                        let chargeCategoryItem = null;
                        if (chargeCategoryObj) {
                            chargeCategoryItem = {
                                value: chargeCategoryId,
                                title: chargeCategoryName,
                                item: chargeCategoryObj,
                            }

                            chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem)
                        }

                        let standardServiceItem = {
                            serviceType: ServiceType.StandardService,
                            binTypeId: binTypeId,
                            wasteTypeId: wasteTypeId,
                            serviceTag: bundleServiceTag,
                            bundleServiceTag: bundleServiceTag,
                            description: bundleServiceTag,
                            price: price,
                            quantity: quantity1,
                            billingType: billingType,
                            chargeCategoryId: chargeCategoryId,
                            chargeCategoryName: chargeCategoryName,
                            chargeCategory: chargeCategoryItem,
                            jobStepId: jobStepId,
                            serviceItemId: serviceItemId,
                            useBinWeightQuantity: (newItem) ? useBinWeightQuantity1 : false,
                            isTaxInclusive: (newItem) ? newItem.isTaxInclusive : false,
                            invoiceTaxRate: (newItem) ? newItem.invoiceTaxRate : null,
                            purchaseTaxRate: (newItem) ? newItem.purchaseTaxRate : null,
                            sortOrder: i,
                        };

                        if (item.additionalCharges && item.additionalCharges.length > 0) {
                            for (let i = 0; i < item.additionalCharges.length; i++) {
                                let additionalItem = item.additionalCharges[i];

                                let subServiceItemId = (additionalItem && additionalItem.serviceItemId) ? additionalItem.serviceItemId : null;

                                let serviceTag = additionalItem.serviceTag;

                                let price = (additionalItem && additionalItem.price) ? additionalItem.price : '0';
                                let billingType = (additionalItem) ? additionalItem.billingType : null;
                                let chargeCategoryId = (additionalItem && additionalItem.chargeCategoryId) ? additionalItem.chargeCategoryId : null;
                                let useBinWeightQuantity = (additionalItem) ? additionalItem.useBinWeightQuantity : false;
                                let quantity = (additionalItem && additionalItem.quantity) ? additionalItem.quantity : useBinWeightQuantity ? '' : 1;

                                let chargeCategoryObj = (additionalItem && additionalItem.chargeCategory) ? additionalItem.chargeCategory : null;
                                let chargeCategoryName = (chargeCategoryObj && chargeCategoryObj.chargeCategoryName) ? chargeCategoryObj.chargeCategoryName : '';
                                let chargeCategoryItem = null;
                                if (chargeCategoryObj) {
                                    chargeCategoryItem = {
                                        value: chargeCategoryId,
                                        title: chargeCategoryName,
                                        item: chargeCategoryObj,
                                    }

                                    chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem)
                                }

                                let additionalServiceItem = {
                                    serviceType: ServiceType.AdditionalService,
                                    binTypeId: null,
                                    wasteTypeId: null,
                                    serviceTag: serviceTag,
                                    bundleServiceTag: bundleServiceTag,
                                    description: serviceTag,
                                    price: price,
                                    quantity: quantity,
                                    billingType: billingType,
                                    chargeCategoryId: chargeCategoryId,
                                    chargeCategoryName: chargeCategoryName,
                                    chargeCategory: chargeCategoryItem,
                                    jobStepId: jobStepId,
                                    serviceItemId: subServiceItemId,
                                    useBinWeightQuantity: useBinWeightQuantity,
                                    serviceAdditionalChargeTemplateId: (additionalItem) ? additionalItem.serviceAdditionalChargeTemplateId : null,
                                    isTaxInclusive: (additionalItem) ? additionalItem.isTaxInclusive : false,
                                    invoiceTaxRate: (additionalItem) ? additionalItem.invoiceTaxRate : null,
                                    purchaseTaxRate: (additionalItem) ? additionalItem.purchaseTaxRate : null,
                                };

                                pricings = Utils.addToArrayWithField(pricings, additionalServiceItem.serviceTag, 'serviceItemId', additionalServiceItem)
                            }
                        }

                        pricings = Utils.unshiftToArrayWithField(pricings, standardServiceItem.serviceTag, 'serviceTag', standardServiceItem)
                    });
                }
            }

            let weighChitTareWeight = Utils.getCalculatedTareWeight(values, values.vehicleId, selectedBinWaste);
            let weighChitNettWeight = Utils.parseFloat(values.weighChitNettWeight);
            let weighChitBillableWeight = Utils.parseFloat(values.weighChitBillableWeight);

            try {
                if (values.stepBinWeight && values.stepBinWeight != '') {
                    let grossWeightVal = Utils.parseFloat(values.stepBinWeight);
                    let tareWeightVal = Utils.parseFloat(weighChitTareWeight);
                    let nonBillableBinWeightVal = Utils.parseFloat(values.nonBillableBinWeight);
    
                    weighChitNettWeight = grossWeightVal - tareWeightVal;
                    weighChitBillableWeight = weighChitNettWeight - nonBillableBinWeightVal;
                }
            } catch (e) { }

            let newPricings = Utils.setQtyAtMainPricings(pricings, weighChitBillableWeight);

            
            let standardServices = newPricings.filter(x => (x.serviceType == ServiceType.StandardService) || (x.serviceType == ServiceType.BundleService));
            standardServices = standardServices.sort((a, b) => a.sortOrder - b.sortOrder);
            let additionalServices = newPricings.filter(x => x.serviceType == ServiceType.AdditionalService);
            additionalServices = additionalServices.sort((a, b) => a.sortOrder - b.sortOrder);

            newPricings = [...standardServices, ...additionalServices]
            // newPricings = newPricings.sort((a, b) => a.sortOrder - b.sortOrder);


            let newValues = {
                ...values,

                pricings: newPricings,
                weighChitTareWeight: weighChitTareWeight,
                weighChitNettWeight: weighChitNettWeight,
                weighChitBillableWeight: weighChitBillableWeight,

                customFormParams: {
                    ...values.customFormParams,

                    selectedBinWaste: selectedBinWaste,
                    chargeCategoryItems: chargeCategoryItems,
                },
            };
            
            setValues(newValues);

            if (callback) {
                callback(pricings);
            }

        }
    },

    getCalculatedTareWeight: (values, vehicleId, selectedBinWaste = null) => {
        let weighChitTareWeight = 0;
        let unladenBinWeightVehicle = 0;
        let unladenBinWeightBinType = 0;
        
        if(values){
            if(values.customFormParams && values.customFormParams.vehicleItems && values.customFormParams.vehicleItems.length > 0){
                let vehicleItem = values.customFormParams.vehicleItems.find(x => x.value == vehicleId);
                unladenBinWeightVehicle = (vehicleItem && vehicleItem.item && Utils.isNumber(vehicleItem.item.unladednBinWeight)) ? parseFloat(vehicleItem.item.unladednBinWeight) : 0;
            }
            
            let selectedBinWasteItem = (selectedBinWaste) ? selectedBinWaste : values.customFormParams.selectedBinWaste;
            if(selectedBinWasteItem && selectedBinWasteItem.length > 0){
                let binTypeItem = (selectedBinWasteItem[0] && selectedBinWasteItem[0].item) ? selectedBinWasteItem[0].item : null;
                unladenBinWeightBinType = (binTypeItem && binTypeItem.binType &&  Utils.isNumber(binTypeItem.binType.unladednBinWeight)) ? parseFloat(binTypeItem.binType.unladednBinWeight) : 0;
            }
        }
        
        weighChitTareWeight = Utils.calcTareWeight(unladenBinWeightBinType, unladenBinWeightVehicle);
        
        // console.group("getCalculatedTareWeight");
        // console.info('⚡ | Vehicle | unladenBinWeight:', unladenBinWeightVehicle);
        // console.info('⚡ | BinType | unladenBinWeight:', unladenBinWeightBinType);
        // console.info('⚡ | weighChitTareWeight:', weighChitTareWeight);
        // console.groupEnd();

        return weighChitTareWeight;
    },
    calcTareWeight: (weightBinType, weightVehicle) => {
        let unladenBinWeightBinType = Utils.isNumber(weightBinType) ? parseFloat(weightBinType) : 0;
        let unladenBinWeightVehicle = Utils.isNumber(weightVehicle) ? parseFloat(weightVehicle) : 0;
        return (unladenBinWeightBinType + unladenBinWeightVehicle).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3);
    },

    isThereWasteRate(checked = false, items = [], values = [], val = null) {
        let errorMsg = 'Bin type and Waste type combination is not exist in customer service';

        if (checked && (items && items.length > 0)) {
            let selectedItems = items.filter(item => values.includes(item.value))
            if (selectedItems && selectedItems.length > 0) {
                if (!selectedItems.some(item => item.haveRate == true)) {
                    if (val) {
                        let selectedObj = selectedItems.filter(item => item.value == val);
                        if (selectedObj && selectedObj.length > 0) {
                            if (!selectedObj[0].haveRate) {
                                Utils.toast(errorMsg, 'warning', null, 3000);
                                return false;
                            }
                        }
                    } else {
                        Utils.toast(errorMsg, 'warning', null, 3000);
                        return false;
                    }
                }
            }
        }

        return true;
    },

    isSameAsBinOut(values) {
        let isSameAsBinOut = false;

        let stepExchangeOutBinTypeId = (values && values.stepExchangeOutBinTypeId) ? values.stepExchangeOutBinTypeId : null;
        // let stepExchangeOutBinNumber = (values && values.stepExchangeOutBinNumber) ? values.stepExchangeOutBinNumber : '';
        let stepExchangeOutWasteTypeId = (values && values.stepExchangeOutWasteTypeId) ? values.stepExchangeOutWasteTypeId : null;

        let stepExchangeInBinTypeId = (values && values.stepExchangeInBinTypeId) ? values.stepExchangeInBinTypeId : null;
        // let stepExchangeInBinNumber = (values && values.stepExchangeInBinNumber) ? values.stepExchangeInBinNumber : '';
        let stepExchangeInWasteTypeIds = (values && values.stepExchangeInWasteTypeIds && values.stepExchangeInWasteTypeIds.length > 0) ? values.stepExchangeInWasteTypeIds : [];

        if (
            stepExchangeOutBinTypeId != null &&
            stepExchangeInBinTypeId != null &&
            (values.stepExchangeOutBinTypeId == stepExchangeInBinTypeId) &&
            // (stepExchangeOutBinNumber == stepExchangeInBinNumber) && 
            (stepExchangeInWasteTypeIds.findIndex(x => x == stepExchangeOutWasteTypeId) !== -1)
        ) {
            isSameAsBinOut = true;
        }

        return isSameAsBinOut;
    },

    isBinWasteSelected(formOptions) {
        if (formOptions) {
            let {
                values,
            } = formOptions;

            if (values.jobTemplateType === JobStepType.Exchange) {
                return (values.stepExchangeOutBinTypeId && values.stepExchangeInBinTypeId) ? true : false;
            } else if (values.jobTemplateType === JobStepType.OnTheSpot) {
                return (values.stepOnTheSpotBinTypeId) ? true : false;
            } else if (values.jobTemplateType === JobStepType.Shift) {
                return (values.stepShiftBinTypeId) ? true : false;
            } else if (values.jobTemplateType === JobStepType.Put) {
                return (values.stepPutBinTypeId) ? true : false;
            } else if (values.jobTemplateType === JobStepType.Out) {
                return (values.stepOutBinTypeId) ? true : false;
            } else if (values.jobTemplateType === JobStepType.Pull) {
                return (values.stepPullBinTypeId) ? true : false;
            }
        } else {
            return false
        }
    },

    checkBillingStatus(isBillable = false, isInvoiced = false, isPaid = false, quickbookInvoiceId = null, xeroInvoiceId = null, quickbookPurchaseOrderId = null) {
        // Non-Billable = isBillable=false, isInvoiced=false
        // Unbilled = isBillable=true, isInvoiced=false
        // MarkAsBilled = isBillable=true, isInvoiced=true

        // Billed = isBillable=true, isInvoiced=true
        // Billed will have either quickbookInvoiceId or xeroInvoiceId or quickbookPurchaseOrderId
        if (isPaid) {
            return BillingStatus.MarkAsPaid;
        } else {
            if (isBillable === false && isInvoiced === false) {
                return BillingStatus.NonBillable;
            } else if (isBillable === true && isInvoiced === false) {
                return BillingStatus.Unbilled;
            } else if (isBillable === true && isInvoiced === true) {
                if (quickbookInvoiceId || xeroInvoiceId || quickbookPurchaseOrderId) {
                    return BillingStatus.Billed;
                } else {
                    return BillingStatus.MarkAsBilled;
                }
            } else {
                return BillingStatus.NonBillable;
            }
        }
    },
    billingStatusDisabledItems(isBillable = false, isInvoiced = false, isPaid = false, quickbookInvoiceId = null, xeroInvoiceId = null, quickbookPurchaseOrderId = null) {
        let billingStatus = Utils.checkBillingStatus(isBillable, isInvoiced, quickbookInvoiceId, xeroInvoiceId, quickbookPurchaseOrderId);

        if (billingStatus == BillingStatus.NonBillable) {
            return [BillingStatus.NonBillable, BillingStatus.Billed, BillingStatus.MarkAsPaid]

        } else if (billingStatus == BillingStatus.Unbilled) {
            return [BillingStatus.Unbilled, BillingStatus.Billed, BillingStatus.MarkAsPaid]

        } else if (billingStatus == BillingStatus.MarkAsBilled) {
            return [BillingStatus.MarkAsBilled, BillingStatus.Billed, BillingStatus.MarkAsPaid]

        } else if (billingStatus == BillingStatus.Billed) {
            return [BillingStatus.Billed, BillingStatus.MarkAsBilled]

        } else if (billingStatus == BillingStatus.MarkAsPaid) {
            return [BillingStatus.MarkAsPaid]

        } else {
            return [];
        }
    },
    getBillingStatus(billingStatus = null) {
        // Non-Billable = isBillable=false, isInvoiced=false
        // Unbilled = isBillable=true, isInvoiced=false
        // MarkAsBilled = isBillable=true, isInvoiced=true
        // Billed = isBillable=true, isInvoiced=true

        if (billingStatus == BillingStatus.NonBillable) {
            return {
                isBillable: false,
                isInvoiced: false,
            }

        } else if (billingStatus == BillingStatus.Unbilled) {
            return {
                isBillable: true,
                isInvoiced: false,
            }

        } else if (billingStatus == BillingStatus.MarkAsBilled) {
            return {
                isBillable: true,
                isInvoiced: true,
            }

        } else if (billingStatus == BillingStatus.Billed) {
            return {
                isBillable: true,
                isInvoiced: true,
            }

        } else {
            return {
                isBillable: false,
                isInvoiced: false,
            }
        }
    },


    getDefaultDrivers: (details = null, empty = '') => {
        if (details && details.defaultDrivers && details.defaultDrivers.length > 0) {
            let arr = details.defaultDrivers.map((item, i) => {
                let driverName = (item.driverName && item.driverName != '') ? item.driverName : null;
                let vehicleName = (item.defaultVehicle && item.defaultVehicle.vehicleName && item.defaultVehicle.vehicleName != '') ? item.defaultVehicle.vehicleName : null;

                if (driverName && vehicleName) {
                    return driverName + ' / ' + vehicleName

                } else if (driverName) {
                    return driverName

                } else if (vehicleName) {
                    return vehicleName
                }
            });

            if (arr && arr.length > 0) {
                return arr.join(', ');
            } else {
                return empty;
            }
        } else {
            return empty;
        }
    },


    isValidValue: (value, pattern = null) => {
        if(value != ''){
            if(pattern){
                const regex = RegExp(pattern);
                if(value.match(regex) !== null){
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    },


    isCollectJob: (data = null) => {
        let parentJobId = (data && data.parentJobId) ? data.parentJobId : null;
        let parentJobRelation = (data && data.parentJobRelation) ? data.parentJobRelation : null;

        if ((parentJobId > 0) && (parentJobRelation == ParentJobRelation.FollowUp)) {
            return true;
        } else {
            return false;
        }
    },

    findSettingsItems(settings = [], text = '', param = 'variableName') {
        let statusItems = [];

        if (settings && settings.length > 0) {
            statusItems = settings.filter(x => x[param].toLowerCase().trim().includes(text.toLowerCase().trim()));
        }

        return statusItems;
    },
    findSettings(settings = [], text = '', param = 'variableName') {
        let status = null;

        if (settings && settings.length > 0) {
            let statusItem = settings.filter(x => x[param].toLowerCase().trim() === text.toLowerCase().trim());

            if (statusItem && statusItem.length > 0) {
                status = statusItem[0];
            }
        }

        return status;
    },
    findSettingsItem(settings = null, type = AccountDocSettingVariables.String, param = 'variableValue') {
        let status = (type === AccountDocSettingVariables.Show) ? false : '';

        if (settings) {
            status = settings[param];

            if (type === AccountDocSettingVariables.Show) {
                status = Utils.strToBool(status);
            }
        }

        return status;
    },
    setSettingsItem(settings = null, param = '', value = '', paramKey = 'variableName', paramValue = 'variableValue') {
        if (settings) {
            let index = settings.findIndex(x => x[paramKey] === param);
            if (index !== -1) {
                settings[index][paramValue] = value;
            }
        }

        return settings;
    },

    findSortItem(arr = [], text = '', param = 'value') {
        let item = null;

        if (arr && arr.length > 0) {
            let statusItem = arr.filter(x => x[param].toLowerCase().trim() === text.toLowerCase().trim());

            if (statusItem && statusItem.length > 0) {
                item = statusItem[0];
            }
        }

        return item;
    },
    findSortItemTemplate(arr = [], value = '', rowItem = null, param = 'template') {
        let item = Utils.findSortItem(arr, value, 'value');
        return (item && item[param]) ? item[param](rowItem) : null;
    },

    getAuditTrailLabel(type) {
        if (type != null) {
            return AuditTrailObjectTypeText[type];
        } else {
            return '';
        }
    },


    customConcat(array = [], separator = '') {
        return array.filter(x => (x !== null && x !== '')).join(separator);
    },
    concatStringRemarks(mainText = '', newText = '', prevText = '', shouldChange = true) {
        mainText = mainText.replace(prevText, '');
        if (shouldChange) {
            mainText = newText + '\n' + mainText;
        }
        mainText = mainText.replace(/^\s*[\r\n]/gm, '');
        return mainText;
    },

    strToBoolLowerCase(status = '') {
        if (status == 'true') {
            return true;
        } else if (status == 'false') {
            return false;
        } else {
            return false;
        }
    },
    boolToStrLowerCase(status = false) {
        if (status === true) {
            return 'true';
        } else if (status === false) {
            return 'false';
        } else {
            return 'false';
        }
    },
    strToBool(status = '') {
        if (status == 'True') {
            return true;
        } else if (status == 'False') {
            return false;
        } else {
            return false;
        }
    },
    boolToStr(status = false) {
        if (status === true) {
            return 'True';
        } else if (status === false) {
            return 'False';
        } else {
            return 'False';
        }
    },






    getCSVDateFormat: () => {
        return "DD/MM/YYYY";
    },
    getCSVDateTimeFormat: () => {
        return "DD/MM/YYYY hh:mm A";
    },

    getAPIDateFormat: () => {
        return "YYYY-MM-DD";
    },
    getAPIDateTimeFormat: () => {
        return "YYYY-MM-DD hh:mm a";
    },

    getDefaultDateFormat: () => {
        return "DD/MM/YYYY";
    },
    getDefaultTimeFormat: () => {
        return "hh:mm A";
    },
    getDefaultDateTimeFormat: () => {
        return "DD/MM/YYYY hh:mm A";
    },

    getImportDateFormat: () => {
        return "DD/MM/YYYY";
    },
    getImportDateTimeFormat: (a = 'A') => {
        return "DD/MM/YYYY hh:mm " + a;
    },
    getImportDateTimeFieldFormat: () => {
        return "dd/MM/yyyy hh:mm aa";
    },
    getDateTimeSecondFieldFormat: () => {
        return "dd/MM/yyyy hh:mm:ss aa";
    },
    getImportDateTimePickerFormat: () => {
        return "dd/MM/yyyy HH:mm";
    },

    getDefaultDateRangeFormat: () => {
        return "DD-MM-YYYY";
    },

    getGanttDateRangeFormat: () => {
        return "MMM DD, yyyy";
    },


    toUcase: (str = '') => {
        if (str && str != '') {
            return str.toUpperCase();
        } else {
            return '';
        }
    },
    toLcase: (str = '') => {
        if (str && str != '') {
            return str.toLowerCase();
        } else {
            return '';
        }
    },

    splitToDateTime: (str = '') => {
        if (str && str != '') {
            let dateTime = str.split(' ');

            let d = (dateTime && dateTime[0] && dateTime[0] != '') ? dateTime[0] : '';
            let t1 = (dateTime && dateTime[1] && dateTime[1] != '') ? dateTime[1] : '';
            let t2 = (dateTime && dateTime[2] && dateTime[2] != '') ? dateTime[2] : '';

            let t = [];
            if (t1 != '') {
                t.push(t1);
            }

            if (t2 != '') {
                t.push(t2);
            }

            return {
                date: d,
                time: t.join(' '),
            };
        } else {
            return {
                date: '',
                time: '',
            };
        }
    },


    getPayrollTemplateVariableTypeNameByID: (id = null) => {
        if (id === PayrollTemplateVariableType.WorkTime) {
            return 'By work time'
        } else if (id === PayrollTemplateVariableType.WorkTimeDistance) {
            return 'By work time + distance'
        } else if (id === PayrollTemplateVariableType.WorkTimeJobType) {
            return 'By work time + job types'
        } else if (id === PayrollTemplateVariableType.WorkTimeDistanceJobType) {
            return 'By work time + distance + job types'
        } else {
            return 'None'
        }
    },

    getDayOfWeekLabel: (day = null) => {
        if (day === DayOfWeek.Monday) {
            return 'Monday'
        } else if (day === DayOfWeek.Tuesday) {
            return 'Tuesday'
        } else if (day === DayOfWeek.Wednesday) {
            return 'Wednesday'
        } else if (day === DayOfWeek.Thursday) {
            return 'Thursday'
        } else if (day === DayOfWeek.Friday) {
            return 'Friday'
        } else if (day === DayOfWeek.Saturday) {
            return 'Saturday'
        } else if (day === DayOfWeek.Sunday) {
            return 'Sunday'
        } else {
            return ''
        }
    },

    getDateRanges: (exclude = []) => {
        let arr = [
            {
                label: 'Today',
                value: [moment().toDate(), moment().toDate()],
                format: {
                    amount: 1,
                    unit: 'days'
                }
            },
            {
                label: 'Tomorrow',
                value: [moment().add(1, 'days').toDate(), moment().add(1, 'days').toDate()],
                format: {
                    amount: 1,
                    unit: 'days'
                }
            },
            {
                label: 'Yesterday',
                value: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                format: {
                    amount: 1,
                    unit: 'days'
                }
            },
            {
                label: 'This Week',
                value: [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
                format: {
                    amount: 1,
                    unit: 'week'
                }
            },
            {
                label: 'Last Week',
                value: [moment().subtract(1, 'week').startOf('week').toDate(), moment().subtract(1, 'week').endOf('week').toDate()],
                format: {
                    amount: 1,
                    unit: 'week'
                }
            },
            {
                label: 'This Month',
                value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
                format: {
                    amount: 1,
                    unit: 'month'
                }
            },
            {
                label: 'Last Month',
                value: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                format: {
                    amount: 1,
                    unit: 'month'
                }
            },
            {
                label: 'Last 6 Months',
                value: [moment().subtract(5, 'month').startOf('month').toDate(), moment().endOf('month').toDate()],
                format: {
                    amount: 6,
                    unit: 'month'
                }
            },
            {
                label: 'This Year',
                value: [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
                format: {
                    amount: 1,
                    unit: 'year'
                }
            },
            {
                label: 'Last Year',
                value: [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()],
                format: {
                    amount: 1,
                    unit: 'year'
                }
            },
            {
                label: 'Custom',
                value: [moment().subtract(1, 'month').startOf('month').toDate(), moment().endOf('month').toDate()],
                format: {
                    amount: 1,
                    unit: 'days'
                }
            },
        ]

        if (exclude && exclude.length > 0) {
            arr = arr.filter(x => !exclude.includes(x.label))
        }

        return arr;
    },
    getDateRangeLabel: (startDate, endDate) => {
        let label = 'Custom';

        let sD = moment(startDate).startOf('day');
        let eD = moment(endDate).startOf('day');

        if (sD.isSameOrAfter(eD)) {
            let pom = sD;
            sD = eD;
            eD = pom;
        }

        let ranges = Utils.getDateRanges();
        if (ranges && ranges.length > 0) {
            for (let i = 0; i < ranges.length; i++) {
                let sDate = moment(ranges[i].value[0]).startOf('day');
                let eDate = moment(ranges[i].value[1]).startOf('day');

                if ((sDate.isSame(sD)) && (eDate.isSame(eD))) {
                    label = ranges[i].label;
                    break;
                }
            }
        }

        return label;
    },
    getDateRangeValue: (label) => {
        let value = null;

        let ranges = Utils.getDateRanges();
        if (ranges && ranges.length > 0) {
            for (let i = 0; i < ranges.length; i++) {
                if (ranges[i].label === label) {
                    value = ranges[i].value;
                    break;
                }
            }
        }

        return value;
    },
    getDateRangeFormat: (label) => {
        let value = null;

        let ranges = Utils.getDateRanges();
        if (ranges && ranges.length > 0) {
            for (let i = 0; i < ranges.length; i++) {
                if (ranges[i].label === label) {
                    value = ranges[i].format;
                    break;
                }
            }
        }

        return value;
    },

    getDaysbetween: (startDate = null, endDate = null, period = 'days') => {
        let days = 0;

        try {
            var a = moment(startDate).startOf('day');
            var b = moment(endDate).startOf('day');
            days = a.diff(b, period);
        } catch (e) { }

        return days;
    },
    addDate: (days = 0, date = null, period = 'days') => {
        try {
            days = parseInt(days);
        } catch (e) { }

        if (date) {
            return moment(date).add(days, period);
        } else {
            return date;
        }
    },
    subtractDate: (days = 0, date = null, period = 'days') => {
        try {
            days = parseInt(days);
        } catch (e) { }

        if (date) {
            return moment(date).subtract(days, period);
        } else {
            return date;
        }
    },

    getHours: () => {
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    },
    getMinutes: () => {
        return [0, 30];
    },

    timeZoneList: () => {
        let utcValue = [];

        let utcMaxItem = 27;
        for (let index = 0; index < utcMaxItem; index++) {
            let value = index * 60;
            let fristGmt = -12 + index;
            let fristFalue = -720 + value;

            if (fristFalue <= 0) {
                utcValue.push({
                    index: index,
                    value: fristFalue,
                    title: `GMT ${fristGmt}`,
                });
            } else {
                utcValue.push({
                    index: index,
                    value: fristFalue,
                    title: `GMT +${fristGmt}`,
                });
            }
        }

        return utcValue;
    },
    getTimeZoneNameByOffset: (timezoneItems, offset) => {
        let timezoneUtcOffset = '';
        let tz = timezoneItems.filter(x => x.value === offset);
        if (tz && tz.length > 0) {
            timezoneUtcOffset = tz[0].title;
        }
        return timezoneUtcOffset;
    },

    getDuration: (hours, minutes) => {
        return parseFloat(parseInt(hours) + (parseInt(minutes) / 60));
    },
    getHoursMinutes: (duration) => {
        let totalDuration = parseFloat(duration) * 60;
        let hours = parseInt(totalDuration / 60);
        let minutes = parseInt(totalDuration % 60);

        return {
            hours: parseInt(hours),
            minutes: parseInt(minutes),
        }
    },
    getDiff: (completedDate, startedDate) => {
        let date1 = moment(completedDate);
        let date2 = moment(startedDate);

        let duration = moment.duration(date1.diff(date2));

        return {
            h: duration.hours(),
            m: duration.minutes(),
        }
    },

    getDateTimeRangesDiff: (dateTimes = []) => {
        let ranges = Utils.getDateTimeRanges(dateTimes);
        return Utils.getDateTimeDiff(ranges);
    },
    getDateTimeRanges: (ranges = []) => {
        let array = [];

        if (ranges && ranges.length > 0) {
            ranges.map(x => {
                let from = moment(x[0]);
                let to = moment(x[1]);

                let secondDate = to.format("YYYY-MM-DDTHH:mm:ss");
                if (Utils.isSameOrAfter(from, to)) {
                    secondDate = to.add(1, 'day').format("YYYY-MM-DDTHH:mm:ss");
                }

                array.push(Utils.getDateTimeRange(x[0], secondDate));
            });
        }

        return array;
    },
    getDateTimeRange: (from, to) => {
        let range = moment.range(from, to);

        let array = Array.from(range.by('minutes', { excludeEnd: false, step: 60 }));

        let h = _.union(array.map(x => parseInt(x.format('H'))));
        let m = _.union(array.map(x => parseInt(x.format('m'))));

        return {
            hours: h,
            minutes: m
        }
    },
    getDateTimeDiff: (array = []) => {
        let h = _.union(...array.map(x => x.hours)).sort((a, b) => a - b);
        let m = _.union(...array.map(x => x.minutes)).sort((a, b) => a - b);

        let diffH = _.difference(Utils.getHours(), h);
        let diffM = _.difference(Utils.getMinutes(), m);

        return {
            hours: diffH,
            minutes: diffM
        }
    },
    getMinMaxDateTime: (ranges = []) => {
        let moments = [];
        let minDate = null;
        let maxDate = null;

        if (ranges && ranges.length > 0) {
            let array = [];

            ranges.map(x => {
                let from = moment(x[0]);
                let to = moment(x[1]);

                let secondDate = to;
                if (from.isAfter(to)) {
                    secondDate = to.add(1, 'day');
                }

                array.push(from);
                array.push(secondDate);
            });

            moments = array.map(d => moment(d));
            minDate = moment.min(moments);
            maxDate = moment.max(moments);
        }

        return {
            min: minDate.format("YYYY-MM-DDTHH:mm:ss"),
            max: maxDate.format("YYYY-MM-DDTHH:mm:ss")
        }
    },

    getDateTimeArray: (ranges = []) => {
        let array = [];

        if (ranges && ranges.length > 0) {
            ranges.map(x => {
                let dates = Utils.convertFromTo(moment(x[0]), moment(x[1]));
                array.push([dates.from, dates.to]);
            });
        }

        return array
    },
    isDateTimeExist: (dates, from, to) => {
        let isBetween = false;
        let isBetweenFrom = false;
        let isBetweenTo = false;

        if (dates && dates.length > 0) {
            for (let i = 0; i < dates.length; i++) {
                if (!isBetweenFrom)
                    isBetweenFrom = Utils.isBetween(from, dates[i][0], dates[i][1]);

                if (!isBetweenTo)
                    isBetweenTo = Utils.isBetween(to, dates[i][0], dates[i][1]);
            }
        }

        if (isBetweenFrom && isBetweenTo) {
            isBetween = true;
        }

        return isBetween;
    },
    isFromToExist: (dates, from, to) => {
        let isBetween = false;

        let isBetweenFromTo = Utils.isDateTimeExist(dates, from, to);

        if (isBetweenFromTo) {
            isBetween = true;
        }

        return isBetween;
    },
    convertFromTo: (from, to) => {
        let firstDate = moment();
        if (from.isAfter(to)) {
            firstDate = moment().subtract(1, 'day');
        }

        let currentDate = moment().format(Utils.getAPIDateFormat());
        let date = firstDate.format(Utils.getAPIDateFormat());

        let fromDate = from.format(Utils.getDefaultTimeFormat());
        let toDate = to.format(Utils.getDefaultTimeFormat());

        let dateF = moment(date + ' ' + fromDate, Utils.getAPIDateTimeFormat());
        let dateT = moment(currentDate + ' ' + toDate, Utils.getAPIDateTimeFormat());

        return {
            from: dateF,
            to: dateT
        };
    },
    isSameOrAfter: (from, to) => {
        return from.isSameOrAfter(to)
    },
    isSameOrBefore: (from, to) => {
        return from.isSameOrBefore(to)
    },
    isBetween: (date, from, to) => {
        return moment(date).isBetween(from, to, undefined, '[]');
    },

    formatTimeCountdown: (seconds = null) => {
        if (seconds > 0) {
            var duration = moment.duration(seconds, 'seconds');
            return moment.utc(duration.as('milliseconds')).format('mm:ss');
        } else {
            return '';
        }
    },



    querySelectorAll: (selector = '', element = null) => {
        let el = null;
        if (selector && selector !== '') {
            try {
                if (element) {
                    el = element.querySelectorAll(selector);
                } else {
                    el = document.querySelectorAll(selector);
                }
            } catch (err) { }
        }
        return el;
    },
    getElementsByTagName: (tagName = '', element = null) => {
        let el = null;
        if (tagName && tagName !== '') {
            try {
                if (element) {
                    el = element.getElementsByTagName(tagName);
                } else {
                    el = document.getElementsByTagName(tagName);
                }
            } catch (err) { }
        }
        return el;
    },
    getElementByClassName: (className = '', element = null) => {
        let el = null;
        if (className && className !== '') {
            try {
                if (element) {
                    el = element.getElementsByClassName(className);
                } else {
                    el = document.getElementsByClassName(className);
                }
            } catch (err) { }
        }
        return el;
    },
    flash: (element = null, className = '', anim = 'highlight-anim') => {
        if (element && className !== '') {
            try {
                let els = Utils.getElementByClassName(className, element);
                if (els && els.length > 0) {
                    for (let i = 0; i < els.length; i++) {
                        els[i].classList.add(anim);
                    }
                }

                setTimeout(() => {
                    try {
                        if (els && els.length > 0) {
                            for (let i = 0; i < els.length; i++) {
                                els[i].classList.remove(anim);
                            }
                        }
                    } catch (err) { }
                }, 2000);
            } catch (err) { }
        }
    },
    colorRows: (ids = [], backgroundColor = '#f1f1f1', color = 'black') => {
        if (ids && ids.length > 0) {
            ids.forEach(id => {
                try {
                    let els = Utils.getElementByClassName("row_" + id);
                    if (els && els.length > 0) {
                        for (let i = 0; i < els.length; i++) {
                            els[i].style.setProperty("background-color", backgroundColor, "important");
                            els[i].style.setProperty("color", color, "important");

                            let tds = els[i].querySelectorAll('td');
                            if (tds && tds.length > 0) {
                                for (let c = 0; c < tds.length; c++) {
                                    tds[c].style.setProperty("background-color", backgroundColor, "important");
                                }
                            }

                            // let childElements = els[i].getElementsByTagName("*");
                            // if(childElements && childElements.length > 0){
                            //     for (let k = 0; k < childElements.length; k++) {
                            //         if (!childElements[k].classList.contains("MuiTouchRipple-root")) {
                            //             childElements[k].style.setProperty("background-color", backgroundColor, "important");
                            //             childElements[k].style.setProperty("color", color, "important");
                            //         }
                            //     }
                            // }

                            // let cbs = els[i].querySelectorAll('input[type="checkbox"]');
                            // if(cbs && cbs.length > 0){
                            //     for (let c = 0; c < cbs.length; c++) {
                            //         cbs[c].classList.add("colored-row-svg");
                            //     }
                            // }
                        }
                    }
                } catch (err) { }
            });
        }
    },
    colorRowsReset: (selector = 'table > tbody > tr') => {
        try {
            let els = Utils.querySelectorAll(selector);
            if (els && els.length > 0) {
                for (let i = 0; i < els.length; i++) {
                    els[i].style.removeProperty("background-color");
                    els[i].style.removeProperty("color");

                    let tds = els[i].querySelectorAll('td');
                    if (tds && tds.length > 0) {
                        for (let c = 0; c < tds.length; c++) {
                            tds[c].style.removeProperty("background-color");
                        }
                    }

                    // let childElements = els[i].getElementsByTagName("*");
                    // if(childElements && childElements.length > 0){
                    //     for (let k = 0; k < childElements.length; k++) {
                    //         if (!childElements[k].classList.contains("MuiTouchRipple-root")) {
                    //             childElements[k].style.removeProperty("background-color");
                    //             childElements[k].style.removeProperty("color");
                    //         }
                    //     }
                    // }

                    // let cbs = els[i].querySelectorAll('input[type="checkbox"]');
                    // if(cbs && cbs.length > 0){
                    //     for (let c = 0; c < cbs.length; c++) {
                    //         cbs[c].classList.remove("colored-row-svg");
                    //     }
                    // }
                }
            }
        } catch (err) { }
    },
    colorRowsPrimery: (ids = []) => {
        Utils.colorRows(ids, '#e9efff', 'black');
    },
    colorRowsSuccess: (ids = []) => {
        Utils.colorRows(ids, '#e9f6ec', 'black');
    },
    colorRowsDanger: (ids = []) => {
        Utils.colorRows(ids, '#ffeded', 'black');
    },
    colorRowsWarning: (ids = []) => {
        Utils.colorRows(ids, '#fff9e6', 'black');
    },
    colorRowsInfo: (ids = []) => {
        Utils.colorRows(ids, '#e7f6f8', 'black');
    },

    changeJobs(field = '', arr = [], dataRows = [], fields = []) {
        if(arr && arr.length > 0){
            let rows = _.cloneDeep(dataRows);
    
            arr.forEach(row => {
                let index = rows.findIndex(x => x[field] === row[field]);
                if(index > -1){
                    if(fields && fields.length > 0){
                        fields.forEach(f => {
                            rows[index][f] = row[f];
                        });
                    } else {
                        rows[index] = row;
                    }
                }
            });
    
            return rows;
        } else {
            return arr;
        }
    },

    clickOutside() {
        try {
            const body = document.querySelector("body");
            body.click();
        } catch (e) { }
    },
    reorder(list, startIndex, endIndex) {
        const items = Array.from(list);
        const [reorderedItem] = items.splice(startIndex, 1);
        items.splice(endIndex, 0, reorderedItem);
        return items
    },

    copyObj(obj = null) {
        if (obj !== null) {
            try {
                return Object.assign({}, obj);
            } catch (e) {
                return null;
            }
        } else {
            return null;
        }
    },
    copyArr(obj = null) {
        if (obj !== null) {
            try {
                return Object.assign([], obj);
            } catch (e) {
                return null;
            }
        } else {
            return null;
        }
    },


    formatString: (str = "") => {
        try {
            var regExpr = /[^a-zA-Z0-9]/g;
            return str.toLowerCase().replace(regExpr, "");
        } catch (ex) {
            return str.toLowerCase();
        }
    },


    getFromAddress(components, type) {
        return components.filter((component) => component.types.indexOf(type) === 0).map((item) => item.long_name).pop() || null;
    },

    hasDuplicatedObjects(array = [], fields = []) {
        return array.map((value) => {
            return fields.map((f, i) => {
                return value[f]
            }).join('')
        }).some((value, index, array) => {
            return array.indexOf(value) !== array.lastIndexOf(value);
        })
    },
    hasDuplicated(array = [], fields = [], texts = []) {
        let status = false;
        if (texts && texts.length > 0) {
            for (let t = 0; t < texts.length; t++) {
                let text = texts[t];

                if (fields && fields.length > 0) {
                    for (let f = 0; f < fields.length; f++) {
                        let field = fields[f];

                        let count = array.filter(x => x[field].toString().toLowerCase() === text.toString().toLowerCase()).length;
                        if (count > 0) {
                            status = true;
                            break;
                        }
                    }
                }

            }
        }
        return status;
    },
    isEmptyRow(obj, fields) {
        try {
            return !fields.map((f) => {
                // return obj[f] === '' ? true : false
                if (Utils.getType(obj[f]) === 'array') {
                    return ((obj[f].length == 0) ? true : false)
                } else if (Utils.getType(obj[f]) === 'object') {
                    return ((obj[f] == null) ? true : false)
                } else {
                    return (((obj[f] == '') || (obj[f] == null)) ? true : false)
                }
            }).some(e => e === false);
        } catch (err) { }
        return false;
    },
    isEqual(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    },

    removeEmptyRows(array = [], fields = []) {
        try {
            return array.map((value) => {
                let row = fields.map((f) => {
                    return value[f]
                }).filter((e) => {
                    return e === 0 ? '0' : e
                });
                return (row && row.length > 0) ? value : null;
            }).filter(x => x !== null)
        }catch(e){
            return [];
        }
    },
    removeEmptyRowsWithArrays: (arr, callback = null) => {
        if (arr && arr.length > 0) {
            return arr.filter(row => {
                if (arr && (arr.length > 0) && callback) {
                    return callback(row);
                }
            });
        } else {
            return [];
        }
    },
    hasEmptyRows: (arr, callback: any = null) => {
        let state = false;

        if (callback && arr && arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                state = callback(arr[i], i);
                if (state)
                    break;
            }
        }

        return state;
    },
    hasEmptyRow(item, fields) {
        if (item && fields && fields.length > 0) {
            for (let i = 0; i < fields.length; i++) {
                if (Utils.getType(item[fields[i]]) === 'array') {
                    return ((item[fields[i]].length == 0) ? true : false)
                } else if (Utils.getType(item[fields[i]]) === 'object') {
                    return ((item[fields[i]] == null) ? true : false)
                } else {
                    return (((item[fields[i]] == '') || (item[fields[i]] == null)) ? true : false)
                }
            }
        } else {
            return false;
        }
    },

    getType(param) {
        if (Array.isArray(param)) {
            return "array";
        } else if (param instanceof Object) {
            return "object";
        } else {
            return typeof param;
        }
    },

    isStandardService(item) {
        return ((item.serviceType == ServiceType.StandardService) || (item.serviceType == ServiceType.BundleService)) ? true : false;
    },

    getServiceItem(bTId, wTId, data) {
        let standardItems = [];
        if (data && data.length > 0) {
            standardItems = data.filter(x => (x.binTypeId == bTId) && (x.wasteTypeId == wTId))
        }

        let arr = [];
        if (standardItems && standardItems.length > 0) {
            for (var i = 0; i < standardItems.length; i++) {
                let item = standardItems[i];

                let binTypeId = item.binTypeId;
                let binTypeName = (item.binTypeName) ? item.binTypeName : '';
                let wasteTypeId = item.wasteTypeId;
                let wasteTypeName = (item.wasteTypeName) ? item.wasteTypeName : '';
                let serviceTag = (item.serviceTag) ? item.serviceTag : '';
                let binOutDate = (item.binOutDate) ? item.binOutDate : '';
                let binNumber = (item.binNumber) ? item.binNumber : '';
                let driverName = (item.driverName) ? item.driverName : '';
                let vehicleName = (item.vehicleName) ? item.vehicleName : '';
                let jobNumber = (item.jobNumber) ? item.jobNumber : '';
                let isBundled = ((item.isBundled === true) || (item.isBundled === false)) ? item.isBundled : false;
                let additionalCharges = (item.bundleServices && item.bundleServices.length > 0) ? item.bundleServices : [];
                let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);

                let arrItem = {
                    value: value,
                    item: item,
                    binTypeId: binTypeId,
                    binTypeName: binTypeName,
                    wasteTypeId: wasteTypeId,
                    wasteTypeName: wasteTypeName,
                    serviceTag: serviceTag,
                    binOutDate: binOutDate,
                    binNumber: binNumber,
                    driverName: driverName,
                    vehicleName: vehicleName,
                    jobNumber: jobNumber,
                    isBundled: isBundled,
                    additionalCharges: additionalCharges,
                };

                arr = Utils.addToArray(arr, value, arrItem);
            }
        }

        let charges = arr.sort((a, b) => b.additionalCharges.length - a.additionalCharges.length);

        let backDataItem = null;
        if (charges && charges.length > 0) {
            let chargeWithBundles = charges.filter(x => x.additionalCharges && x.additionalCharges.length > 0);
            if (chargeWithBundles && chargeWithBundles.length > 0) {
                backDataItem = chargeWithBundles[0];
            } else {
                backDataItem = charges[0];
            }
        }


        return backDataItem;
    },
    getServiceTagItem(sTag, bTId, wTId, data) {
        let standardItems = [];
        if (data && data.length > 0) {
            standardItems = data.filter(x => (x.serviceTag == sTag) && (x.binTypeId == bTId) && (x.wasteTypeId == wTId))
        }

        let arr = [];
        if (standardItems && standardItems.length > 0) {
            for (var i = 0; i < standardItems.length; i++) {
                let item = standardItems[i];

                let binTypeId = item.binTypeId;
                let binTypeName = (item.binTypeName) ? item.binTypeName : '';
                let wasteTypeId = item.wasteTypeId;
                let wasteTypeName = (item.wasteTypeName) ? item.wasteTypeName : '';
                let serviceTag = (item.serviceTag) ? item.serviceTag : '';
                let binOutDate = (item.binOutDate) ? item.binOutDate : '';
                let binNumber = (item.binNumber) ? item.binNumber : '';
                let driverName = (item.driverName) ? item.driverName : '';
                let vehicleName = (item.vehicleName) ? item.vehicleName : '';
                let jobNumber = (item.jobNumber) ? item.jobNumber : '';
                let isBundled = ((item.isBundled === true) || (item.isBundled === false)) ? item.isBundled : false;
                let additionalCharges = (item.bundleServices && item.bundleServices.length > 0) ? item.bundleServices : [];
                let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);

                let arrItem = {
                    value: value,
                    item: item,
                    binTypeId: binTypeId,
                    binTypeName: binTypeName,
                    wasteTypeId: wasteTypeId,
                    wasteTypeName: wasteTypeName,
                    serviceTag: serviceTag,
                    binOutDate: binOutDate,
                    binNumber: binNumber,
                    driverName: driverName,
                    vehicleName: vehicleName,
                    jobNumber: jobNumber,
                    isBundled: isBundled,
                    additionalCharges: additionalCharges,
                };

                arr = Utils.addToArray(arr, value, arrItem);
            }
        }

        let charges = arr.sort((a, b) => b.additionalCharges.length - a.additionalCharges.length);

        let backDataItem = null;
        if (charges && charges.length > 0) {
            let chargeWithBundles = charges.filter(x => x.additionalCharges && x.additionalCharges.length > 0);
            if (chargeWithBundles && chargeWithBundles.length > 0) {
                backDataItem = chargeWithBundles[0];
            } else {
                backDataItem = charges[0];
            }
        }

        return backDataItem;
    },
    getServiceTagItemByServiceItem(serviceItemIds, sTag, bTId, wTId, data) {
        let standardItems = [];
        if (data && data.length > 0) {
            if (serviceItemIds && serviceItemIds.length> 0) {
                standardItems = serviceItemIds.map(id => data.find(item => item.serviceItemId === id)).filter(item => item !== undefined);
            } else {
                standardItems = data.filter(x => (x.serviceTag == sTag) && (x.binTypeId == bTId) && (x.wasteTypeId == wTId))
            }
        }

        let arr = [];
        if (standardItems && standardItems.length > 0) {
            for (var i = 0; i < standardItems.length; i++) {
                let item = standardItems[i];

                let binTypeId = item.binTypeId;
                let binTypeName = (item.binTypeName) ? item.binTypeName : '';
                let wasteTypeId = item.wasteTypeId;
                let wasteTypeName = (item.wasteTypeName) ? item.wasteTypeName : '';
                let serviceTag = (item.serviceTag) ? item.serviceTag : '';
                let binOutDate = (item.binOutDate) ? item.binOutDate : '';
                let binNumber = (item.binNumber) ? item.binNumber : '';
                let driverName = (item.driverName) ? item.driverName : '';
                let vehicleName = (item.vehicleName) ? item.vehicleName : '';
                let jobNumber = (item.jobNumber) ? item.jobNumber : '';
                let isBundled = ((item.isBundled === true) || (item.isBundled === false)) ? item.isBundled : false;
                let serviceItemId = (item.serviceItemId) ? item.serviceItemId : '';
                let additionalCharges = (i == 0) ? (item.bundleServices && item.bundleServices.length > 0) ? item.bundleServices : [] : [];
                let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);

                let arrItem = {
                    value: value,
                    item: item,
                    binTypeId: binTypeId,
                    binTypeName: binTypeName,
                    wasteTypeId: wasteTypeId,
                    wasteTypeName: wasteTypeName,
                    serviceTag: serviceTag,
                    binOutDate: binOutDate,
                    binNumber: binNumber,
                    driverName: driverName,
                    vehicleName: vehicleName,
                    jobNumber: jobNumber,
                    isBundled: isBundled,
                    serviceItemId: serviceItemId,
                    additionalCharges: additionalCharges,
                };

                arr = Utils.addToArray(arr, value, arrItem);
            }
        }

        return arr
    },
    getPricings(chargeCategoryItems = [], serviceItems) {
        let pricings = [];
        
        if(serviceItems && serviceItems.length > 0){
            for(let j = 0; j < serviceItems.length; j++){
                let serviceItem = serviceItems[j];
                
                if (serviceItem && serviceItem.item) {
                    let item = serviceItem.item;
    
                    let chargeCategoryObj = (item.chargeCategory) ? item.chargeCategory : null;
                    let chargeCategoryId = (item.chargeCategoryId) ? item.chargeCategoryId : null;
                    let chargeCategoryName = (chargeCategoryObj && chargeCategoryObj.chargeCategoryName) ? chargeCategoryObj.chargeCategoryName : '';
                    if (chargeCategoryObj) {
                        let chargeCategoryItem = {
                            value: chargeCategoryId,
                            title: chargeCategoryName,
                            item: chargeCategoryObj,
                        }
    
                        chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem)
                    }
    
                    let bundleServiceTag = item.serviceTag;
    
                    let dataItem = {
                        serviceItemId: item.serviceItemId,
                        serviceTag: bundleServiceTag,
                        description: bundleServiceTag,
                        billingType: item.billingType,
                        chargeCategoryId: chargeCategoryId,
                        chargeCategoryName: chargeCategoryName,
                        quantity: 1,
                        price: item.price,
                        bundleServiceTag: bundleServiceTag,
                        binTypeId: item.binTypeId,
                        wasteTypeId: item.wasteTypeId,
                        serviceType: ServiceType.StandardService,
                        jobStepId: null,
                        useBinWeightQuantity: item.useBinWeightQuantity,
                        isTaxInclusive: (item) ? item.isTaxInclusive : false,
                        invoiceTaxRate: (item) ? item.invoiceTaxRate : null,
                        purchaseTaxRate: (item) ? item.purchaseTaxRate : null,
                        sortOrder: j,
                    }
    
                    pricings.push(dataItem);
    
                    if (serviceItem.additionalCharges && serviceItem.additionalCharges.length > 0) {
                        for (let i = 0; i < serviceItem.additionalCharges.length; i++) {
                            let subItem = serviceItem.additionalCharges[i];
    
                            let subChargeCategoryObj = (subItem.chargeCategory) ? subItem.chargeCategory : null;
                            let subChargeCategoryId = (subItem.chargeCategoryId) ? subItem.chargeCategoryId : null;
                            let subChargeCategoryName = (subChargeCategoryObj && subChargeCategoryObj.chargeCategoryName) ? subChargeCategoryObj.chargeCategoryName : '';
                            if (subChargeCategoryObj) {
                                let subChargeCategoryItem = {
                                    value: subChargeCategoryId,
                                    title: subChargeCategoryName,
                                    item: subChargeCategoryObj,
                                }
    
                                chargeCategoryItems = Utils.addToArray(chargeCategoryItems, subChargeCategoryId, subChargeCategoryItem)
                            }
    
                            let useBinWeightQuantity = (subItem) ? subItem.useBinWeightQuantity : false;
                            let quantity = useBinWeightQuantity ? '' : 1;
    
                            let subDataItem = {
                                serviceItemId: subItem.serviceItemId,
                                serviceType: ServiceType.AdditionalService,
                                binTypeId: null,
                                wasteTypeId: null,
                                serviceTag: subItem.serviceTag,
                                bundleServiceTag: bundleServiceTag,
                                description: subItem.serviceTag,
                                price: subItem.price,
                                quantity: quantity,
                                billingType: subItem.billingType,
                                chargeCategoryId: subChargeCategoryId,
                                chargeCategoryName: subChargeCategoryName,
                                jobStepId: null,
                                useBinWeightQuantity: useBinWeightQuantity,
                                nonBillableWeight: subItem.nonBillableWeight,
                                isTaxInclusive: (subItem) ? subItem.isTaxInclusive : false,
                                invoiceTaxRate: (subItem) ? subItem.invoiceTaxRate : null,
                                purchaseTaxRate: (subItem) ? subItem.purchaseTaxRate : null,
                            }
    
                            pricings.push(subDataItem);
                        }
                    }
                }
            }
        }

        return {
            pricings: pricings,
            chargeCategoryItems: chargeCategoryItems,
        };
    },
    getChargeCategoryFromPricings(chargeCategoryItems = [], pricings = []) {
        if (chargeCategoryItems && chargeCategoryItems.length > 0) {
            if (pricings && pricings.length > 0) {
                for (let i = 0; i < pricings.length; i++) {
                    let subItem = pricings[i];

                    let subChargeCategoryObj = (subItem.chargeCategory) ? subItem.chargeCategory : null;
                    let subChargeCategoryId = (subItem.chargeCategoryId) ? subItem.chargeCategoryId : null;
                    let subChargeCategoryName = (subChargeCategoryObj && subChargeCategoryObj.chargeCategoryName) ? subChargeCategoryObj.chargeCategoryName : '';
                    if (subChargeCategoryObj) {
                        let subChargeCategoryItem = {
                            value: subChargeCategoryId,
                            title: subChargeCategoryName,
                            item: subChargeCategoryObj,
                        }

                        chargeCategoryItems = Utils.addToArray(chargeCategoryItems, subChargeCategoryId, subChargeCategoryItem)
                    }
                }
            }
        }

        return chargeCategoryItems;
    },
    comparePricingsArrays(arr1 = [], arr2 = [], callback = null) {
        // Create a new empty result array
        const result = [];

        // Loop through each item in the first array
        for (const item of arr1) {
            // Check if the item exists in the second array, based on the binTypeId and wasteTypeId fields
            let match1 = arr2.find(i => i.binTypeId == item.binTypeId && i.wasteTypeId == item.wasteTypeId && i.serviceItemId == item.serviceItemId);
            let match2 = arr2.find(i => i.serviceTag.trim().toUpperCase() == item.serviceTag.trim().toUpperCase() && i.binTypeId == item.binTypeId && i.wasteTypeId == item.wasteTypeId);
            let match = (match1) ? match1 : match2;

            // If the item exists in the second array, update the item in the result array
            if (match) {
                // Exclude the quantity field from the update
                result.push({
                    ...match,
                    quantity: item.quantity
                });
            }
            // If the item does not exist in the second array, skip it (do not remove it from the array)
            else {
                if (callback) {
                    if (item.bundleServiceTag != '' && !callback(item)) {
                        result.splice(result.indexOf(item), 1);
                    } else {
                        result.push(item);
                    }
                } else {
                    if (item.bundleServiceTag != '') {
                        result.splice(result.indexOf(item), 1);
                    } else {
                        result.push(item);
                    }
                }
            }
        }

        // Loop through each item in the second array
        for (const item of arr2) {
            // Check if the item exists in the result array, based on the binTypeId and wasteTypeId fields

            let match1 = result.find(i => i.serviceTag == item.serviceTag && i.binTypeId == item.binTypeId && i.wasteTypeId == item.wasteTypeId && i.serviceItemId == item.serviceItemId);
            let match2 = result.find(i => i.serviceTag == item.serviceTag && i.binTypeId == item.binTypeId && i.wasteTypeId == item.wasteTypeId);
            let match = (match1) ? match1 : match2;

            // If the item does not exist in the result array, add it
            if (!match) {
                result.push(item);
            }
        }

        result.sort((a, b) => (a.serviceType < b.serviceType ? 1 : -1));
        result.sort((a, b) => (a.bundleServiceTag < b.bundleServiceTag ? 1 : -1));

        return result;
    },
    addManualItems(pricings, oldPricings) {
        if(oldPricings && oldPricings.length > 0){
            let manualPricings = oldPricings.filter(x => x.serviceType == ServiceType.AdditionalService && (x.bundleServiceTag == null || x.bundleServiceTag == '' || x.bundleServiceTag == undefined));
            
            if (manualPricings && manualPricings.length > 0) {
                for (let i = 0; i < manualPricings.length; i++) {
                    let itm = manualPricings[i];
                    pricings.push(itm);
                }
            }
        }

        return pricings;
    },

    getRepeatOrder(repeatOrder) {
        if (repeatOrder === RepeatOrder.Monthly) {
            return 'monthly';
        } else if (repeatOrder === RepeatOrder.Weekly) {
            return 'weekly';
        } else {
            return 'daily';
        }
    },

    getSchedules(repeatOrder, data = null) {
        if (repeatOrder === RepeatOrder.Monthly) {
            let schedules = [
                {
                    day: 0,
                    isChecked: false,
                    time: null,
                    date: null,
                }
            ];

            if (data && data.schedules && data.schedules.length > 0) {
                schedules = data.schedules;
            }

            return schedules;
        } else if (repeatOrder === RepeatOrder.Weekly) {
            let schedules = [
                {
                    day: DayOfWeek.Monday,
                    isChecked: false,
                    time: null,
                    date: null,
                },
                {
                    day: DayOfWeek.Tuesday,
                    isChecked: false,
                    time: null,
                    date: null,
                },
                {
                    day: DayOfWeek.Wednesday,
                    isChecked: false,
                    time: null,
                    date: null,
                },
                {
                    day: DayOfWeek.Thursday,
                    isChecked: false,
                    time: null,
                    date: null,
                },
                {
                    day: DayOfWeek.Friday,
                    isChecked: false,
                    time: null,
                    date: null,
                },
                {
                    day: DayOfWeek.Saturday,
                    isChecked: false,
                    time: null,
                    date: null,
                },
                {
                    day: DayOfWeek.Sunday,
                    isChecked: false,
                    time: null,
                    date: null,
                }
            ];

            if (data && data.schedules && data.schedules.length > 0) {
                data.schedules.forEach((item, i) => {
                    let index = schedules.findIndex(x => x.day === item.day);
                    if (index === -1) {
                        schedules.push({
                            ...item,
                            isChecked: true,
                        });
                    } else {
                        schedules[index] = {
                            ...item,
                            isChecked: true,
                        }
                    }
                });
            }

            return schedules;
        } else {
            return [];
        }
    },

    iscustomerSiteExist(props = null) {
        if (props) {
            let {
                jobTemplateType,
                customerSiteId,
                hasCoordCustomerSite,
                customerSiteShiftId,
                hasCoordCustomerSiteShift
            } = props;

            if (jobTemplateType === JobStepType.Shift) {
                return ((customerSiteId && hasCoordCustomerSite) && (customerSiteShiftId && hasCoordCustomerSiteShift)) ? true : false;
            } else {
                return (customerSiteId && hasCoordCustomerSite) ? true : false;
            }
        } else {
            return false;
        }
    },
    isBinTypeExist(props = null) {
        if (props) {
            let {
                jobTemplateType,
                stepExchangeOutBinTypeId,
                stepExchangeInBinTypeId,
                stepOnTheSpotBinTypeId,
                stepPullBinTypeId,
                stepPutBinTypeId,
                stepOutBinTypeId,
                stepShiftBinTypeId,
            } = props;

            if (jobTemplateType === JobStepType.Exchange) {
                return (stepExchangeOutBinTypeId && stepExchangeInBinTypeId) ? true : false;
            } else if (jobTemplateType === JobStepType.OnTheSpot) {
                return stepOnTheSpotBinTypeId ? true : false;
            } else if (jobTemplateType === JobStepType.Shift) {
                return stepShiftBinTypeId ? true : false;
            } else if (jobTemplateType === JobStepType.Put) {
                return stepPutBinTypeId ? true : false;
            } else if (jobTemplateType === JobStepType.Out) {
                return stepOutBinTypeId ? true : false;
            } else if (jobTemplateType === JobStepType.Pull) {
                return stepPullBinTypeId ? true : false;
            }
        } else {
            return false;
        }
    },
    getJobFormActionButtons(props) {
        if (props) {
            let {
                isEdit,
                isCopy,
                // currentStatusName,
                statusName,
                customerId,
                jobTemplateId,
                driverId,
                vehicleId,
                customFormParams,
            } = props;

            statusName = statusName.toString().toLowerCase().trim();
            let customerSiteId = Utils.iscustomerSiteExist(props);
            let binTypeId = Utils.isBinTypeExist(props);

            if (!isEdit || isCopy) {
                if (!customerId) {
                    return null;
                } else {
                    if (!jobTemplateId || !customerSiteId) {
                        return {
                            main: JobAction.Draft,
                            dropdown: null,
                        };
                    } else {
                        if (!binTypeId && (customFormParams.jobTemplateType !== JobStepType.Task)) {
                            return {
                                main: JobAction.Draft,
                                dropdown: null,
                            };
                        } else {
                            if (!driverId) {
                                return {
                                    main: JobAction.Unassigned,
                                    dropdown: [JobAction.Draft],
                                };
                            } else if (driverId && !vehicleId) {
                                return {
                                    main: JobAction.Assigned,
                                    dropdown: [JobAction.Draft],
                                };

                            } else {
                                return {
                                    main: JobAction.Dispatched,
                                    dropdown: [JobAction.Draft, JobAction.Assigned, JobAction.Completed],
                                };
                            }
                        }
                    }
                }

            } else {
                if (['draft', 'unassigned'].includes(statusName)) {
                    if (!customerId) {
                        return null;
                    } else {
                        if (!jobTemplateId || !customerSiteId) {
                            return {
                                main: JobAction.Cancelled,
                                dropdown: [JobAction.Draft],
                            };
                        } else {
                            if (!binTypeId && (customFormParams.jobTemplateType !== JobStepType.Task)) {
                                return {
                                    main: JobAction.Cancelled,
                                    dropdown: [JobAction.Draft],
                                };
                            } else {
                                if (!driverId || !vehicleId) {
                                    return {
                                        main: JobAction.Unassigned,
                                        dropdown: [JobAction.Draft, JobAction.Cancelled],
                                    };
                                } else {
                                    return {
                                        main: JobAction.Dispatched,
                                        dropdown: [JobAction.Draft, JobAction.Assigned, JobAction.Completed, JobAction.Cancelled],
                                    };
                                }
                            }
                        }
                    }

                } else if (['assigned'].includes(statusName)) {
                    if (!customerId) {
                        return null;
                    } else {
                        if (!jobTemplateId || !customerSiteId) {
                            return {
                                main: JobAction.Cancelled,
                                dropdown: [JobAction.Draft],
                            };
                        } else {
                            if (!binTypeId && (customFormParams.jobTemplateType !== JobStepType.Task)) {
                                return {
                                    main: JobAction.Cancelled,
                                    dropdown: [JobAction.Draft],
                                };
                            } else {
                                if (!driverId) {
                                    return {
                                        main: JobAction.Unassigned,
                                        dropdown: [JobAction.Draft, JobAction.Cancelled],
                                    };
                                } else if (driverId && !vehicleId) {
                                    return {
                                        main: JobAction.Assigned,
                                        dropdown: [JobAction.Draft, JobAction.Cancelled],
                                    };
                                } else {
                                    return {
                                        main: JobAction.Dispatched,
                                        dropdown: [JobAction.Draft, JobAction.Assigned, JobAction.Completed, JobAction.Cancelled],
                                    };
                                }
                            }
                        }
                    }

                } else if (['dispatched', 'acknowledged'].includes(statusName)) {
                    if (!customerId) {
                        return null;
                    } else {
                        if (!jobTemplateId || !customerSiteId) {
                            return {
                                main: JobAction.Cancelled,
                                dropdown: [JobAction.Draft],
                            };
                        } else {
                            if (!binTypeId && (customFormParams.jobTemplateType !== JobStepType.Task)) {
                                return {
                                    main: JobAction.Cancelled,
                                    dropdown: [JobAction.Draft],
                                };
                            } else {
                                if (!driverId || !vehicleId) {
                                    return {
                                        main: JobAction.Unassigned,
                                        dropdown: [JobAction.Draft, JobAction.Cancelled],
                                    };
                                } else {
                                    return {
                                        main: JobAction.Dispatched,
                                        dropdown: [JobAction.Draft, JobAction.Completed, JobAction.Cancelled],
                                    };
                                }
                            }
                        }
                    }

                } else if (['started', 'in progress'].includes(statusName)) {
                    if (!customerId) {
                        return null;
                    } else {
                        if (!jobTemplateId || !customerSiteId) {
                            return {
                                main: JobAction.Save,
                                dropdown: [JobAction.SaveAndClose],
                            };
                        } else {
                            if (!binTypeId && (customFormParams.jobTemplateType !== JobStepType.Task)) {
                                return {
                                    main: JobAction.Save,
                                    dropdown: [JobAction.SaveAndClose],
                                };
                            } else {
                                if (!driverId || !vehicleId) {
                                    return {
                                        main: JobAction.Save,
                                        dropdown: [JobAction.Cancelled, JobAction.SaveAndClose],
                                    };
                                } else {
                                    return {
                                        main: JobAction.Save,
                                        dropdown: [JobAction.Completed, JobAction.Cancelled, JobAction.SaveAndClose],
                                    };
                                }
                            }
                        }
                    }

                } else if (statusName === 'completed') {
                    if (!customerId) {
                        return null;
                    } else {
                        if (!jobTemplateId || !customerSiteId) {
                            return {
                                main: JobAction.Save,
                                dropdown: [JobAction.SaveAndClose],
                            };
                        } else {
                            if (!binTypeId && (customFormParams.jobTemplateType !== JobStepType.Task)) {
                                return {
                                    main: JobAction.Save,
                                    dropdown: [JobAction.SaveAndClose],
                                };
                            } else {
                                if (!driverId || !vehicleId) {
                                    return {
                                        main: JobAction.Save,
                                        dropdown: [JobAction.SaveAndClose],
                                    };
                                } else {
                                    return {
                                        main: JobAction.Save,
                                        dropdown: [JobAction.Cancelled, JobAction.SaveAndClose],
                                    };
                                }
                            }
                        }
                    }

                } else {
                    if (!customerId) {
                        return null;
                    } else {
                        if (!jobTemplateId || !customerSiteId) {
                            return {
                                main: JobAction.Save,
                                dropdown: [JobAction.SaveAndClose],
                            };
                        } else {
                            if (!binTypeId && (customFormParams.jobTemplateType !== JobStepType.Task)) {
                                return {
                                    main: JobAction.Save,
                                    dropdown: [JobAction.SaveAndClose],
                                };
                            } else {
                                if (!driverId || !vehicleId) {
                                    return {
                                        main: JobAction.Save,
                                        dropdown: [JobAction.SaveAndClose],
                                    };
                                } else {
                                    return {
                                        main: JobAction.Save,
                                        dropdown: [JobAction.SaveAndClose],
                                    };
                                }
                            }
                        }
                    }
                }
            }
        } else {
            return null;
        }
    },
    getJobFormActionButtonName(jobAction = null) {
        if (jobAction == JobAction.Draft) {
            return 'Mark as Draft';
        } else if (jobAction == JobAction.Unassigned) {
            return 'Mark as Unassigned';
        } else if (jobAction == JobAction.Assigned) {
            return 'Mark as Assigned';
        } else if (jobAction == JobAction.Dispatched) {
            return 'Dispatch Now';
        } else if (jobAction == JobAction.Completed) {
            return 'Mark as Completed';
        } else if (jobAction == JobAction.Cancelled) {
            return 'Mark as Cancelled';
        } else if (jobAction == JobAction.SaveAndClose) {
            return 'Save and Close';
        } else {
            return 'Save';
        }
    },

    getXeroPaymentTermTypeName(xeroPaymentTermType) {
        if(xeroPaymentTermType != ''){
            let selectedItem = XeroPaymentTermTypeItems.filter(x => x.value == xeroPaymentTermType);
            if(selectedItem && selectedItem.length > 0){
                return selectedItem[0].title;
            } else {
                return '';
            }
        } else {
            return '';
        }
    },

}

export default Utils;