/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ParseResult } from "../../../utils/interfaces";
// import {  } from '../../../utils/utils';
// import {  } from '../../../utils/enums';


interface InitState {
  isLoading: boolean,
  list: Array<any>,

  isLoadingDetails: boolean,
  details: any,

  isLoadingCreateUpdateDelete: boolean,

  isInitLoading: boolean,
  isVertical: boolean,
  showActualJobTime: boolean,
}


function NewReducer() {
  const name = 'ganttChart';


  const initialState: InitState = {
    isLoading: false,
    list: [],

    isLoadingDetails: false,
    details: null,

    isLoadingCreateUpdateDelete: false,

    isInitLoading: false,
    isVertical: true,
    showActualJobTime: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setIsInitLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isInitLoading = action.payload;
    },

    setIsVertical: (state: InitState, action: PayloadAction<boolean>) => {
      state.isVertical = action.payload;
    },
    showActualJobTime: (state: InitState, action: PayloadAction<boolean>) => {
      state.showActualJobTime = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoadingDetails = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
      state.isLoadingDetails = false;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRead());

      await dispatchCrudApi.readApi(params, 'customer').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishRead(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishRead(null));
      });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await dispatchCrudApi.readApi(null, 'customer/' + id).then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishDetails(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishDetails(null));
      });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCreate());

      await dispatchCrudApi.createApi(params, 'customer').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishCreate(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishCreate(null));
      });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await dispatchCrudApi.updateApi(params, 'customer').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishUpdate(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishUpdate(null));
      });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDelete());

      let paramsData: any = { data: params };

      await dispatchCrudApi.deleteApi(paramsData, 'customer').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishDelete(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishDelete(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();